<template>
  <div :class="['Notice', `_${type}`, `_size-${size}`]" v-show="!dismissed">
    <Button class="Notice_DismissBtn" type="icon" :icon="['solid', 'times']" @click="dismiss()">Dismiss</Button>
    <Icon class="Notice_Icon" v-if="icon.length" :type="icon[0]" :icon="icon[1]" />
    <span :class="['Notice_Content', icon.length?'_withIcon':'']">
      <h3 v-if="title!=''">{{  title }}</h3>
      <p><slot></slot></p>
    </span>
  </div>
</template>

<script>
  export default {
    name: 'UINotice',
  };
</script>

<script setup>
  import { ref, toRefs } from 'vue';

  const emit = defineEmits( [ 'dismissed' ] );

  const props = defineProps( {
    type: {
      type: String,
      default: 'info',
    },
    size: {
      type: String,
      default: 'regular',
    },
    dismissable: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    icon: {
      type: Array,
      default: () => [],
    },
  } );

  const { type, size, title } = toRefs( props );

  const dismissed = ref( false );

  const dismiss = () => {
    dismissed.value = true;
    emit( 'dismissed', true );
  };

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  @import '@/assets/styles/variables/_colours.scss';

  .Notice {
    position: relative;
    background: $dig-blue;
    color: #FFF;
    padding: 32px;
    margin: 0 0 16px 0 !important;

    &_DismissBtn {
      position: absolute;
      right: 0;
      top: 0;
      color: #FFF !important;
    }

    &_Icon {
      display: inline-block;
      vertical-align: middle;
      font-size: 18pt;
      margin-right: 32px;
      color: $hugr-colours-secondary;
    }

    &_Content {
      display: inline-block;
      vertical-align: middle;

      &._withIcon {
        width: calc( 100% - 36px );
      }

      > * { margin: 0; }
    }

    &._size-small {
      padding: 18px;

      .Notice_Icon {
        margin-right: 18px;
      }
    }
    &._size-micro {
      padding: 12px;
      .Notice_Icon {
        margin-right: 12px;
      }
      .Notice_Content {
        h3 {
          font-size: 12pt;
          font-weight: bold;
          margin-bottom: 4px;
        }
        p { font-size: 11pt }
      }
    }
  }
  ._darkMode .Notice {

  }

  .noticeLegacy {
    background: #e5ebf2;
    margin: 0 !important;
    padding: 30px 15px;
    border-left: 5px solid #5e646a;
    white-space: normal;

    &.alert {
      background: #fdedc0;
      border-left: 5px solid #f4ce65;
    }
    &.error {
      background: #f3cbc8;
      border-left: 5px solid #d32b1f;
    }
    &.size-small {
      padding: 15px 10px;
    }
    &.size-micro {
      padding: 10px 5px;
    }
  }

  ._darkMode .noticeLegacy {
    background: darken($hugr-colours-primary, 10%);
    &.alert {
      background: darken(#f4ce65, 50%);
      border-left: 5px solid #f4ce65;
    }
    &.error {
      background: darken(#d32b1f, 20%);
      border-left: 5px solid #d32b1f;
    }
  }
</style>
