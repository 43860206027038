<template>
  <div>
    <div v-if="report" class="Versions">
      <!-- <LinkButton type="transparent" size="small" :icon="['solid', 'arrow-left']" :to="'/reports'">Back to reports</LinkButton> -->
      <ReportInfo :reportVersion="report.currentVersion._id" :trail="[{name: 'Home', path: '/dashboard'}, {name: 'Reports', path: `/${$hugrConfig.reportRouterReplacement}s`}, {name: 'THIS_REPORT'}]"/>

      <Body class="Versions_Body" title="Versions" :withMenu="true" v-if="versions">
        <Notice size="small">
          <p>{{$gettext('The latest version is ')}} {{report.trueVersion.version}}</p>
          <p>
            <span v-translate>Published:</span>
            <span class="Versions_Body_Padded" v-if="report.trueVersion.published"><DateTime :timestamp="report.trueVersion.publishedDate" /></span>
            <span class="Versions_Body_Padded" v-else v-translate>No</span>
          </p>
          <Button class="Version_Body_NewButton" @click="$refs.versionmodal.show()">{{ $gettext( 'New version' ) }}</Button>
          <!-- <p>
            <Button @click="fullRetest()" v-if="!acting">{{$gettext('Full retest')}}</Button>
            <Button v-else disabled>{{$gettext('Adding a version...')}}</Button>
            <translate class="Versions_Body_Padded" :translate-params="{ver: (parseFloat(report.trueVersion.version)+1).toFixed(0)+'.0'}">This will create version %{ver}, copy all pages and component but not issues.</translate>
          </p>
          <p>
            <Button @click="quickRetest()" v-if="!acting">{{$gettext('Quick retest')}}</Button>
            <Button v-else disabled>{{$gettext('Adding a version...')}}</Button>
            <translate class="Versions_Body_Padded" :translate-params="{ver: (parseFloat(report.trueVersion.version)+0.1).toFixed(1)}">This will create version %{ver}, copy all pages and component and issues.</translate>
          </p> -->
        </Notice>
        <h3 v-translate>Version history</h3>
        <ClearList class="Versions_Body_List" v-if="versions.length>1">
          <template v-for="version in versions">
            <li v-if="version.length" :key="version[0].version._id" class="Versions_Body_List_Item">
              <p>{{$gettext('Version')}} {{version[0].version.version}}</p>
              <p>
                <span v-translate>Published: </span>
                <span class="Versions_Body_Padded" v-if="version[0].version.published"><DateTime :timestamp="version[0].version.publishedDate" /></span>
                <span class="Versions_Body_Padded" v-else v-translate>No</span>
              </p>
              <LinkButton size="small" :to="`/${$hugrConfig.reportRouterReplacement}s/view/${report.identifier}/${version[0].version.version}`">{{$gettext('Browse version')}} {{version[0].version.version}}</LinkButton>
            </li>
          </template>
        </ClearList>
        <p v-else v-translate>No previous versions</p>
      </Body>
    </div>
    <Loader v-else />
    <VersionModal v-if="report" ref="versionmodal" :id="report._id" :trueVersion="report.trueVersion.version" />
  </div>
</template>

<script>
import gql from 'graphql-tag';

import VersionModal from '@/modals/Report/Version';

export default {
  name: 'ReportsVersionsView',
  data() {
    return {
      identifier: this.$route.params.identifier.replace( '%20', ' ' ),
      version: this.$route.params.version,

      report: false,
      versions: false,

      acting: false,
    };
  },
  mounted() {
    this.$apollo.mutate( {
      mutation: gql`
        mutation ($page: String) {
          sendNavigation(page: $page)
        }
      `,
      variables: {
        page: 'Assessment Versions',
      },
    } );
  },
  watch: {
    $route() {
      if( this.$route.params.identifier ) {
        this.identifier = this.$route.params.identifier.replace( '%20', ' ' );
        this.version = this.$route.params.version;
      }
      this.$apollo.queries.report.refetch();
    },
    report() {
      this.$apollo.query( {
        query: gql`
          query ReportVersions($report: ObjectID!) {
            versions: ReportVersions(report: $report) {
              version {
                _id,
                version,
                published,
                publishedDate
              }
              newIssues {
                _id
              }
              oldIssues {
                _id
              }
              closedIssues
            }
          }
        `,
        variables: {
          report: this.report._id,
        },
      } ).then( res => {
        this.versions = res.data.versions;
      } );
    },
  },
  apollo: {
    report: {
      query() {
        const reportFragment = gql`
          fragment versionReportFragment on Report {
            _id,
            identifier,
            title,
            archived,
            currentVersion {
              _id,
              published,
              publishedDate,
              version
            }
            trueVersion {
              _id
              published,
              publishedDate,
              version
            }
          }`;
        if( typeof this.version == 'undefined' ) {
          return gql`
            query Report($ident: String!) {
              report: Report(identifier: $ident) {
                ... versionReportFragment
              }
            }
            ${reportFragment}
          `;
        }

          return gql`
            query Report($ident: String!, $version: String!) {
              report: ReportWithVersion(identifier: $ident, version: $version) {
                ... versionReportFragment
              }
            }
            ${reportFragment}
          `;

      },
      variables() {
        return {
          ident: this.identifier,
          version: this.version,
        };
      },
    },
  },
  methods: {

  },
  components: {
    VersionModal,
  },
};
</script>

<style lang="scss" scoped>
  @import '@/assets/styles/variables/_colours.scss';

  .Versions {
    &_Body {
      &_Padded {
        margin-left: 10px;
      }
      &_List {
        &_Item {
          border-left: 3px solid $hugr-colours-grey;
          margin-bottom: 10px;
          padding: 5px;
        }
      }
    }
  }
</style>
