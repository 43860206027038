<template>
  <Modal
    ref="modal"
    :title="$gettext('New Team')"

    :showActions="true"

    :loading="submitting"
    loadingText="Adding Team..."

    :submitText="$gettext('Add team')"
    @submit="submitTeam"

    @close="reset()">
    <FormInput ref="identifier" idRoot="team_" :label="$gettext('Identifier')" v-model="identifier" :validation="[{
      regex: /^[a-zA-Z0-9\-\_]*$/,
      message: $gettext('May only contain letters, numbers, underscore and hyphen characters')
    },
    'not-empty']"/>
    <FormInput ref="name" idRoot="team_" :label="$gettext('Name')" v-model="name" :validation="['not-empty']"/>

    <ul v-if="users.length" aria-live="polite">
      <li v-for="user in users" v-bind:key="user.email">
        {{user.name}} ({{user.email}})
        &nbsp;
        <button @click="rmUser(user)">{{$gettext('Remove')}}</button>
      </li>
    </ul>
    <p v-else v-translate>No users</p>
    <AriaSearchSelect ref="userselect" :label="$gettext('Add a User')" :dropdown="true" idRoot="team_" :gqlOptions="['USERS']" @selected="addUser"/>

    <ul v-if="managers.length" aria-live="polite">
      <li v-for="user in managers"  v-bind:key="user.email">
        {{user.name}} ({{user.email}})
        &nbsp;
        <button @click="rmManager(user)">{{$gettext('Remove')}}</button>
      </li>
    </ul>
    <p v-else v-translate>No managers</p>
    <AriaSearchSelect ref="managerselect" :label="$gettext('Add a Manager')" :dropdown="true" idRoot="team_" :gqlOptions="['USERS']" @selected="addManager"/>

    <AriaSearchSelect ref="customerselect" :label="$gettext('Customer')" :dropdown="true" :gqlOptions="['CUSTOMERS']" @selected="setCustomer" :validation="['not-empty']"/>

    <AriaSearchSelect ref="contactselect" :label="$gettext('Internal Contact')" :dropdown="true" idRoot="team_" :gqlOptions="['DIGTEAM']" @selected="setContact" :validation="['not-empty']"/>

    <FormInput ref="reportslimit" idRoot="team_" :label="$gettext('Custom Report Limit')" v-model="reportslimit" :validation="['not-empty']"/>
    <FormInput ref="userslimit" idRoot="team_" :label="$gettext('Custom Users Limit')" v-model="userslimit" :validation="['not-empty']"/>

    <label for="activeIndefinitely" v-translate>Active indefinitely</label>
    <input type="checkbox" v-model="activeIndefinitely" />
    <p v-translate>Team valid indefinitely?</p>

    <template v-if="!activeIndefinitely">
      <FormInput idRoot="team_" label="Active until" v-model="activeUntil" />
      <p v-translate>Set this date to the future to keep this team active even without a valid subscription</p>
    </template>
  </Modal>
</template>

<script>
import gql from 'graphql-tag';

import FormInput from '@/components/Form/Input';

import AriaSearchSelect from '@/components/Aria/SearchSelect2';

export default {
  name: 'TeamModal',
  data() {
    return {
      identifier: '',
      name: '',
      managers: [],
      reportslimit: 50,
      userslimit: 10,
      users: [],
      customer: '',
      contact: null,
      defaultContact: null,

      activeIndefinitely: false,
      activeUntil: null,

      submitting: false,
    };
  },
  apollo: {
    settings: {
      query: gql`
        {
          settings: Settings {
            id
            defaultTeamContact {
              _id,
              name,
              email
            }
          }
        }
      `,
      result( res ) {
        this.defaultContact = res.data.settings.defaultTeamContact;
        this.contact = this.defaultContact._id;
      },
      fetchPolicy: 'no-cache',
    },
  },
  methods: {
    setContact( contact, display ) {
      this.$refs.contactselect.select( contact, display );
      this.contact = contact;
    },
    submitTeam() {
      this.$hugrvalidate( [ 'identifier', 'name', 'customerselect', 'contactselect' ], this ).then( ( { success, errors } ) => {
        if( success ) {
          this.submitting = true;
          this.$apollo.mutate( {
            mutation: gql`
              mutation addTeam($team: TeamInput!) {
                team: addTeam(team: $team) {
                  _id
                }
              }
            `,
            variables: {
              team: {
                identifier: this.identifier,
                name: this.name,
                userslimit: parseInt( this.userslimit ),
                reportslimit: parseInt( this.reportslimit ),
                managers: this.managers.map( user => user._id ),
                users: this.users.map( user => user._id ),
                customer: this.customer,
                contact: this.contact,
                activeIndefinitely: this.activeIndefinitely,
                activeUntil: this.activeIndefinitely ? null : this.activeUntil,
              },
            },
          } ).then( res => {
            this.submitting = false;
            this.reset();
            this.$alerts.success( 'Team added!', `Team ${this.name} has been added successfully` );
            this.$emit( 'success', res.data.team._id );
          } ).catch( error => {
            this.submitting = false;
            this.$alerts.error( `Can't add team`, `Check the data you've inputted and try again.`, '', error );
          } );
        }
      } );
    },

    addUser( user ) {
      this.$apollo.query( {
        query: gql`
          query User($id: ObjectID!) {
            user: User(id: $id) {
              _id,
              name,
              email
            }
          }
        `,
        variables: {
          id: user,
        },
      } ).then( res => {
        this.users.push( res.data.user );
      } );
    },
    rmUser( user ) {
      if( this.managers.map( user => user._id ).indexOf( user._id ) >= 0 ) { //if remove user, also remove manager
        this.managers.splice( this.managers.map( user => user._id ).indexOf( user._id ), 1 );
      }
      this.users.remove( user );
    },

    addManager( user ) {
      this.$apollo.query( {
        query: gql`
          query User($id: ObjectID!) {
            user: User(id: $id) {
              _id,
              name,
              email
            }
          }
        `,
        variables: {
          id: user,
        },
      } ).then( res => {
        if( this.users.indexOf( res.data.user ) < 0 ) { //make sure manager is in users
          this.users.push( res.data.user );
        }
        this.managers.push( res.data.user );
      } );
    },
    rmManager( user ) {
      this.managers.remove( user );
    },

    setCustomer( customer, display ) {
      this.$refs.customerselect.select( customer, display );
      this.customer = customer;
    },

    show() {
      this.$refs.modal.show();
      setTimeout( () => {
        this.setContact( this.defaultContact._id, `${this.defaultContact.name} (${this.defaultContact.email})` );
      }, 100 );
    },
    reset() {
      this.identifier = '';
      this.name = '';
      this.managers = [];
      this.users = [];
      this.customer = '';
      this.activeIndefinitely = false;
      this.activeUntil = null;
      this.$refs.modal.reset();
    },
  },
  props: {
  },
  components: {
    FormInput,
    AriaSearchSelect,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
