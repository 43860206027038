<template>
  <Modal
    ref="modal"
    :title="$gettext('New Ticket')"

    :showActions="true"

    :loading="submitting"
    loadingText="Creating Ticket..."

    :submitText="$gettext('Create ticket')"
    @submit="submit"

    @close="reset()">
    <FormInput ref="title" idRoot="ticket_" :label="$gettext('Title')" v-model="title" :validation="['not-empty']" :hintText="$gettext('This is a general title for your ticket.')"/>
    <template v-if="Object.keys(teams).length>3">
      <FormSelect idRoot="ticket_" :label="$gettext('Team')" v-model="team" :options="{
          ...teams
        }"/>
    </template>
    <template v-else>
      <FormFieldSet :legend="$gettext('What team should this ticket be for?')">
        <div v-for="(teamname, teamvalue) of teams" :key="`team-${teamvalue}-container`">
          <input type="radio" v-model="team" :value="teamvalue" :id="`team-${teamvalue}`" :key="`team-${teamvalue}`" />
          <label :for="`team-${teamvalue}`" :key="`team-${teamvalue}-label`">{{teamname}} {{$gettext('(Team)')}}</label>
        </div>
      </FormFieldSet>
    </template>

    <FormSelect ref="reportSelect" v-if="team" idRoot="ticket_" :label="$gettext('Report')" v-model="report" :hintText="$gettext('Archived reports will not be shown.')" :options="{
      ...teamReportsNames
    }"/>

    <FormSelect idRoot="ticket_" :label="$gettext('Ticket Type')" v-model="template" :options="{
        general: $gettext('General enquiry'),
        testSection: $gettext('Help with testing a section'),
        testPage: $gettext('Help with testing a page'),
        helpIssue: $gettext('Help with an issue'),
        fixIssue: $gettext('Help with fixing an issue'),
      }"/>

    <FormSelect v-if="template == 'helpIssue' || template == 'fixIssue'" idRoot="ticket_" :hintText="$gettext('Closed issues will not be shown.')" :label="$gettext('Issue')" :options="{
      ...issueNames
    }"/>

    <FormTextArea ref="extraInfo" idRoot="ticket_" :label="$gettext('Extra info')" v-model="extraInfoText" :validation="['not-empty']"/>
    <FormImage ref="evidenceImage" idRoot="issue_" :label="$gettext('Screenshot')" v-model="evidence"/>
  </Modal>
</template>

<script>
import gql from 'graphql-tag';
import { mapState } from 'vuex';

import FormFieldSet from '@/components/Form/FieldSet';
import FormInput from '@/components/Form/Input';
import FormSelect from '@/components/Form/Select';
import FormTextArea from '@/components/Form/TextArea';
import FormImage from '@/components/Form/Image';

export default {
  name: 'TicketModal',
  data() {
    return {
      identifier: '',
      title: '',
      team: this.defaultTeam,
      template: 'general',

      teams: false,

      fullTeam: false,

      evidence: '',
      report: this.defaultReport,
      reports: [],

      extraInfoText: '',

      issues: [],

      submitting: false,
    };
  },
  apollo: {
    reports: {
      query: gql`
      {
        reports: Reports {
          _id,
          title,
          team
          {
            _id,
            name,
          }
          archived,
        }
      }
      `,
      update: data => {
        return data.reports;
      },
    },
    teams: {
      query: gql`
        {
          teams: Teams {
            _id
            name
          }
        }
      `,
      update: data => {
        const opts = {};
        for( const team of data.teams ) {
          opts[team._id] = team.name;
        }

        return opts;
      },
    },
  },
  watch: {
    team() {
      if( parseInt( this.user.aclevel ) >= 2 && this.team != 0 ) {
        this.$apollo.query( {
          query: gql`
            query Team($id: ObjectID!) {
              issues: Team(id: $id) {
                _id,
                identifier,
                customer {
                  _id
                }
              }
            }
          `,
          variables: {
            id: this.team,
          },
        } ).then( res => {
          this.fullTeam = res.data.team;
        } );
      }
    },
    report() {
      if ( this.report != '' ) {
        this.$apollo.query( {
          query: gql`
            query IssuesFromReport($id: ObjectID!) {
              issues: IssuesFromReport(id: $id) {
                _id,
                identifier,
                status,
                reason
              }
            }
          `,
          variables: {
            id: this.report,
          },
        } ).then( res => {
          this.issues = res.data.issues;
        } );
      }
    },
  },
  methods: {
    submit() {
      const toValidate = [ 'title', 'extraInfo' ];

      if( !this.report ) {
        this.report = 0;
      }

      this.$hugrvalidate( toValidate, this ).then( ( { success } ) => {
        if( success ) {
          if ( this.report && this.report != '0' && this.report != 0 ) {
            this.doSubmit();
          } else {
            this.$alerts.error( "Error", "No report selected." );
          }
        }
      } );
    },

    doSubmit() {
      const ticket = {
        title: this.title,
        ticketType: this.template,
        createdBy: this.user.id,
        status: "open",
        extraInfo: this.extraInfoText,
        report: this.report,
        team: this.team,
        screenshot: this.evidence,
        issue: this.issue?._id,
      };

      this.submitting = true;
      this.$apollo.mutate( {
        mutation: gql`
          mutation addTicket($ticket: TicketInput!) {
            ticket: addTicket(ticket: $ticket) {
              _id
            }
          }
        `,
        variables: {
          ticket,
        },
      } ).then( () => {
        this.submitting = false;
        this.$emit( 'success' );
        this.$alerts.success( 'Ticket created!', `The ticket has been created successfully` );
        this.reset();
      } ).catch( () => {
        this.submitting = false;
        this.$alerts.error( 'Ticket not created', `Sorry. You've found a bug that we need to look at.` );
      } );
    },
    show() {
      this.$refs.modal.show();
    },
    reset() {
      this.title = '';
      this.template = 'testSection';
      this.extraInfoText = '';
      this.team = '0';
      this.evidence = '';

      this.$refs.modal.reset();
    },
  },
  computed: {
    ...mapState( [ 'user' ] ),
    teamReports() {
      return this.reports.filter( report => {
        return ( ( report.team?._id == this.team ) && !report.archived );
      } );
    },
    teamReportsNames() {
      const ret = {};
      for( const report of this.teamReports ) {
        ret[report._id] = report.title;
      }

      return ret;
      // return this.teamReports.map(r => r.title);
    },
    openIssues() {
      return this.issues.filter( issue => {
        return !( issue.status ).includes( "closed" );
      } );
    },
    issueNames() {
      return this.openIssues.map( r => r.reason );
    },
  },
  props: {
    defaultTeam: {
      type: String,
      default: "0",
    },
    defaultReport: {
      type: String,
      default: "0",
    },
  },
  components: {
    FormInput,
    FormFieldSet,
    FormSelect,
    FormTextArea,
    FormImage,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
 form {
   button.button {
     width: 100%;
     margin-top: 20px;
   }
 }
</style>
