<template>
  <Modal
    ref="modal"
    :title="$gettext('Detail overrides and key issues')"

    :showActions="true"

    :loading="submitting"
    loadingText="Updating..."

    :submitText="$gettext('Save')"
    @submit="extended?submitExtended():submit()"

    @close="reset()"
    :ready="data">
    <FormInput id="dateCreatedOverride" idRoot="template_" :label="$gettext('Date Created')" v-model="createdOverride"/>
    <FormInput id="datePublishedOverride" idRoot="template_" :label="$gettext('Date Published')" v-model="publishedOverride"/>

    <FormFieldSet legend="Key issue instances breakdown" decoration="divide">
      <li v-for="(issue, key) in processedKeyIssueInstances" class="spacing" v-bind:key="key">
        <span v-if="issue[1] == 1">
          There is <strong>{{ issue[1] }}</strong> issue marked as a key issue for <strong>{{ issue[0] }}</strong>
        </span>
        <span v-else>
          There are <strong>{{ issue[1] }}</strong> issues marked as key issues for <strong>{{ issue[0] }}</strong>
        </span>
        <Button class="removeButton" type="border" size="micro" @click.prevent="populateIssue(issue[0], issue[1])">
          <span v-if="processedDisplayIssues.map(i => i.name).indexOf(issue[0])>=0 || keyIssueTitle==issue[0]" v-translate>Use again</span>
          <span v-else v-translate>Populate</span>
        </Button>
      </li>
    </FormFieldSet>

    <FormFieldSet legend="Add a key issue" decoration="divide">
      <form @submit.prevent="addIssue">
        <FormInput ref="keyissuetitle" idRoot="issue_" :label="$gettext('Key Issue Title')" v-model="keyIssueTitle"/>
        <FormTextArea ref="keyissuebody" idRoot="issue_" :label="$gettext('Key Issue Description')" v-model="keyIssueBody" />
        <Button type="border" size="small" @click.prevent="addIssue()">{{$gettext('Add issue')}}</Button>
      </form>
    </FormFieldSet>

    <FormFieldSet legend="Key issues" decoration="divide">
      <AriaSearchSelectList v-if="keyIssues.length" v-model:items="keyIssues" :overrideDisplayItems="processedDisplayIssues" :ordered="true" :moveable="true" :boldName="true"/>
    </FormFieldSet>

    <div v-if="extended">
      <FormInput id="mainURLOverride" idRoot="template_" :label="$gettext('Main URL')" v-model="statementOverrides.mainURL"/>
      <FormInput id="purposeOverride" idRoot="template_" :label="$gettext('Service purpose')" v-model="statementOverrides.purpose"/>
      <FormInput id="platformOverride" idRoot="template_" :label="$gettext('Platform type')" v-model="statementOverrides.platform"/>
      <FormInput id="reportURLOverride" idRoot="template_" :label="$gettext('Report URL')" v-model="statementOverrides.reportURL"/>
      <FormInput id="customerOverride" idRoot="template_" :label="$gettext('Customer')" v-model="statementOverrides.customer"/>
      <FormInput id="reportNameOverride" idRoot="template_" :label="$gettext('Alternative name')" v-model="statementOverrides.reportName"/>
      <FormInput id="wcagVersionOverride" idRoot="template_" :label="$gettext('WCAG version')" v-model="statementOverrides.wcagVersion"/>
      <FormInput id="wcagStandardOverride" idRoot="template_" :label="$gettext('WCAG standard')" v-model="statementOverrides.wcagStandard"/>
      <FormInput id="contactEmailOverride" idRoot="template_" :label="$gettext('Contact Email')" v-model="statementOverrides.contactEmail"/>
      <FormInput id="contactPhoneOverride" idRoot="template_" :label="$gettext('Contact Phone')" v-model="statementOverrides.contactPhone"/>
      <FormInput id="complianceStatusOverride" idRoot="template_" :label="$gettext('Compliance status')" v-model="statementOverrides.complianceStatus"/>
      <FormInput id="statementDateOverride" idRoot="template_" :label="$gettext('Statement Date')" v-model="statementOverrides.statementDate"/>
      <FormInput id="lastReviewDateOverride" idRoot="template_" :label="$gettext('Last Statement Review Date')" v-model="statementOverrides.lastReviewDate"/>
      <FormInput id="latestTestDateOverride" idRoot="template_" :label="$gettext('Latest Test Date')" v-model="statementOverrides.latestTestDate"/>
      <FormInput id="testConductorOverride" idRoot="template_" :label="$gettext('Latest Tester Name')" v-model="statementOverrides.testConductor"/>
      <FormTextArea id="disproportionateBurdenOverride" idRoot="template_" :label="$gettext('Disproportionate Burden Claim')" v-model="statementOverrides.disproportionateBurden"/>
    </div>
  </Modal>
</template>

<script>
import gql from 'graphql-tag';
import { mapState, mapActions } from 'vuex';

import AriaSearchSelectList from '@/components/Aria/SearchSelectList';

import FormFieldSet from '@/components/Form/FieldSet';
import FormInput from '@/components/Form/Input';
import FormTextArea from '@/components/Form/TextArea';
import FormSelect from '@/components/Form/Select';

export default {
  name: 'EditRenderDetailsModal',
  data() {
    return {
      keyIssueTitle: '',
      keyIssueBody: '',
      createdOverride: '',
      publishedOverride: '',
      keyIssues: [],
      keyIssueInstances: [],
      reportVersionID: false,
      statementOverrides: {},
      extended: false,
      submitting: false,
    };
  },
  methods: {
    submit() {
      this.submitting = true;
      this.$apollo.mutate( {
        mutation: gql`
          mutation updateReportVersionOverrides($id: ObjectID!, $createdOverride: String, $publishedOverride: String,  $keyIssues: [String]) {
            result: updateReportVersionOverrides(id: $id, createdOverride: $createdOverride, publishedOverride: $publishedOverride, keyIssues: $keyIssues)
          }
        `,
        variables: {
          id: this.reportVersionID,
          createdOverride: this.createdOverride,
          publishedOverride: this.publishedOverride,
          keyIssues: this.keyIssues,
        },
      } ).then( () => {
        this.submitting = false;
        this.$alerts.success( 'Report details updated!', `The report details have been updated successfully` );
        this.$emit( 'success', { created: this.createdOverride, published: this.publishedOverride, issues: this.keyIssues } );
        this.reset();
      } ).catch( () => {
        this.submitting = false;
        this.$alerts.coded( 'E063', 'F3501' ); //see notifications spreadsheet
      } );
    },
    submitExtended() {
      this.$apollo.mutate( {
        mutation: gql`
          mutation updateReportVersionOverrides($id: ObjectID!, $createdOverride: String, $publishedOverride: String,  $keyIssues: [String], $statementOverrides: accessibilityStatementOverridesInput ) {
            result: updateReportVersionOverrides(id: $id, createdOverride: $createdOverride, publishedOverride: $publishedOverride, keyIssues: $keyIssues, statementOverrides: $statementOverrides)
          }
        `,
        variables: {
          id: this.reportVersionID,
          createdOverride: this.createdOverride,
          publishedOverride: this.publishedOverride,
          keyIssues: this.keyIssues,
          statementOverrides: this.statementOverrides,
        },
      } ).then( res => {
        this.$alerts.success( 'Details updated!', `The override details have been updated successfully` );
        this.$emit( 'success', { created: this.createdOverride, published: this.publishedOverride, issues: this.keyIssues, statementoverrides: this.statementOverrides } );
        this.reset();
      } ).catch( () => {
        this.$alerts.coded( 'E063', 'F3501' ); //see notifications spreadsheet
      } );
    },
    show( reportVersionID, created, published, issues, keyIssueInstances ) {
      this.reportVersionID = reportVersionID;
      this.createdOverride = created;
      this.publishedOverride = published;
      this.keyIssues = Object.assign( [], issues );
      this.keyIssueInstances = Object.assign( [], keyIssueInstances );
      this.$refs.modal.show();
    },
    showExtended( reportVersionID, created, published, issues, keyIssueInstances, statementOverrides, focusID ) {
      this.extended = true;
      this.reportVersionID = reportVersionID;
      this.createdOverride = created;
      this.publishedOverride = published;
      this.keyIssues = Object.assign( [], issues );
      this.keyIssueInstances = Object.assign( [], keyIssueInstances );
      this.statementOverrides = { ...statementOverrides } || {};
      delete this.statementOverrides.__typename;
      this.$refs.modal.show();
      setTimeout( () => {
        const el = document.getElementById( focusID );

        if ( el ) {
            el.children[1].focus();
        }
      }, 200 );
    },
    addIssue() {
      if ( this.keyIssueTitle != '' ) {
        if ( this.keyIssueBody != '' ) {
          this.keyIssues.push( `${this.keyIssueTitle}<keyIssueBodySeparator>${this.keyIssueBody}` );
        } else {
          this.keyIssues.push( this.keyIssueTitle );
        }
        this.keyIssueTitle = '';
        this.keyIssueBody = '';
      }
    },
    removeIssue( issueKey ) {
      this.keyIssues.splice( issueKey, 1 );
    },
    moveIssueUp( issueKey ) {
      if( issueKey > 0 ) {
        const temp = this.keyIssues[issueKey - 1];
        this.keyIssues[issueKey - 1] = this.keyIssues[issueKey];
        this.keyIssues[issueKey] = temp;
      }
    },
    moveIssueDown( issueKey ) {
      if( issueKey < this.keyIssues.length - 1 ) {
        const temp = this.keyIssues[issueKey + 1];
        this.keyIssues[issueKey + 1] = this.keyIssues[issueKey];
        this.keyIssues[issueKey] = temp;
      }
    },
    populateIssue( section, number ) {
      this.keyIssueTitle = section;
      this.keyIssueBody = `There was 1 issue marked as a key issue for the ${section} category.`;
      if ( number > 1 ) {
        this.keyIssueBody = `There were ${number} issues marked as key issues for the ${section} category.`;
      }
      this.$refs.keyissuetitle.focus();
    },
    reset() {
      this.extended = false;
      this.$refs.modal.reset();
    },
  },
  computed: {
    processedKeyIssueInstances() {
      const ret = new Map();
      for ( const keyIssue of this.keyIssueInstances ) {
        const split = keyIssue.section ? keyIssue.section.split( " : " ) : keyIssue.template.section.split( " : " );
        let section = split[0];

        if ( split.length > 0 ) {
          // eslint-disable-next-line prefer-destructuring
          section = split[1];
        }

        // const section = `${keyIssue.template.identifier}: ${keyIssue.template.title}`;

        if ( !ret.has( section ) ) {
          ret.set( section, 1 );
        } else {
          ret.set( section, ret.get( section ) + 1 );
        }
      }

      return ret;
    },
    processedKeyIssues() {
      const ret = [];
      for ( const keyIssue of this.keyIssues ) {
        const split = keyIssue.split( '<keyIssueBodySeparator>' );
        ret.push( split );
      }

      return ret;
    },
    processedDisplayIssues() {
      const ret = [];
      for ( const keyIssue of this.processedKeyIssues ) {

        let newObject = {
          name: keyIssue[0],
          type: "",
        };

        if( keyIssue.length > 1 ) {
          newObject = {
            name: keyIssue[0],
            type: keyIssue[1],
          };
        }

        ret.push( newObject );
      }

      return ret;
    },
  },
  components: {
    FormFieldSet,
    FormInput,
    FormTextArea,
    AriaSearchSelectList,
  },
  props: {
    data: {
      type: Array,
      default: () => {
        return [];
      },
      required: true,
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .removeButton {
    margin-left: 5px;
  }
  .spacing {
    margin-top: 5px;
  }
</style>
