<template>
  <Body :title="$gettext('Portfolios')" :fill="true" class="Portfolios">
    <!-- Need to move sort logic to server for pagination -->
    <!-- <SortBy class="Teams_Sort" :options="sortOpts" /> -->

    <CardSet :title="$gettext('All Portfolios')" hlevel="3" :button="{icon:['solid', 'users', 'after'], text: $gettext('Add a portfolio')}" class="Portfolios_Portfolios" @buttonClick="$refs.portfoliomodal.show()">
      <template v-if="portfolios">
        <DataTable  class="Portfolios_Portfolios_Table"
                    :headers="[
            $gettext('Identifier'),
            $gettext('Name'),
            $gettext('Customer'),
            $gettext('Principal user'),
            $gettext('Actions')
          ]">
          <tr v-for="portfolio in portfolios" v-bind:key="'portfolio-' + portfolio.identifier">
            <td>{{ portfolio.identifier }}</td>
            <td>{{ portfolio.name }}</td>
            <td>{{ portfolio.customer.name }}</td>
            <td>
              <template v-if="portfolio.principal">{{ portfolio.principal.name }} ({{ portfolio.principal.email }})</template>
              <template v-else>None</template>
            </td>
            <td>
              <Button size="small" @click="$refs.editportfoliomodal.show(portfolio._id)">{{$gettext('Edit Portfolio')}}</Button>&nbsp;
              <LinkButton size="small" :to="`/${$hugrConfig.reportRouterReplacement}s/find/all/alphabetical/portfolio/${portfolio._id}`">Assessments</LinkButton>
            </td>
          </tr>
        </DataTable>
        <!-- <DataTablePaginator :pages="teams.pages" @select="(page) => { teamsPage = page } " /> -->
      </template>
      <Empty v-else :text="$gettext('Nothing here!')" :button="{icon:['solid', 'users'], text: $gettext('Add a portfolio')}" @buttonClick="$refs.portfoliomodal.show()" />
    </CardSet>

    <PortfolioModal ref="portfoliomodal" @success="$apollo.queries.portfolios.refetch()" />
    <EditPortfolioModal ref="editportfoliomodal" @success="$apollo.queries.portfolios.refetch()" />
  </Body>
</template>

<script>
import gql from 'graphql-tag';
import { mapState, mapGetters } from 'vuex';
import PortfolioModal from '@/modals/Portfolio/Create';
import EditPortfolioModal from '@/modals/Portfolio/Edit';

export default {
  name: 'PortfoliosView',
  data() {
    return {
      portfolios: false,
    };
  },
  computed: {
    ...mapGetters( [ 'hasPermission' ] ),
    ...mapState( { stateUser: state => state.user } ),
  },
  mounted() {
    this.$apollo.queries.portfolios.refetch();

    this.$apollo.mutate( {
      mutation: gql`
        mutation ($page: String) {
          sendNavigation(page: $page)
        }
      `,
      variables: {
        page: 'Portfolios',
      },
    } );
  },
  apollo: {
    portfolios: {
      query: gql`
        query Portfolios {
          portfolios: Portfolios {
            _id
            identifier
            name
            customer {
                _id
                name
            }
            principal {
              _id
              name
              email
            }
          }
        }
      `,
    },
  },
  methods: {

  },
  components: {
    PortfolioModal,
    EditPortfolioModal,
  },
};
</script>

<style lang="scss" scoped>

  @import '@/assets/styles/variables/_colours.scss';

  .red {
    color: $hugr-colours-red;
  }

  .Portfolios {
    &_Portfolios {
      &_Table {
        width: 100%;
      }
    }
  }

  ._darkMode {
    .red {
      color: lighten($hugr-colours-red, 30%);
    }
  }
</style>
