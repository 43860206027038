<template>
  <Modal
    ref="modal"
    :title="issueIdentifier"
    :subtitle="issue?`${issue?.template.identifier}: ${issue?.template.title}`:''"

    :showActions="false"

    @close="reset()"
    class="ViewIssueModal"
    :fix="true">
    <div class="ViewIssueModal_Inner __grid" v-if="issue">
      <div class="ViewIssueModal_Inner_Left __grid_column_8">
        <h4>Description</h4>
        <template v-if="issue.reason">
          <vue-markdown :key="issue.identifier+'-description'" v-highlight :html="false">{{ issue.reason }}</vue-markdown>
        </template>
        <template v-else>None added</template>

        <h4>Solution(s)</h4>
        <ul v-if="issue.solutions.length">
          <li v-for="solution in issue.solutions" :key="solution.identifier">
            <Button type="link" @click="solutiondetailsmodal.show( solution._id )">{{ solution.identifier }}: {{ solution.title }}</Button>
          </li>
        </ul>
        <p v-else v-translate>None added</p>
        
        <h4>Attachments</h4>
        <Gallery v-model="issue.evidence" :editable="false" :instance="issue._id"/>
      </div>
      <div class="ViewIssueModal_Inner_Right __grid_column_4">
        <dl class="ViewIssueModal_Inner_Right_List">
          <div>
            <dt>Status</dt>
            <dd>
              <!-- <span v-if="issue.reportVersion.published">
                <AriaListBox v-model="issue.status" idRoot="instance_" label="Status" :labelVisible="false" @change="statusChange"
                  :options="{
                    'reported': $gettext('Reported'),
                    'in-progress': $gettext('In Progress'),
                    'retest': $gettext('To Retest')
                  }"
                  :extra="{
                    'reported': $gettext('Issue is reported, action is required.'),
                    'in-progress': $gettext('Fix is in progress.'),
                    'retest': $gettext('Issue has been fixed and requires retesting')
                  }"
                  />
              </span>
              <span v-else>
                <AriaListBox idRoot="instance_" :label="$gettext('Status')" :labelVisible="false" v-model="issue.status" @change="statusChange"
                  :options="{
                    'reported': $gettext('Reported'),
                    'closed-removed': $gettext('Closed - Removed'),
                    'closed-fixed': $gettext('Closed - Fixed'),
                    'closed-disproportionate': $gettext('Closed - Disproportionate Burden'),
                    'in-progress': $gettext('In Progress'),
                    'retest': $gettext('To Retest')
                  }"
                  :extra="{
                    'reported': $gettext('Issue is reported, action is required.'),
                    'closed-removed': $gettext('Component with issue has been removed or mitigated.'),
                    'closed-fixed': $gettext('Issue has been fixed.'),
                    'closed-disproportionate': $gettext('Item has been closed due to disproportionate burden'),
                    'in-progress': $gettext('Fix is in progress.'),
                    'retest': $gettext('Issue has been fixed and requires retesting')
                  }"
                />
              </span> -->
            </dd>
          </div>
          <div v-if="issue.reportVersion.report.team || issue.reportVersion.report.portfolio">
            <dt>Assignee</dt>
            <dd>
              <!-- <AriaListBox idRoot="instance_" :label="$gettext('Assignee')" :labelVisible="false" v-model="assignee" @change="assigneeChange"
                  :options="assignOpts"
                /> -->
            </dd>
          </div>
          <div>
            <dt>QA Status</dt>
            <dd>
              <!-- <AriaListBox v-if="!issue.reportVersion.published" idRoot="instance_" :label="$gettext('QA Status')" :labelVisible="false" v-model="issue.flag" @change="flagChange"
                :options="{
                  'unchecked': $gettext('Unchecked'),
                  'to-check': $gettext('To Check'),
                  'checked': $gettext('Checked'),
                  'change-required': $gettext('Change Required'),
                  'second-opinion': $gettext('Second Opinion')
                }"
                :extra="{
                  'unchecked': $gettext(`Issue hasn't been checked`),
                  'to-check': $gettext('Issue needs to be checked'),
                  'checked': $gettext('Issue has been checked and confirmed'),
                  'change-required': $gettext('A change is required before the issue can be confirmed'),
                  'second-opinion': $gettext('Issue has been checked but a second opinion is required')
                }"
              />
              <span v-else>{{ issue.flag }}</span> -->
            </dd>
          </div>
          <div>
            <dt>Found On</dt>
            <dd>
              <router-link v-if="issue.component" :to="`/${$hugrConfig.reportRouterReplacement}s/${issue.reportVersion.report.identifier}/${issue.reportVersion.version}/components/${issue.component._id}`" role="button" @click.prevent="goToComponent( issue.component._id )" :title="issue.component.identifier">
                <Pill size="small" :text="issue.component.identifier" />
              </router-link>
            </dd>
          </div>
          <div>
            <dt>Severity</dt>
            <dd></dd>
          </div>
          <div>
            <dt>Priority</dt>
            <dd></dd>
          </div>
          <div>
            <dt>Reported by</dt>
            <dd></dd>
          </div>
          <div>
            <dt>Reported on</dt>
            <dd></dd>
          </div>
          <div>
            <dt>Last updated</dt>
            <dd></dd>
          </div>
        </dl>
      </div>
    </div>
  </Modal>
</template>

<script setup>
  import { ref } from 'vue';
  import { useQuery } from "@vue/apollo-composable";
  import gql from "graphql-tag";

  // import AriaSearchSelect from '@/components/Aria/SearchSelect3';
  // import AriaSearchSelectLegacy from '@/components/Aria/SearchSelect2';
  import AriaListBox from '@/components/Aria/ListBox.vue';

  import Gallery from '@/components/UI/Gallery';

  const modal = ref( null );
  const issueIdentifier = ref( null );
  const doQuery = ref( false );

  const { loading, onResult, refetch } = useQuery( gql`
    query IssueInstance($identifier: String!) {
      issue: IssueInstance(identifier: $identifier) {
        _id
        identifier
        evidence
        reason
        priority
        status
        flag
        isAKeyIssueInstance

        nextInstance {
          _id,
          identifier
        }
        prevInstance {
          _id,
          identifier
        }

        reporter {
          _id,
          name,
          email
        }

        assignee {
          _id,
          name,
          email
        }

        page {
          _id
          name
          host
          path
          progress
          isCompliant
          issues {
            _id
          }
        }
        component {
          _id
          identifier,
          pages {
            _id
            name
            progress
            isCompliant
            issues {
              _id
            }
          }
        }

        others {
          _id
          page {
            _id
            name
            host
            path
            progress
            isCompliant
            issues {
              _id
            }
          }
        }

        solutions {
          _id,
          identifier,
          title
        }
        
        template {
          _id
          identifier
          title
          description
          affected
          impact
          severity

          criteria {
            _id
            criterion
            identifier
            title
            level
          }

          solutions {
            _id
          }
        }

        reportVersion {
          _id
          version
          published
          report {
            _id
            identifier

            owner {
              _id,
              name,
              email
            }
            collaborators {
              _id,
              name,
              email
            }
            team {
              _id
              users {
                _id,
                name,
                email
              }
            }
            portfolio {
              _id
              teams {
                _id
                users {
                  _id,
                  name,
                  email
                }
              }
            }
          }
        }
      }
    }
  `,
  {
    identifier: issueIdentifier,
  },
  {
    fetchPolicy: 'no-cache',
    enabled: doQuery,
  } );

  const issue = ref( null );

  const show = identifier => {
    issueIdentifier.value = identifier;
    doQuery.value = true;
    if( issue.value && issue.value.identifier == identifier ) refetch();
    onResult( ( { data } ) => {
      issue.value = data.issue;
      // reportVersion.value = data.component.reportVersion._id;
    } );
    modal.value.show();
  };

  const reset = () => {
    modal.value.reset();
  };

  defineExpose( {
    show,
    reset,
  } );
</script>

<style lang="scss" scoped>

</style>
