<template>
  <div>
    <template v-if="!boxOnly">
      <ul class="Comments" v-if="comments.length">
        <li v-for="comment in parsedComments" v-bind:key="comment._id" :class="['Comments_Comment', reference!=comment.reference?'_prevVersion':'']">
          <div class="Comments_Comment_Head">
            <Avatar :uid="comment.from._id" />
            <p class="Comments_Comment_Head_Name">{{ comment.from.name }}</p>
            <p class="Comments_Comment_Head_Time __fade">
              <DateTime :timestamp="comment.timestamp" format="fromNow"/>
              <template v-if="reference!=comment.reference"> - This comment is from a previous version</template>
            </p>
            <ButtonSet class="Comments_Comment_Head_Menu" label="Options" :dropdown="true" size="micro" type="icon" :icon="['solid', 'ellipsis-vertical']">
              <Button type="transparent" size="micro" v-if="comment.from._id==user.id" @click="doDelete(comment._id)" :icon="['solid', 'trash']">{{$gettext('Delete')}}</Button>
              <Button type="transparent" size="micro" v-if="comment.from._id==user.id" @click="doEdit(comment._id, comment.contents)" :icon="['regular', 'edit']">{{$gettext('Edit')}}</Button>
              <Button type="transparent" size="micro" @click="doReply(comment.from._id, comment._id)" :icon="['solid', 'reply']">{{$gettext('Reply')}}</Button>
            </ButtonSet>
          </div>
          <vue-markdown class="Comments_Comment_Body" :key="comment.timestamp+'-comment'" v-highlight :anchorAttributes="{target: '_blank'}" :html="true">{{ comment.contents }}</vue-markdown>
          <div v-if="comment.associatedFiles.length > 0" class="Comments_Comment_Files">
            <li v-for="(file, i) of comment.associatedFiles" :key="`comment-${_id}-${i}`">
              <span> <a href="#" @click="openFile(file.uuid, file.name)">{{deUrify(file.name)}}</a> ({{returnFileSize(file.size)}})</span>
            </li>
          </div>
          <a href="#" role="button" class="Comments_Comment_ShowAll" v-if="comment.replies.length&&!comment.showReplies" @keyup.space.prevent="comment.showReplies=true" @click.prevent="comment.showReplies=true">Show replies</a>
          <ul class="Comments _sub" v-if="comment.replies.length" v-show="comment.showReplies">
            <li v-for="subcomment in comment.replies" v-bind:key="subcomment._id"  class="Comments_Comment">
              <div class="Comments_Comment_Head">
                <Avatar :uid="subcomment.from._id" />
                <p class="Comments_Comment_Head_Name">{{ subcomment.from.name }}</p>
                <span class="Comments_Comment_Head_ReplyTo __fade"><Icon type="solid" icon="caret-right" aria-label="replying to"/>&nbsp;{{ subcomment.tagged[0][1] }}</span>
                <p class="Comments_Comment_Head_Time __fade"><DateTime :timestamp="subcomment.timestamp" format="fromNow"/></p>
                <ButtonSet class="Comments_Comment_Head_Menu" label="Options" :dropdown="true" size="micro" type="icon" :icon="['solid', 'ellipsis-vertical']">
                  <Button type="transparent" size="micro" v-if="subcomment.from._id==user.id" @click="doDelete(subcomment._id)" :icon="['solid', 'trash']">{{$gettext('Delete')}}</Button>
                  <Button type="transparent" size="micro" v-if="subcomment.from._id==user.id" @click="doEdit(subcomment._id, subcomment.contents)" :icon="['regular', 'edit']">{{$gettext('Edit')}}</Button>
                  <Button type="transparent" size="micro" @click="doReply(subcomment.from._id, comment._id)" :icon="['solid', 'reply']">{{$gettext('Reply')}}</Button>
                </ButtonSet>
              </div>
              <vue-markdown class="Comments_Comment_Body" :key="subcomment.timestamp+'-comment'" v-highlight :anchorAttributes="{target: '_blank'}" :html="true">{{ subcomment.contents }}</vue-markdown>
              <div v-if="comment.associatedFiles.length > 0" class="Comments_Comment_Files">
                <li v-for="(file, i) of comment.associatedFiles" :key="`comment-${_id}-${i}`">
                  <span> <a href="#" @click="openFile(file.uuid, file.name)">{{deUrify(file.name)}}</a> ({{returnFileSize(file.size)}})</span>
                </li>
              </div>
            </li>
          </ul>
        </li>
      </ul>
      <p v-else v-translate>No Comments</p>
    </template>

    <form v-if="editing"  class="edit-form">
      <label :id="uid+'-commentlabel'" v-translate>Edit Comment</label>
      <CommentBox ref="commentboxedit" :labelledBy="uid+'-commentlabel'" :id="uid+'-commentedit'" :key="uid+'-commentedit'" v-model="edit.contents" :team="team" verb="Save" @submitComment="doSave" message="Editing a comment" :cancellable="true" @cancel="cancelEdit"/>
    </form>
    <form class="comment-form" v-else>
      <label :id="uid+'-commentlabel'">Add a {{ noun }}</label>
      <CommentBox ref="commentbox" :labelledBy="uid+'-commentlabel'" :id="uid+'-comment'" :key="uid+'-comment'" v-model="comment.contents" :team="team" :verb="verb" @submitComment="doComment" :fileUpload="fileUploadEnabled" @fileschanged="processFiles"/>
      <div v-if="fileUploadEnabled">
        <div v-if="selectedFiles.length > 0">
          <span v-translate> Chosen files: </span>
          <ul>
            <li v-for="(file, i) in selectedFiles" :key="`file-`+i">
              <span> {{file.name}} ({{returnFileSize(file.size)}})</span>
              <span v-if="file.size >= (1024 * 1024 * $hugrConfig.fileUploadLimitMB)"> (This file is too big. Maximum {{$hugrConfig.fileUploadLimitMB}}MB) </span>
            </li>
          </ul>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import gql from 'graphql-tag';
//TODO live update
import { mapState } from 'vuex';
import UIButtonSet from '@/components/UI/ButtonSet';

import Avatar from '@/components/Helpers/Avatar';

import CommentBox from '@/components/Comments/Box';

export default {
  name: 'Comments',
  data() {
    return {
      uid: this.$uuid.generate(),

      editing: false,
      edit: {
        id: null,
        contents: null,
      },
      selectedFiles: false,
      fileSelectKey: 0,

      comments: false,
      parsedComments: false,
      comment: {
        contents: '',
      },
    };
  },
  apollo: {
    comments: {
      query: gql`
        query Comments($reference: String!) {
          comments: Comments(reference: $reference) {
            _id,
            reference,
            from {
              _id,
              name
            },
            contents,
            edited,
            timestamp,
            associatedFiles {
              name,
              uuid,
              size
            }
            replyTo {
              _id
            }
          }
        }
      `,
      variables() {
        return {
          reference: this.reference,
        };
      },
    },
  },
  watch: {
    comments() {
      this.parseComments();
    },
    refresh() {
      this.$apollo.queries.comments.refetch();
    },
  },
  methods: {
    processFiles( event ) {
      this.selectedFiles = event.target.files;
      this.fileSelectKey++;
    },
    deUrify( name ) {
      return decodeURI( name );
    },
    openFile( uuid, name ) {
      const url = `${this.$hugrConfig.fileContainerURL}/download/${uuid}/${name}`;

      fetch( `${url}`, {
        method: "GET",
        headers: {
          authorization: `Bearer ${localStorage.getItem( "HUGR_ACCESS_TOKEN" )}`,
        },
      } ).then( res => {
        if ( res.ok ) {
          return res.blob();
        }

        return res.text();
      } ).then( data => {
        if ( data instanceof Blob ) {
          const url = window.URL.createObjectURL( data );
          const a = document.createElement( "a" );
          a.style.display = 'none';
          a.setAttribute( 'href', url );
          a.setAttribute( 'download', name );
          document.getElementById( 'app' ).append( a );
          a.click();
          window.URL.revokeObjectURL( url );
          a.remove();
        } else {
          this.$alerts.error( "Failure", data );
        }
      } ).catch( () => {

      } );
    },
    returnFileSize( number ) {
      if ( number < 1024 ) {
        return `${number} bytes`;
      } else if ( number >= 1024 && number < ( 1024 * 1024 ) ) {
        return `${( number / 1024 ).toFixed( 1 )} KB`;
      } else if ( number >= ( 1024 * 1024 ) ) {
        return `${( number / ( 1024 * 1024 ) ).toFixed( 1 )} MB`;
      }
    },
    async uploadFiles( commentID ) {
      const formData = new FormData();
      const url = `${this.$hugrConfig.fileContainerURL}/upload`;
      const files = Array.from( this.selectedFiles );
      for ( const file of files ) {
        if ( file.size <= ( this.$hugrConfig.fileUploadLimitMB * 1024 * 1024 ) ) {
          formData.append( "files", file, file.name.toLowerCase().replaceAll( "/","_" ).replaceAll( "\\","_" ).replaceAll( ",","_" ).replaceAll( " ","_" ) );
        }
      }

      if ( this.reportID ) {
        formData.append( "reportID", this.reportID );
      }

      if ( commentID ) {
        formData.append( "commentID", commentID );
      }

      const result = await fetch( `${url}`, {
        method: "POST",
        headers: {
          authorization: `Bearer ${localStorage.getItem( "HUGR_ACCESS_TOKEN" )}`,
        },
        body: formData,
      } ).then( res => {
        if ( res.ok ) {
          return res.json();
        }

        return {
          success: false,
          anyFailures: true,
        };
      } ).then( json => {
        return json;
      } ).catch( () => {
        return {
          success: false,
          anyFailures: true,
        };
      } );

      return result;
    },
    async parseComments() {
      this.parsedComments = [];

      for( const comment of this.comments.filter( c => c.replyTo == null ) ) {
        const { comment: parsed, tagged } = await this.parseComment( comment.contents );
        const replies = [];
        for( const subcomment of this.comments.filter( c => c.replyTo?._id == comment._id ) ) {
          const { comment: subparsed, tagged: subtagged } = await this.parseComment( subcomment.contents );
          replies.push( {
            ...subcomment,
            contents: subparsed,
            tagged: subtagged,
          } );
        }
        this.parsedComments.push( {
          ...comment,
          contents: parsed,
          tagged,
          replies,
          repliesOpen: false,
        } );
      }
      this.$forceUpdate();
    },
    async parseComment( comment ) {
      const userid = comment.match( /@\{([a-z0-9]*)\}/gm );
      const tagged = [];

      if ( userid ) {
        const res = await this.$apollo.query( {
          query: gql`
          query UsersById($ids: [ObjectID]!) {
            users: UsersById(ids: $ids) {
              _id,
              name
            }
          }
        `,
          variables: {
            ids: userid.map( id => id.replace( '@{', '' ).replace( '}', '' ) ),
          },
        } );
        //this.$alerts.coded('E019', 'F801'); //see notifications spreadsheet
        res.data.users.forEach( user => {
          comment = comment.replace( `@{${user._id}}`, `[@${user.name}](/userprofile/${user._id})` );
          tagged.push( [ user._id, user.name ] );
        } );
      }

      return { comment, tagged };
    },
    doReply( uid, cid ) {
      this.parseComment( `@{${uid}}&nbsp;` ).then( ( { comment } ) => {
        const re = /\[@([^\]]*)]\(\/userprofile\/([^)]*)\)/g;
        let match;
        // eslint-disable-next-line no-cond-assign
        while( match = re.exec( comment ) ) {
          comment = comment.replace( match[0], `<span class="attag" data-active="false" data-id="${match[2]}">${match[1]}</span>` );
          this.$refs.commentbox.setReplyTo( cid, match[1] );
        }

        this.$refs.commentbox.setValue( comment );
        this.$refs.commentbox.focus();
      } );
    },
    doEdit( cid, contents ) {
      const re = /\[@([^\]]*)]\(\/userprofile\/([^)]*)\)/g;
      let match;
      // eslint-disable-next-line no-cond-assign
      while( match = re.exec( contents ) ) {
        contents = contents.replace( match[0], `<span class="attag" data-active="false" data-id="${match[2]}">${match[1]}</span>` );
      }

      this.edit = {
        id: cid,
        contents,
      };
      this.editing = true;

      setTimeout( () => {
        this.$refs.commentboxedit.focus();
      }, 100 );
    },
    cancelEdit() {
      this.editing = false;
      this.edit.id = null;
      this.edit.contents = null;
      //TODO focus management
    },
    doDelete( id ) {
      this.$confirm.simple( 'Cannot be reversed' ).then( result => {
        if( result ) {
          this.$apollo.mutate( {
            mutation: gql`
              mutation removeComment($id: ObjectID!) {
                removed: removeComment(id: $id)
              }
            `,
            variables: {
              id,
            },
          } ).then( () => {
            this.$alerts.success( 'The comment has been removed' );
            this.$apollo.queries.comments.refetch();
          } ).catch( () => {
            this.$alerts.coded( 'E020', 'F802' ); //see notifications spreadsheet
          } );
        }
      } );
    },
    doSave() {
      this.edit.contents = this.contentsToSend( this.edit.contents );
      this.$apollo.mutate( {
        mutation: gql`
          mutation updateComment($id: ObjectID!, $contents: String!) {
            comment: updateComment(id: $id, contents: $contents) {
              _id
            }
          }
        `,
        variables: this.edit,
      } ).then( () => {
        this.$alerts.success( 'Comment updated!' );
        this.$apollo.queries.comments.refetch();
        this.editing = false;
        this.edit.id = null;
        this.edit.contents = null;
      } ).catch( () => {
        this.$alerts.coded( 'E021', 'F803' ); //see notifications spreadsheet
      } );
    },
    async doComment( e ) {
      const contents = this.contentsToSend( this.comment.contents );
      let success = false;
      let commentID = '';
      const replyTo = this.$refs.commentbox.getReplyTo();
      
      await this.$apollo.mutate( {
        mutation: gql`
          mutation addComment($comment: CommentInput!) {
            comment: addComment(comment: $comment) {
              _id
            }
          }
        `,
        variables: {
          comment: {
            reference: this.reference,
            from: this.user.id,
            contents,
            edited: false,
            replyTo,
          },
        },
      } ).then( res => {
        commentID = res.data.comment._id;
        success = true;

      } ).catch( () => {
        this.$alerts.coded( 'E022', 'F804' ); //see notifications spreadsheet
      } );

      if ( success ) {
        if ( this.selectedFiles.length > 0 ) {
          let result = {
            success: false,
            anyFailures: false,
          };

          await this.uploadFiles( commentID ).then( res => {
            result = res;
          } );

          if ( result.success && !result.anyFailures ) {
            this.$alerts.success( 'Comment posted!', `Your comment has successfully been posted and the files uploaded.` );
          } else {
            this.$alerts.warn( 'Comment posted!', `The comment was posted, but some files failed to upload.` );
          }
        } else {
          this.$alerts.success( 'Comment posted!', `Your comment has successfully been posted.` );
        }
        
        this.$apollo.queries.comments.refetch();
        this.comment.contents = '';
        this.selectedFiles = [];
        this.$refs.commentbox.clearReplyTo();
        this.$emit( 'posted' );
        this.$forceUpdate();
      }
    },
    contentsToSend( contents ) {
      let ret = contents;
      const regex = /<span class="attag" data-active="[a-z]*" data-id="([A-Za-z0-9]*)"[A-Za-z0-9\-=" ]*>[A-Za-z0-9 @]*<\/span>/g;
      let match;
      // eslint-disable-next-line no-cond-assign
      while( match = regex.exec( contents ) ) {
        ret = ret.replace( match[0], `@{${match[1]}}` );
      }

      const regex2 = /<span class="attag(?: _fail)?" data-active="[a-z]*"(?: data-id="[A-Za-z0-9]*")?[A-Za-z0-9\-=" ]*(?: )?[A-Za-z0-9\-=" ]*>([A-Za-z0-9 @]*)<\/span>/g;
      let match2;
      // eslint-disable-next-line no-cond-assign
      while( match2 = regex2.exec( contents ) ) {
        ret = ret.replace( match2[0], match2[1] );
      }

      //quickfix for nested issues
      // const regex2 = /<a href="[A-Za-z0-9/#]*" target="_blank" class="attag" data-id="([A-Za-z0-9]*)">[\n\t ]*(@{[A-Za-z0-9]*})[\n\t ]*<\/a>/g;
      // let match2;
      // // eslint-disable-next-line no-cond-assign
      // while( match2 = regex2.exec( ret ) ) {
      //   ret = ret.replace( match2[0], `${match2[2]}` );
      // }

      //quickfix for non-nested empties
      // const regex3 = /<a href="[A-Za-z0-9/#]*" target="_blank" class="attag" data-id="([A-Za-z0-9]*)">[\n\t ]*<\/a>/g;
      // let match3;
      // // eslint-disable-next-line no-cond-assign
      // while( match3 = regex3.exec( ret ) ) {
      //   ret = ret.replace( match3[0], "" );
      // }

      return ret;
    },
  },
  computed: {
    ...mapState( [ 'user' ] ),
  },
  props: {
    fileUploadEnabled: {
      type: Boolean,
      default: false,
    },
    refresh: {
      type: Number,
      default: 0,
    },
    reference: {
      type: String,
      required: true,
    },
    reportID: {
      type: String,
      required: false,
      default: '',
    },
    team: {
      default: false,
    },
    boxOnly: {
      type: Boolean,
      default: () => false,
    },
    noun: {
      type: String,
      default: 'comment',
    },
    verb: {
      type: String,
      default: 'send',
    },
  },
  components: {
    ButtonSet: UIButtonSet,

    Avatar,
    CommentBox,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

  @import '@/assets/styles/variables/_buttons.scss';
  @import '@/assets/styles/variables/_mixins.scss';

  .Comments {
    max-height: 400px;
    list-style: none;
    margin: 0;
    padding:0;
    background: lighten( $hugr-colours-grey, 15% );
    border: 1px solid $hugr-colours-grey;
    margin-bottom: 16px;

    &._sub {
      border: none;
      border-top: 1px solid $hugr-colours-grey;
      margin-top: 16px;
      > li {
        background: lighten( $hugr-colours-grey, 12% );
      }
    }

    @include vertical-scroll;

    &_Comment {
      padding: 16px;
      border-top: 1px solid $hugr-colours-grey;
      position: relative;

      &:first-child {
        border-top: none;
      }

      &._prevVersion {
        background: lighten( $hugr-colours-grey, 12% );
      }

      &_Head {
        > * { display: inline-block; vertical-align: middle; }
        &_Name {
          margin: 0 8px;
        }
        &_Time {
          font-size: 0.8em;
        }
        &_ReplyTo {
          font-size: 0.8em;
          margin-right: 16px;
        }
        &_Menu {
          float: right;
          button {
            color: #000;
            display: block;
            width: 100%;
          }
        }
      }
      &_Menu {
        position: absolute;
        right: 16px;
        top: 40px;
        z-index: 999;
        width: 80px;
        background: $hugr-colours-grey;
        padding: 6px;
        border-radius: 3px;
        button {
          width: 100%;
        }
      }
      &_Body {
        margin-left: 44px;
      }
      &_Files {
        margin-left: 44px;
        border-top: 1px solid $hugr-colours-grey;
        padding: 8px 0;
        margin-top: 8px;
      }
      &_ShowAll {
        font-size: 0.8em;
        text-decoration: none;
        margin-left: 44px;
      }
    }

    .comment-fileInput {
      margin-left: 10px;
    }

    .edit-form {
      position:relative;
      padding: 10px;
      margin-bottom: 40px;
      label {
        color: #262e37;
        margin-bottom: 10px;
      }
      textarea {
        width: 100%;
        height: 100px;
      }
      .actions{
        position: absolute;
        right: -2px;
        bottom: -37px;
        button {
          margin-left: 8px;
        }
      }
    }
  }

  ._darkMode .Comments {
    background: lighten( $hugr-colours-primary, 20% );
    &_Comment {
      &_Head {
        &_Time {
          color: lighten( $hugr-colours-grey, 5% );
        }
      }
      &_Body {
        color: #FFF !important;
      }
    }

    .comment-fileInput {
      margin-left: 10px;
    }

    .edit-form {
      label {
        color: $hugr-colours-grey;
      }
    }
    &._sub {
      > li {
        background: $hugr-colours-primary;
      }
    }
  }

  // ._darkMode #comments {
  //   & > ul {
  //     & > li {
  //       .comment {
  //         .name {
  //           color: $hugr-colours-grey;
  //         }
  //         .edited {
  //           color: darken($hugr-colours-grey, 10%);
  //         }
  //         .comment-actions {
  //           .timestamp {
  //             color: $hugr-colours-grey;
  //           }
  //         }
  //       }
  //     }
  //   }

  //   .comment-form {
  //     label {
  //       color: $hugr-colours-grey;
  //     }
  //   }

  //   .edit-form {
  //     label {
  //       color: $hugr-colours-grey;
  //     }
  //   }
  // }
</style>
