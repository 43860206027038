<template>
  <Modal
    v-show="!hideModal"
    ref="modal"
    :title="$gettext('new Access Token')"

    :showActions="true"

    :loading="submitting"
    loadingText="Adding Token..."

    :submitText="$gettext('Add token')"
    @submit="submit"

    @close="reset()">
    <FormInput idRoot="name_" label="Name" v-model="name" />
    <FormSelect idRoot="expiry_" label="Expiration" v-model="expiry" aria-describedby="expiryDate" :options="{
      1: $gettext('1 day'),
      7: $gettext('7 days'),
      30: $gettext('30 days'),
      60: $gettext('60 days'),
      90: $gettext('90 days'),
      0: $gettext('Never'),
      }"/>
    <span id="expiryDate" class="__fade __small">This token will expire on {{ moment().add(expiry, "days").format("DD/MM/YY") }} at midnight</span>

    <FormSelect idRoot="scope_" label="Scope" v-model="scope" :options="{
      0: $gettext('All'),
      1: $gettext('Team'),
      2: $gettext('Report'),
      }"/>
    <span id="scopeMessage" class="__fade __small">
      <span v-if="scope==0">This access token will be able to access all resources</span>
      <span v-if="scope==1">This access token will only be able to access info and reports on the selected team</span>
      <span v-if="scope==2">This access token will only be able to access the selected report</span>
    </span>

    <template v-if="scope==1">
      <template v-if="Object.keys(teams).length>3">
        <FormSelect idRoot="report_" :label="$gettext('Team')" v-model="team" :options="{
            ...teams
          }"/>

      </template>
      <template v-else>
        <FormFieldSet :legend="$gettext('Team')">
          <div v-for="(teamname, teamvalue) of teams" :key="`team-${teamvalue}-container`">
            <input type="radio" v-model="team" :value="teamvalue" :id="`team-${teamvalue}`"
              :key="`team-${teamvalue}`" />
            <label :for="`team-${teamvalue}`" :key="`team-${teamvalue}-label`">{{teamname}} (Team)</label>
          </div>
        </FormFieldSet>
      </template>
    </template>
  </Modal>
</template>

<script>
  export default {
    name: "AccessTokenModal",
  };
</script>

<script setup>
  import { ref, reactive, computed, inject } from 'vue';
  import { useMutation, useQuery } from '@vue/apollo-composable';

  import gql from 'graphql-tag';
  import moment from 'moment';

  import FormInput from '@/components/Form/Input.vue';
  import FormSelect from '@/components/Form/Select.vue';

  const emit = defineEmits( [ 'success' ] );

  const confirm = inject( 'confirm' );
  const alerts = inject( 'alerts' );

  const modal = ref( null );

  const name = ref( '' );
  const expiry = ref( 7 );
  const scope = ref( 0 );
  const team = ref( null );
  const report = ref( null );
  const permissions = reactive( [] );
  const hideModal = ref( false );

  const submitting = ref( false );

  const show = () => {
    hideModal.value = false;
    modal.value.show();
  };

  const hide = () => {
    hideModal.value = true;
  };

  const reset = () => {
    name.value = '';
    expiry.value = 7;
    scope.value = 0;
    team.value = null;
    report.value = null;
    permissions.value = [];
    submitting.value = false;
    modal.value.reset();
  };

  const { mutate: addAccessToken, onError: addAccessTokenError, onDone: addAccessTokenDone } = useMutation( gql`
    mutation addAccessToken($token: AccessTokenInput!, $expires: Int, $password: String!) {
      accessToken: addAccessToken(token: $token, expires: $expires, password: $password) {
        _id,
        name,
        token
      }
    }
  ` );

  addAccessTokenError( () => {

  } );

  addAccessTokenDone( result => {
    reset();
    emit( 'success', result.data.accessToken );
  } );

  const resource = () => {
    if( parseInt( scope.value ) == 0 ) return null;
    if( parseInt( scope.value ) == 1 ) return team.value;
    if( parseInt( scope.value ) == 3 ) return report.value;
  };

  const submit = () => {
    hide();
    confirm.password().then( result => {
      if( result[0] ) {
        submitting.value = true;
        addAccessToken( {
          token: {
            name: name.value,
            scope: parseInt( scope.value ),
            resource: resource(),
            permissions: permissions.value,
          },
          expires: parseInt( expiry.value ),
          password: result[1],
        } );
      } else {
        // cancelEmail();
        show();
      }
    } );
  };

  const { result: teamResult } = useQuery( gql`
    query {
      teams: Teams {
        _id
        name
      },
    }`,
  );

  const teams = computed( () => {
    if( !teamResult.value ) return {};

    const opts = {};

    for( const team of teamResult.value.teams ) {
      opts[team._id] = team.name;
    }

    return opts;
  } );

  defineExpose( {
    show,
    reset,
  } );
</script>
