<template>
  <WidgetBase title="Common issues">
    <div v-for="( issue, i ) in sorted" :key="'issue-'+i">
      <div v-if="i < 4" class="Issue">
        <div class="Issue_Half">
          <h5 class="Issue_Half_Title">
            {{issue[0]}}
          </h5>
          <div class="Issue_Half_Criteria">
            {{ severityWord( (processed[2][issue[0]]) ) }}  -
            <span v-for=" (criterium, k) in processed[3][issue[0]]" :key="`criteria-`+k">
              WCAG {{ criterium.criterion }} ({{ criterium.level }})
            </span>
          </div>
        </div>
        <div class="Issue_Half">
          <div class="Bar">
            <div class="Bar_Inner" :style="`width: ${issue[1] / sorted[0][1] * 100}%`">

            </div>
          </div>
          <div class="Issue_Half_Count">
            {{ issue[1] }} instance(s) on {{ processed[1][issue[0]].length }} product(s)
          </div>
        </div>
      </div>
    </div>
    <Empty v-if="sorted.length == 0" :text="$gettext( 'No issues on any assessments' )" />
  </WidgetBase>
</template>

<script>
import WidgetBase from './WidgetBase.vue';

export default {
  name: 'UIWidgetCommonIssues',
  props: {
    issues: {
      type: Object,
    },
    reports: {
      type: Array,
    },
  },
  methods: {
    severityWord( severity ) {
      switch( severity ) {
        case 0:
          return "Advisory";
        case 1:
          return "Low";
        case 2:
          return "Medium";
        case 3:
          return "High";
        case 4:
          return "Critical";
      }
    },
  },
  computed: {
    sorted() {
      return Object.entries( this.processed[0] ).sort( ( a,b ) => b[1] - a[1] );
    },
    processed() {
      const issueCount = {};
      const reportCount = {};
      const templateSeverity = {};
      const criteria = {};

      for ( const [ report, issueArray ] of Object.entries( this.issues ) ) {
        issueArray.forEach( issue => {
          if ( issue.template.title in reportCount ) {
            if ( reportCount[issue.template.title].indexOf( report ) < 0 ) {
              reportCount[issue.template.title].push( report );
            }
          } else {
            reportCount[issue.template.title] = [];
            reportCount[issue.template.title].push( report );
          }

          if ( issue.template.title in issueCount ) {
            issueCount[issue.template.title]++;
          } else {
            issueCount[issue.template.title] = 1;
          }

          if ( !( issue.template.title in templateSeverity ) ) {
            templateSeverity[issue.template.title] = issue.template.severity;
          }

          if ( !( issue.template.title in criteria ) ) {
            criteria[issue.template.title] = issue.template.criteria;
          }

        } );
      }

      return [ issueCount, reportCount, templateSeverity, criteria ];
    },
  },
  components: {
    WidgetBase,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  @import '@/assets/styles/variables/_colours.scss';

  .Bar {
    width: 100%;
    height: 16px;
    margin-bottom: 8px;

    &_Inner {
      height: 16px;
      background-color: $hugr-colours-pills-critical;
    }
  }

  .Issue {
    display: flex;
    margin-bottom: 16px;

    &_Half {
      width: 48%;
      margin-right: 8px;

      &_Title {
        margin-bottom: 8px;
        margin-top: 0;
        font-weight: bold;
        font-size: 11pt;
      }

      &_Count {
        font-size: 10pt;
        margin-top: -4px;
      }

      &_Criteria {
        font-size: 10pt;
        margin-top: -6px;
      }
    }
  }
  // .widget {
  //   .container {
  //     padding: 20px;
  //     .inner {
  //       height: 100%;
  //       position: relative;
  //     }
  //   }
  // }
</style>
