<template>
  <Fragment ref="fragment" :icon="['solid', 'circle-exclamation']" :halfTitle="true" :title="!loading&&issue?`${issue.template.identifier}: ${issue.template.title}`:'Loading'" :subtitle="!loading&&issue?issue.identifier:'Loading'" :showBtn="false" @closed="reset(false)">
    <div v-if="issue" class="IssueFragment">
      <div class="IssueFragment_Status">
        <dl class="IssueFragment_Status_Inner">
          <div>
            <dt>Status</dt>
            <dd>
              <span v-if="issue.reportVersion.published"> <!-- <span v-if="user.aclevel==3||instance.reportVersion.published"></span> -->
                <AriaListBox v-model="issue.status" idRoot="instance_" label="Status" :labelVisible="false" @change="statusChange"
                  :options="{
                    'reported': $gettext('Reported'),
                    'in-progress': $gettext('In Progress'),
                    'retest': $gettext('To Retest')
                  }"
                  :extra="{
                    'reported': $gettext('Issue is reported, action is required.'),
                    'in-progress': $gettext('Fix is in progress.'),
                    'retest': $gettext('Issue has been fixed and requires retesting')
                  }"
                  />
              </span>
              <span v-else>
                <AriaListBox idRoot="instance_" :label="$gettext('Status')" :labelVisible="false" v-model="issue.status" @change="statusChange"
                  :options="{
                    'reported': $gettext('Reported'),
                    'closed-removed': $gettext('Closed - Removed'),
                    'closed-fixed': $gettext('Closed - Fixed'),
                    'closed-disproportionate': $gettext('Closed - Disproportionate Burden'),
                    'in-progress': $gettext('In Progress'),
                    'retest': $gettext('To Retest')
                  }"
                  :extra="{
                    'reported': $gettext('Issue is reported, action is required.'),
                    'closed-removed': $gettext('Component with issue has been removed or mitigated.'),
                    'closed-fixed': $gettext('Issue has been fixed.'),
                    'closed-disproportionate': $gettext('Item has been closed due to disproportionate burden'),
                    'in-progress': $gettext('Fix is in progress.'),
                    'retest': $gettext('Issue has been fixed and requires retesting')
                  }"
                />
              </span>
            </dd>
          </div>
          <div v-if="issue.reportVersion.report.team || issue.reportVersion.report.portfolio">
            <dt>Assignee</dt>
            <dd>
              <AriaListBox idRoot="instance_" :label="$gettext('Assignee')" :labelVisible="false" v-model="assignee" @change="assigneeChange"
                  :options="assignOpts"
                />
            </dd>
          </div>
          <div>
            <dt>QA Status</dt>
            <dd>
              <AriaListBox v-if="!issue.reportVersion.published" idRoot="instance_" :label="$gettext('QA Status')" :labelVisible="false" v-model="issue.flag" @change="flagChange"
                :options="{
                  'unchecked': $gettext('Unchecked'),
                  'to-check': $gettext('To Check'),
                  'checked': $gettext('Checked'),
                  'change-required': $gettext('Change Required'),
                  'second-opinion': $gettext('Second Opinion')
                }"
                :extra="{
                  'unchecked': $gettext(`Issue hasn't been checked`),
                  'to-check': $gettext('Issue needs to be checked'),
                  'checked': $gettext('Issue has been checked and confirmed'),
                  'change-required': $gettext('A change is required before the issue can be confirmed'),
                  'second-opinion': $gettext('Issue has been checked but a second opinion is required')
                }"
              />
              <span v-else>{{ issue.flag }}</span>
            </dd>
          </div>
        </dl>
      </div>

      <div class="IssueFragment_Grid __grid">
        <div class="IssueFragment_Grid_Template __grid_column_4">
          <dl class="IssueFragment_Grid_Template_List">
            <div v-if="issue.template?.severity" class="_inline">
              <dt>Severity:</dt>
              <dd><Pill :text="['Advisory', 'Low', 'Medium', 'High', 'Critical'][ issue.template.severity ]" size="small"/></dd>
            </div>
            <div v-if="issue.priority!=null" class="_inline">
              <dt>Priority:</dt>
              <dd><Pill :text="['Lowest', 'Low', 'Medium', 'High', 'Highest'][ issue.priority ]" size="small"/></dd>
            </div>
            <div class="_inline">
              <button class="IssueFragment_Grid_Template_List_KeyIssueBtn" :title="issue.isAKeyIssueInstance?'Is a key issue':'Make a key issue'" :aria-label="issue.isAKeyIssueInstance?'Is a key issue':'Make a key issue'" @click="changeKeyIssue( !issue.isAKeyIssueInstance )">
                <Icon :type="issue.isAKeyIssueInstance?'solid':'regular'" icon="star" />
              </button>
            </div>
            <div class="_inline _right" v-if="!edit&&!issue.reportVersion.published">
              <Button size="micro" type="transparent" @click="doEdit()" aria-label="Edit issue instane" title="Edit issue instane">
                <Icon type="solid" icon="pen" />
              </Button>
              <Button size="micro" type="transparent" @click="mutateissuemodal.show()" aria-label="Change issue template" title="Change issue template">
                <Icon type="solid" icon="exchange-alt" />
              </Button>
            </div>
            <div>
              <dt>Description:</dt>
              <dd>
                <template v-if="issue.template?.description">
                  <vue-markdown :key="issue.template.identifier+'-description'" v-highlight :html="false">{{ issue.template.description }}</vue-markdown>
                  <!-- <span class="_truncate">{{ issue.template.description }}</span> -->
                  <!-- <Button type="link">Show more</Button> -->
                </template>
                <template v-else>No description</template>
              </dd>
            </div>
            <div v-if="issue.template?.criteria?.length">
              <dt>{{ issue.template.criteria.length>1?'WCAG References:':'WCAG Reference:' }}</dt>
              <dd>
                <ul v-if="issue.template.criteria.length>1">
                  <li v-for="criterion of issue.template.criteria" :key="criterion.criteria">{{ criterion.criterion }} {{ criterion.title }} (Level {{ criterion.level }}) [{{ criterion.identifier }}]</li>
                </ul>
                <span v-else>{{ issue.template.criteria[0].criterion }} {{ issue.template.criteria[0].title }} (Level {{ issue.template.criteria[0].level }}) [{{ issue.template.criteria[0].identifier }}]</span>
              </dd>
            </div>
            <div v-if="issue.template?.affected">
              <dt>Affected Users:</dt>
              <dd>{{ issue.template.affected.map( a => a.replace( '-', ' ' ) ).join( ', ' ) }}</dd>
            </div>
            <div v-if="issue.template?.impact">
              <dt>Impact on Users:</dt>
              <dd>
                <vue-markdown :key="issue.template.identifier+'-impact'" v-highlight :html="false">{{ issue.template.impact }}</vue-markdown>
              </dd>
            </div>
            <div class="_inline">
              <dt>Reporter:</dt>
              <dd>
                <span v-if="issue.reporter">{{issue.reporter.name}} <template v-if="issue.reporter.email!= ''">({{issue.reporter.email}})</template></span>
                <span v-else v-translate>Unavailable</span>
              </dd>
            </div>
            <!-- <div>
              <dt>Created:</dt>
              <dd>
                <template v-if="component.created">{{ moment( component.created ).format( "DD/MM/YYYY @ HH:mm") }}</template>
                <template v-else>Unknown</template>
              </dd>
            </div> -->
            <!-- <div>
              <dt>Created By:</dt>
              <dd>
                <template v-if="component.user">{{ component.user.name }}</template>
                <template v-else>Unknown</template>
              </dd>
            </div> -->
          </dl>
          <div class="IssueFragment_Grid_Template_FoundOn">
            <h4 class="IssueFragment_Grid_Template_FoundOn_Title">Found on:</h4>
            <ul class="IssueFragment_Grid_Template_FoundOn_List" aria-live="polite">
              <template v-if="issue.component">
                <li class="IssueFragment_Grid_Template_FoundOn_List_Item">
                  <Icon type="solid" icon="puzzle-piece" />&nbsp;
                  <router-link :to="`/${$hugrConfig.reportRouterReplacement}s/${issue.reportVersion.report.identifier}/${issue.reportVersion.version}/components/${issue.component._id}`" role="button" @click.prevent="goToComponent( issue.component._id )" :title="issue.component.identifier">{{issue.component.identifier}}</router-link>
                </li>
                <li v-for="page in issue.component.pages" v-bind:key="page.name" class="IssueFragment_Grid_Template_FoundOn_List_Item">
                  <Icon type="regular" icon="file" />&nbsp;
                  <router-link :to="`/${$hugrConfig.reportRouterReplacement}s/${issue.reportVersion.report.identifier}/${issue.reportVersion.version}/pages/${page._id}`" role="button" @click.prevent="goToPage( page._id )" :title="page.name">{{page.name}}</router-link>
                </li>
              </template>
              <template v-else>
                <li v-if="issue.page" class="IssueFragment_Grid_Template_FoundOn_List_Item">
                  <Icon type="regular" icon="file" />&nbsp;
                  <router-link :to="`/${$hugrConfig.reportRouterReplacement}s/${issue.reportVersion.report.identifier}/${issue.reportVersion.version}/pages/${issue.page._id}`" role="button" @click.prevent="goToPage( issue.page._id )" :title="issue.page.name">{{issue.page.name}}</router-link>
                  <div class="IssueFragment_Grid_Template_FoundOn_List_Item_Right">
                    <CompliancePill :progress="issue.page.progress" :isCompliant="issue.page.isCompliant" :issues="issue.page.issues.length" size="small" :truncate="true"/>
                    <Button v-if="issue.others.length&&!issue.reportVersion.published" type="icon" size="micro" :icon="['solid', 'times']" @click.prevent="removePrimaryPage()" :title="`Remove issue from ${issue.page.name}`">{{ $gettext('Remove issue from') }} {{ issue.page.name }}</Button>
                  </div>
                </li>
                <li v-else v-translate class="IssueFragment_Grid_Template_FoundOn_List_Item">No key page</li>
                <li v-for="other in issue.others" v-bind:key="other.page.name" class="IssueFragment_Grid_Template_FoundOn_List_Item">
                  <Icon type="regular" icon="file" />&nbsp;
                  <router-link :to="`/${$hugrConfig.reportRouterReplacement}s/${issue.reportVersion.report.identifier}/${issue.reportVersion.version}/pages/${other.page._id}`" role="button" @click.prevent="goToPage( other.page._id )" :title="other.page.name">{{other.page.name}}</router-link>
                  <div class="IssueFragment_Grid_Template_FoundOn_List_Item_Right">
                    <CompliancePill :progress="issue.page.progress" :isCompliant="issue.page.isCompliant" :issues="issue.page.issues.length" size="small" :truncate="true"/>
                    <Button v-if="!issue.reportVersion.published" type="icon" size="micro" :icon="['solid', 'times']" @click.prevent="removeGroupPage(other._id)" :title="`Remove issue from ${other.page.name}`">{{$gettext('Remove issue from')}} {{ other.page.name }}</Button>
                  </div>
                </li>
              </template>
              <!-- <li v-for="page of component.pages" :key="page._id" class="ComponentFragment_Grid_Details_Others_List_Item">
                <Icon type="regular" icon="file" />&nbsp;
                <router-link :to="`/reports/${component.reportVersion.report.identifier}/${component.reportVersion.version}/pages/${page._id}`" role="button" @click.prevent="goToPage( page._id )" :title="page.name">{{ page.name }}</router-link>
                <div class="ComponentFragment_Grid_Details_Others_List_Item_Right">
                  <CompliancePill :progress="page.progress" :isCompliant="page.isCompliant" :issues="page.issues.length" size="small" :truncate="true"/>
                  <Button v-if="!component.reportVersion.published" type="icon" :icon="['solid', 'link-slash']" @click="removePage( page._id )">Remove page from component</Button>
                </div>
              </li> -->
            </ul>
            <div class="IssueFragment_Grid_Template_FoundOn_AddPage"  v-if="!issue.reportVersion.published&&issue.page">
              <AriaSearchSelectLegacy :label="$gettext('Add a Page')" :icon="['regular', 'file']" :iconOnly="true" :dropdown="true" idRoot="page" :gqlOptions="['PAGES_BY_REPORT', issue.reportVersion._id]"  @selected="addGroupPage"/>
            </div>
          </div>
          <div class="IssueFragment_Grid_Template_Traversal">
            <LinkButton :icon="['solid', 'chevron-left']" class="t_prev" type="border" size="micro" :to="`/issue/${issue.prevInstance.identifier}`" v-if="issue.prevInstance">{{$gettext('Previous item')}}</LinkButton>
            <LinkButton :icon="['solid', 'chevron-right', 'after']" class="t_next" type="border" size="micro" :to="`/issue/${issue.nextInstance.identifier}`" v-if="issue.nextInstance">{{$gettext('Next item')}}</LinkButton>
          </div>
        </div>

        <div :class="['IssueFragment_Grid_Particulars', '__grid_column_8', edit?'_editing':'']">
          <figure class="IssueFragment_Grid_Particulars_Figure">
            <Gallery v-model="issue.evidence" :editable="edit" :instance="issue._id"/>
            <figcaption v-if="!edit">
              <h3 v-translate>Description</h3>
              <vue-markdown :key="issue.identifier+'-caption'" v-highlight :html="false">{{ issue.reason }}</vue-markdown>
            </figcaption>
            <TextArea v-else idRoot="issue_" :label="$gettext('Description')" v-model="tmpIssue.reason" />
          </figure>

          <div v-if="!edit">
            <h3 v-translate>Solutions</h3>
            <ul v-if="issue.solutions.length">
              <li v-for="solution in issue.solutions" :key="solution.identifier">
                <Button type="link" @click="solutiondetailsmodal.show( solution._id )">{{ solution.identifier }}: {{ solution.title }}</Button>
              </li>
            </ul>
            <p v-else v-translate>No solutions</p>
          </div>
          <AriaSearchSelect v-else ref="solutionselect" :label="$gettext('Solutions')" :dropdown="true" :multiple="true" idRoot="issue_" :gqlOptions="['SOLUTIONS']" @selected="setSolutions" :priority="issue.template.solutions.map( s => s._id )" :default="issue.solutions.map( s => s._id ).join( ',' )"/>

          <!-- <AriaSearchSelect v-if="edit&&issue.page" ref="pageselect" :label="$gettext('Page')" :dropdown="true" :multiple="true" idRoot="issue_" :gqlOptions="['PAGES_BY_REPORT', report]" @selected="setPages" :default="issue.pages.map( p => p._id ).join( ',' )" :validation="['not-empty']"/>
          <AriaSearchSelect v-if="edit&&issue.component" ref="componentselect" :label="$gettext('Component')" :dropdown="true" idRoot="issue_" :gqlOptions="['COMPONENTS_BY_REPORT', report]" @selected="setComponent" :default="issue.component._id" :validation="['not-empty','valid-option', 'option-matches']"/> -->

          <div class="IssueFragment_Grid_Particulars_Priority" v-if="edit">
            <AriaListBox idRoot="instance_" :label="$gettext('Priority')" :labelVisible="false" v-model="tmpIssue.priority"
              :options="{
                '0': $gettext('Lowest'),
                '1': $gettext('Low'),
                '2': $gettext('Medium'),
                '3': $gettext('High'),
                '4': $gettext('Highest')
              }"
            />
          </div>

          <div v-if="!edit">
            <h3 v-translate>Comments</h3>
            <Comments :refresh="commentKey" v-bind:reference="issue._id" :team="issue.reportVersion.report.team?issue.reportVersion.report.team._id:( issue.reportVersion.report.portfolio ? issue.reportVersion.report.portfolio.teams[0]._id : false )" :fileUploadEnabled="hasPermission('Files','Upload')" :reportID="issue.reportVersion.report._id"/>
          </div>
          <div v-if="edit" class="IssueFragment_Grid_Particulars_EditActions">
            <Button size="small" @click="cancelEdit()">Cancel</Button>
            <Button size="small" type="secondary" @click="saveEdit()">Save</Button>
          </div>
        </div>
      </div>
      <MutateIssueModal ref="mutateissuemodal" :id="issue._id" :report="issue.reportVersion._id" />
      <SolutionDetailsModal ref="solutiondetailsmodal" />
    </div>
    <Loader v-else />
  </Fragment>
</template>

<script>
  export default {
    name: "Issuefragment",
  };
</script>

<script setup>
  import { ref, computed, inject } from 'vue';
  import { useQuery, useLazyQuery, useMutation } from "@vue/apollo-composable";
  import gql from "graphql-tag";
  import moment from 'moment';
  import { useStore } from 'vuex';

  import Gallery from '@/components/UI/Gallery';

  import TextArea from '@/components/Form/TextArea';

  import AriaSearchSelect from '@/components/Aria/SearchSelect3';
  import AriaSearchSelectLegacy from '@/components/Aria/SearchSelect2';
  import AriaListBox from '@/components/Aria/ListBox.vue';

  import CompliancePill from '../components/CompliancePill.vue';
  import Pill from '@/components/UI/Pill.vue';

  import Comments from '@/components/Comments/Index';

  import MutateIssueModal from '@/modals/IssueInstance/Mutate';
  import SolutionDetailsModal from '@/modals/Solution/Details';

  // import EditComponentModal from '@/modals/EditComponent';
  // import UserJourneyModal from '@/modals/UserJourney';
  // import EditUserJourneyModal from '@/modals/EditUserJourney';

  const confirm = inject( 'confirm' );
  const alerts = inject( 'alerts' );

  const store = useStore();
  const hasPermission = computed( () => store.getters.hasPermission );
  const user = computed( () => store.getters.user );

  const emit = defineEmits( [ 'closed', 'goToPage', 'goToUserJourney' ] );
  // const confirm = inject( 'confirm' );

  const fragment = ref( null );
  const doQuery = ref( false );
  const issueId = ref( null );
  const edit = ref( false );
  const commentKey = ref( 0 );
  // const reportVersion = ref( null );

  const mutateissuemodal = ref( null );
  const solutiondetailsmodal = ref( null );

  const { loading, onResult, refetch } = useQuery( gql`
    query IssueInstance($identifier: String!) {
      issue: IssueInstance(identifier: $identifier) {
        _id
        identifier
        evidence
        reason
        priority
        status
        flag
        isAKeyIssueInstance

        nextInstance {
          _id,
          identifier
        }
        prevInstance {
          _id,
          identifier
        }

        reporter {
          _id,
          name,
          email
        }

        assignee {
          _id,
          name,
          email
        }

        page {
          _id
          name
          host
          path
          progress
          isCompliant
          issues {
            _id
          }
        }
        component {
          _id
          identifier,
          pages {
            _id
            name
            progress
            isCompliant
            issues {
              _id
            }
          }
        }

        others {
          _id
          page {
            _id
            name
            host
            path
            progress
            isCompliant
            issues {
              _id
            }
          }
        }

        solutions {
          _id,
          identifier,
          title
        }
        
        template {
          _id
          identifier
          title
          description
          affected
          impact
          severity

          criteria {
            _id
            criterion
            identifier
            title
            level
          }

          solutions {
            _id
          }
        }

        reportVersion {
          _id
          version
          published
          report {
            _id
            identifier

            owner {
              _id,
              name,
              email
            }
            collaborators {
              _id,
              name,
              email
            }
            team {
              _id
              users {
                _id,
                name,
                email
              }
            }
            portfolio {
              _id
              teams {
                _id
                users {
                  _id,
                  name,
                  email
                }
              }
            }
          }
        }
      }
    }
  `,
  {
    identifier: issueId,
  },
  {
    fetchPolicy: 'no-cache',
    enabled: doQuery,
  } );

  const issue = ref( null );

  const show = id => {
    issueId.value = id;
    doQuery.value = true;
    if( issue.value && issue.value._id == id ) refetch();
    onResult( ( { data } ) => {
      issue.value = data.issue;
      // reportVersion.value = data.component.reportVersion._id;
    } );
    fragment.value.show();
  };

  const reset = ( triggerParent = true ) => {
    emit( 'closed', true );
    if( triggerParent ) fragment.value.reset();
    doQuery.value = false;
  };

  const assignOpts = computed( () => {
    const res = {
      0: 'Unassigned',
    };

    if( issue.value.reportVersion.report.team ) {
      for( const user of issue.value.reportVersion.report.team.users ) {
        res[user._id] = `${user.name} (${user.email})`;
      }
    } else if( issue.value.reportVersion.report.portfolio ) {
      for( const team of issue.value.reportVersion.report.portfolio.teams ) {
        for( const user of team.users ) {
          if( Object.keys( res ).indexOf( user._id ) < 0 ) {
            res[user._id] = `${user.name} (${user.email})`;
          }
        }
      }
    }

    return res;
  } );

  const assignee = computed( {
    get() {
      if( issue.value.assignee ) {
        return issue.value.assignee._id;
      }

        return 0;

    },
    set( v ) {
      if( v == 0 ) {
        issue.value.assignee = null;
      } else if( issue.value.reportVersion.report.team ) {
          for( const user of issue.value.reportVersion.report.team.users ) {
            if( user._id == v ) {
              issue.value.assignee = user;
            }
          }
        } else if( issue.value.reportVersion.report.portfolio ) {
          for( const team of issue.value.reportVersion.report.portfolio.teams ) {
            for( const user of team.users ) {
              if( user._id == v ) {
                issue.value.assignee = user;
              }
            }
          }
        }
    },
  } );

  const tmpIssue = ref( null );

  const doEdit = () => {
    tmpIssue.value = { ...issue.value };
    setTimeout( () => {
      edit.value = true;
    }, 100 );
  };

  const { mutate: updateIssueInstanceSolutions } = useMutation(
    gql`
    mutation updateIssueInstanceSolutions($id: ObjectID!, $solutions: [ObjectID]!) {
      instance: updateIssueInstanceSolutions(id: $id, solutions: $solutions) {
        _id,
        solutions {
          _id,
          identifier,
          title
        }
      }
    }
  ` );

  const { mutate: updateIssueInstanceParticulars } = useMutation(
    gql`
    mutation updateIssueInstanceParticulars($id: ObjectID!, $evidence: [String]!, $reason: String!) {
      instance: updateIssueInstanceParticulars(id: $id, evidence: $evidence, reason: $reason) {
        _id,
        reason,
      }
    }
  ` );

  const setSolutions = solutions => {
    tmpIssue.value.solutions = solutions.map( s => { return { _id: s } } );
  };

  const { mutate: setIssueInstancePriority } = useMutation(
    gql`
    mutation setIssueInstancePriority($id: ObjectID!, $priority: Int!) {
      instance: setIssueInstancePriority(id: $id, priority: $priority) {
        priority
      }
    }
  ` );

  // const changePriority = to => {
  //   const priority = parseInt( to );
  //   setIssueInstancePriority( {
  //     id: this.instance._id,
  //     priority,
  //   } ).then( res => {
  //     issue.value.priority = res.data.instance.priority;
  //     alerts.success( 'Priority updated', `Issue instance priority has been updated` );
  //   } ).catch( () => {
  //     alerts.error( `Issue priority not changed`, `There's a bug and you've found it` );
  //     //TODO this should change it back
  //   } );
  // };
  
  const cancelEdit = () => {
    tmpIssue.value = null;
    edit.value = false;
  };

  const saveEdit = () => {
    updateIssueInstanceParticulars( {
      id: issue.value._id,
      evidence: [], //old
      reason: tmpIssue.value.reason,
    } ).then( res => {
      issue.value.reason = res.data.instance.reason;
      alerts.success( 'Particulars updated', `Issue instance particulars have been updated` );
    } ).catch( () => {
      alerts.error( `Oops. We can't update that at the moment :/`, `There's an issue on the backend.` );
    } );

    updateIssueInstanceSolutions( {
      id: issue.value._id,
      solutions: tmpIssue.value.solutions.map( solution => solution._id ),
    } ).then( res => {
      issue.value.solutions = res.data.instance.solutions;
      alerts.success( 'Solutions updated', `Issue instance solutions have been updated` );
    } ).catch( () => {
      alerts.error( `Sorry, we couldn't update the solution :/`, `` );
    } );
    
    const priority = parseInt( tmpIssue.value.priority );
    setIssueInstancePriority( {
      id: issue.value._id,
      priority,
    } ).then( res => {
      issue.value.priority = res.data.instance.priority;
      alerts.success( 'Priority updated', `Issue instance priority has been updated` );
    } ).catch( () => {
      alerts.error( `Issue priority not changed`, `There's a bug and you've found it` );
    } );

    setTimeout( () => {
      edit.value = false;
    }, 100 );
  };

  const isShowing = () => ( fragment.value ? fragment.value.isShowing() : false );

  defineExpose( {
    show,
    reset,
    isShowing,
  } );

  const { mutate: setKeyIssue } = useMutation(
    gql`
    mutation setIssueInstanceKeyIssue($id: ObjectID!, $value: Boolean!) {
      result: setIssueInstanceKeyIssue(id: $id, value: $value)
    }
  ` );

  const changeKeyIssue = value => {
    setKeyIssue( {
      id: issue.value._id,
      value,
    } ).then( () => {
      issue.value.isAKeyIssueInstance = value;
      if( issue.value.isAKeyIssueInstance ) {
        alerts.success( 'Set as key issue', `Instance has been set as a key issue` );
      } else {
        alerts.success( 'Removed from key issues', `Instance has been removed from key issues` );
      }
    } ).catch( () => {
      alerts.error( `Couldn't set as key issue`, `Something went wrong, please try again.` );
    } );
  };

  const { mutate: addIssueInstancePage } = useMutation(
    gql`
    mutation addIssueInstancePage($parent: ObjectID!, $page: ObjectID!) {
      instance: addIssueInstancePage(parent: $parent, page: $page) {
        _id,
        others {
          _id
          page {
            _id
            name
          }
        }
      }
    }
  ` );

  const addGroupPage = ( page, display ) => {
    if( issue.value.page._id == page || issue.value.others.map( instance => instance.page._id ).indexOf( page ) >= 0 ) {
      alerts.warn( 'Issue already on this page', `This issue is already associated with this page, it can't be added again.` );
    } else {
      addIssueInstancePage( {
        parent: issue.value._id,
        page,
      } ).then( res => {
        issue.value.others = res.data.instance.others;
        alerts.success( 'Page added', `Instance has been added to page ${display}` );
      } ).catch( () => {
        alerts.error( `Couldn't add the page`, `Something went wrong, please try again.` );
      } );
    }
  };

  const { mutate: removeIssueInstancePage } = useMutation(
    gql`
    mutation removeIssueInstancePage($parent: ObjectID!, $id: ObjectID!) {
      instance: removeIssueInstancePage(parent: $parent, id: $id) {
        _id,
        others {
          _id
          page {
            _id
            name
          }
        }
      }
    }
  ` );

  const removeGroupPage = id => {
    removeIssueInstancePage( {
      parent: issue.value._id,
      id,
    } ).then( res => {
      issue.value.others = res.data.instance.others;
      alerts.success( 'Page removed', `Instance has been removed from page` );
    } ).catch( err => {
      alerts.error( `Couldn't remove the page`, `Something went wrong, please try again.` );
    } );
  };
  
  const { mutate: changeIssueInstancePage } = useMutation(
    gql`
    mutation changeIssueInstancePage($issue: ObjectID!) {
      instance: changeIssueInstancePage(issue: $issue) {
        _id,
        page {
          _id
          name
        }
      }
    }
  ` );

  const removePrimaryPage = () => {
    changeIssueInstancePage( {
      issue: issue.value._id,
    } ).then( res => {
      issue.value.page = res.data.instance.page;
      alerts.success( 'Page removed', `Instance has been removed from page` );
    } ).catch( err => {
      alerts.error( `Couldn't remove the page`, `Something went wrong, please try again.` );
    } );
  };
  
  const { mutate: addComment } = useMutation(
    gql`
    mutation addComment($comment: CommentInput!) {
      comment: addComment(comment: $comment) {
        _id
      }
    }
  ` );

  const { mutate: setIssueInstanceStatus } = useMutation(
    gql`
    mutation setIssueInstanceStatus($id: ObjectID!, $status: String!) {
      instance: setIssueInstanceStatus(id: $id, status: $status) {
        oldStatus
        issue {
          status
        }
      }
    }
  ` );

  const statusChange = ( e, to ) => {
    const status = to || issue.value.status;

    setIssueInstanceStatus( {
      id: issue.value._id,
      status,
    } ).then( res => {
      const { status } = res.data.instance.issue;
      alerts.success( 'Status changed', `Issue instance status has been changed to ${issue.value.status}` );

      const positive = [ 'closed-fixed' ];
      const negative = [ 'reported' ];
      const indifferent = [ 'closed-removed' ];

      if ( [ ...positive, ...negative, ...indifferent ].indexOf( status ) >= 0 ) {
        const prefilledMessage = () => {
          if ( positive.includes( status ) ) return 'Fixed: ';
          if ( negative.includes( status ) ) return 'Not Fixed: ';
          if ( indifferent.includes( status ) ) return '';

          return '';
        };

        confirm.input( 'Add a comment about this change?', prefilledMessage() ).then( ( [ yesNo, commentToAdd ] ) => {
          if ( yesNo ) {
            addComment( {
              comment: {
                reference: issue.value._id,
                from: user.value.id,
                contents: commentToAdd,
                edited: false,
              },
            } ).then( () => {
              alerts.success( 'Comment posted!', `Your comment has successfully been posted.` );
              commentKey.value += 1;
            } ).catch( () => {
              alerts.error( `The comment can't be added`, `It's not you it's us. We're still in Alpha so please bear with us and <a target="_blank" href="https://hugr.community/c/Post-any-questions-were-here-to-help-and-hopefully-the-answers-will-help-others-too/bugs/">report a bug</a>` );
            } );
          }
        } );
      }
    } ).catch( () => {
      alerts.error( `Issue status not changed`, `There's a bug and you've found it` );
      //TODO this should change it back
    } );
  };

  const { mutate: setIssueInstanceAssignee } = useMutation(
    gql`
    mutation setIssueInstanceAssignee($id: ObjectID!, $assignee: ObjectID!) {
      instance: setIssueInstanceAssignee(id: $id, assignee: $assignee) {
        _id
        assignee {
          _id,
          name,
        }
      }
    }
  ` );

  const { mutate: setIssueInstanceUnAssigned } = useMutation(
    gql`
    mutation setIssueInstanceUnAssigned($id: ObjectID!) {
      instance: setIssueInstanceUnAssigned(id: $id) {
        _id
      }
    }
  ` );

  const assigneeChange = ( e, to ) => {
    const myAssignee = to || assignee.value;
    if( myAssignee != 0 ) {
      setIssueInstanceAssignee( {
        id: issue.value._id,
        assignee: myAssignee,
      } ).then( res => {
        alerts.success( 'Assignee changed', `Issue instance has been assigned to ${res.data.instance.assignee.name}` );
      } ).catch( () => {
        alerts.error( `Issue status not assigned`, `There's a bug and you've found it` );
        //TODO this should change it back
      } );
    } else {
      setIssueInstanceUnAssigned( {
        id: issue.value._id,
      } ).then( () => {
        alerts.success( 'Assignee changed', `Issue instance has been unassigned` );
      } ).catch( () => {
        alerts.error( `Issue status has not been unassigned`, `There's a bug and you've found it` );
        //TODO this should change it back
      } );
    }
  };

  const { mutate: setIssueInstanceFlag } = useMutation(
    gql`
    mutation setIssueInstanceFlag($id: ObjectID!, $flag: String!) {
      instance: setIssueInstanceFlag(id: $id, flag: $flag) {
        _id
      }
    }
  ` );

  const flagChange = ( e, to ) => {
    const flag = to || issue.value.flag;
    setIssueInstanceFlag( {
      id: issue.value._id,
      flag,
    } ).then( () => {
      alerts.success( 'QA Status changed', `Issue instance QA status has been changed to ${issue.value.flag}` );
    } ).catch( () => {
      alerts.error( `QA status not changed`, `There's a bug and you've found it` );
      //TODO this should change it back
    } );
  };

  const goToPage = page => {
    reset();
    emit( 'goToPage', page );
  };

  const goToComponent = component => {
    reset();
    emit( 'goToComponent', component );
  };

  // const goToUserJourney = journey => {
  //   reset();
  //   emit( 'goToUserJourney', journey );
  // };
</script>

<style lang="scss">
  @import '@/assets/styles/variables/_colours.scss';
  @import '@/assets/styles/variables/_fonts.scss';
  @import '@/assets/styles/variables/_mixins.scss';

  .IssueFragment {
    position: relative;
    height: 95%;

    &_Status {
      position: absolute;
      top: -90px;
      right: 0;
      width: 50%;
      &_Inner {
        display: flex;
        gap: 8px 8px;
        columns: 3;
        flex-wrap: wrap;
        flex-direction: row;
        > div {
          width: 30%;
          flex-grow: 1;
          dt {
            font-weight: bold;
          }
          dd {
            margin-left: 0;
          }
        }
      }
    }

    &_Grid {
      height: 100%;
      overflow: hidden;

      &_Template {
        @include vertical-scroll;

        &_List {
          position: relative;
          &_KeyIssueBtn {
            background: transparent;
            border: 1px dashed;
            border-radius: 16px;
            width: 28px;
            height: 28px;
            cursor: pointer;

            &:hover, &:focus {
              background: lighten( $dig-blue, 60% );
            }
          }
          > div {
            margin: 8px 0;
            dt {
              display: inline;
              font-weight: bold;
            }
            dd {
              // display: inline;
              margin-left: 0;
              font-size: 0.8em;

              .md {
                color: #000;
                font-size: 1em;
              }

              ul {
                list-style: none;
                padding: 0;
                margin: 0;
              }

              span._truncate {
                display: block;
                max-height: 100px;
                overflow: hidden;
                position: relative;
                &:before {
                  content: '';
                  display: block;
                  position: absolute;
                  width: 100%;
                  height: 16px;
                  background: linear-gradient(transparent, #FFF);
                  bottom: 0;
                }
              }
            }

            &._inline {
              display: inline-block;
              margin-right: 16px;
              dt {
                display: inline;
              }
              dd {
                display: inline;
                margin-left: 8px;
              }
            }

            &._right {
              position: absolute;
              right: 0;
            }
          }
        }
        
        &_FoundOn {
          border-top: 1px solid $hugr-colours-grey;
          display: flex;
          flex-wrap: wrap;
          flex-direction: row;
          flex-grow: 1;
          &_Title {
            margin-bottom: 0;
            width: 100px;
            font-weight: bold;
            font-family: $hugr-fonts-body;
          }
          &_List {
            list-style: none;
            padding: 0;
            width: calc( 100% - 100px );
            &_Item {
              position: relative;
              margin-bottom: 12px;
              a {
                display: inline-block;
                width: calc( 100% - 126px );
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                vertical-align: middle;
              }
              &_Right {
                position: absolute;
                right: 0;
                top: -2px;
                button {
                  margin-left: 8px;
                }
              }
            }
            &_LastItem {
              button {
                width: 100%;
                text-align: center;
              }
            }
          }
          &_AddPage {
            width: 100%;
          }
        }

        &_Traversal {
          border-top: 1px solid $hugr-colours-grey;
          padding: 16px 0 0;
          display: flex;
          justify-content: space-between;
          gap: 32px;
        }
      }

      &_Particulars {
        background: $hugr-colours-input-surface;
        padding: 16px;
        overflow-y: auto;

        &._editing {
          background: lighten( $hugr-colours-grey, 15% );
        }

        @include vertical-scroll;

        h3 {
          font-size: 0.9em;
          font-weight: bold;
        }

        &_Figure {
          margin: 0;
        }

        &_Priority {
          max-width: 200px;
        }

        &_EditActions {
          width: 100%;
          text-align: right;
          button {
            margin-left: 8px;
          }
        }
      }

    }
  }

  ._darkMode .IssueFragment {
    &_Grid {
      &_Template {

        &_List {
          &_KeyIssueBtn {
            color: white;
            
            &:hover, &:focus {
              background: darken( $dig-blue, 10% );
            }
          }
        }
      }

      &_Particulars {
        background: darken( $hugr-colours-primary, 10% );
      }
    }
  }
</style>
