<template>
  <Body :title="$gettext(`Metrics`)" :fill="true" class="Metrics">
    <div class="Metrics_Timeframe">
      <label for="timeframe">Show metrics for</label>&nbsp;
      <select id="timeframe" v-model="timeframe">
        <option value="1days">Last day</option>
        <option value="7days">Last 7 days</option>
        <option value="1month">Last month</option>
      </select>
    </div>
    <dl class="Metrics_QuickStats">
      <div>
        <dt>Total Logins:</dt>
        <dd>{{ metrics.totalLogins }}</dd>
      </div>
      <div>
        <dt>Total Daily Logins:</dt>
        <dd>{{ metrics.totalDailyLogins }}</dd>
      </div>
      <div>
        <dt>Total Checks Completed:</dt>
        <dd>{{ metrics.totalChecks }}</dd>
      </div>
      <div>
        <dt>Average Daily Checks Completed:</dt>
        <dd>{{ metrics.averageDailyChecks }}</dd>
      </div>
    </dl>

    <h2>Daily active users</h2>
    <div class="Metrics_DailyActive">
      <div class="Metrics_DailyActive_Day" v-for="day in metrics.days" :key="day.id">
        <span class="Metrics_DailyActive_Day_BarContainer">
          <span class="Metrics_DailyActive_Day_BarContainer_Bar" :style="`height: ${(day.activeUsers/maxDailyUsers)*100}%`">
            <span class="Metrics_DailyActive_Day_BarContainer_Bar_Num">{{ day.activeUsers }}</span>
          </span>
        </span>
        <span class="Metrics_DailyActive_Day_Label">{{ day.id }}</span>
      </div>
    </div>

    <h2>Users</h2>
    <div class="Metrics_Users">
      <div class="Metrics_Users_User" v-for="userM in metrics.usersWithMetrics" :key="userM.user._id">
        <h3>{{ userM.user.name }}</h3>
        <dl>
          <div>
            <dt>Total checks:</dt>
            <dd>{{ userM.totalChecks }}</dd>
          </div>
          <div>
            <dt>Total logins:</dt>
            <dd>{{ userM.totalLogins }}</dd>
          </div>
        </dl>
        <h4 v-translate>Reports</h4>
        <ul>
          <li v-for="report in userM.reports" :key="report._id">
            <router-link :to="`/${$hugrConfig.reportRouterReplacement}s/view/${report.identifier}`">{{ report.title }}</router-link>
          </li>
        </ul>
      </div>
    </div>
  </Body>
</template>

<script setup>
  import { ref, computed } from 'vue';
  import { useQuery } from "@vue/apollo-composable";
  import gql from "graphql-tag";
  import moment from 'moment';

  const timeframe = ref( '7days' );
  const fromDate = computed( () => {
    let date = new Date();
    switch( timeframe.value ) {
      case '1days': {
        date = moment().subtract( 1, 'd' );

        break;
      }
      case '7days': {
        date = moment().subtract( 6, 'd' );

        break;
      }
      case '1month': {
        date = moment().subtract( 1, 'M' );

        break;
      }
    }

    return date.set( { hour: 1, minute: 0, second: 0 } );
  } );

  const { loading, onResult, refetch } = useQuery( gql`
    query Metrics( $from: Date, $to: Date ) {
      metrics: Metrics( from: $from, to: $to ) {
        id
        totalLogins
        totalDailyLogins
        totalChecks
        averageDailyChecks

        usersWithMetrics {
          user {
            _id
            name
            email
          } 
          reports {
            _id
            identifier
            title
          }
          totalLogins
          totalChecks
        }

        days {
          id
          checksCompleted
          totalLogins
          activeUsers
        }
      }
    }
  `,
  {
    from: fromDate,
    to: moment().set( { hour: 24, minute: 0, second: 0 } ),
  },
  {
    fetchPolicy: 'no-cache',
    // enabled: doQuery,
  } );

  const metrics = ref( false );

  onResult( ( { data } ) => {
    metrics.value = data.metrics;
  } );

  const maxDailyUsers = computed( () => {
    let max = 0;
    for( const day of metrics.value.days ) {
      if( parseInt( day.activeUsers ) > max ) max = parseInt( day.activeUsers );
    }

    return max;
  } );
</script>

<style lang="scss" scoped>
  
  dl {
    dt, dd {
      display: inline;
      margin: 0;
      padding: 0;
    }
    dd {
      margin-left: 8px;
    }
  }
  .Metrics {
    &_DailyActive {
      // overflow-x: auto;
      // overflow-y: hidden;
      // height: 515px;
      &_Day {
        position: relative;
        display: inline-block;
        vertical-align: bottom;
        margin-bottom: 100px;
        &_BarContainer {
          position: relative;
          display: block;
          height: 250px;
          width: 24px;
          background-color: lightgrey;
          margin-right: 4px;

          &_Bar {
            position: absolute;
            bottom: 0;
            display: inline-block;
            width: 100%;
            background-color: black;
            &_Num {
              color: white;
              padding: 8px;
            }
          }
        }
        &_Label {
          position: absolute;
          display: inline-block;
          transform: rotate(-90deg);
          height: 24px;
          width: 100px;
          bottom: -50px;
          left: -34px;
        }
      }
    }

    &_Users {
      display: flex;
      flex-wrap: wrap;
      gap: 18px;
      &_User {
        flex-grow: 1;
        // border-left: 2px solid;
        // padding-left: 8px;
        background: lighten( grey, 40% );
        padding: 16px;
        h3 {
          margin-top: 0;
        }
      }
    }
  }
</style>
