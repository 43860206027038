<template>
  <Modal ref="modal" :title="$gettext(`Share accessibility ${ shareType }`)" @close="reset()">
    <div class="Pad">
    <div>
      Sharing accessibility {{ shareType }} for: <strong>{{ report.title }}</strong>, version <strong>{{ report.currentVersion.version }}</strong>
    </div>
    <div>
      <div class="Parent">
        <div>
          <Icon class="RMargin" type="solid" icon="globe" />
          <span class="RMargin">Copy private link</span>
          <Pill :hintMode="true" :text="'?'" :tooltip="'Copy link to share with team members.'" :type="'positive'" :circle="true" size="micro" />
        </div>
        <Button type="primary" size="small" @click.prevent="copyPrivate">{{$gettext('Copy private link')}}</Button>
      </div>
      <div class="Parent">
        <div>
          <Icon class="RMargin" type="solid" icon="link" />
          <span class="RMargin">Copy public link</span>
          <Pill :hintMode="true" :text="'?'" :tooltip="'Copy link to share with others. Must be published and available publicly.'" :type="'positive'" :circle="true" size="micro" />
        </div>
        <Button type="primary" size="small" :disabled="report.publicLink == false" @click.prevent="copyPublic">{{$gettext('Copy public link')}}</Button>
      </div>
      <div class="Parent">
        <div>
          <Icon class="RMargin" type="solid" icon="download" />
          <span class="RMargin">Download document</span>
          <Pill :hintMode="true" :text="'?'" :tooltip="'Download as a HTML file, or Save as PDF in print menu.'" :type="'positive'" :circle="true" size="micro" />
        </div>
        <div >
          <Button type="primary" class="RMargin" size="small" @click.prevent="doEmit( 'html' )">{{$gettext('HTML')}}</Button>
          <Button type="primary" size="small" @click.prevent="doEmit( 'print' )">{{$gettext('PDF (Print)')}}</Button>
        </div>
      </div>
    </div>
  </div>
  </Modal>
</template>

<script>
import gql from 'graphql-tag';
import { mapState, mapActions } from 'vuex';
import Pill from '../components/UI/Pill';
import config from '../../config';

export default {
  name: 'ShareModal',
  data() {
    return {
      report: false,
    };
  },
  methods: {
    copyPrivate() {
      navigator.clipboard.writeText( `${config.rootUrl}${this.$route.fullPath}` );

      this.$alerts.success( 'Copied to clipboard' );
      this.$emit( 'success' );
      this.reset();
    },
    copyPublic() {
      navigator.clipboard.writeText( `${config.rootUrl}/view/statement/${this.report.linkString}` );

      if ( this.shareType == "report" ) {
        navigator.clipboard.writeText( `${config.rootUrl}/view/${this.report.linkString}` );
      }

      this.$alerts.success( 'Copied to clipboard' );
      this.$emit( 'success' );
      this.reset();
    },
    doEmit( input ) {
      this.reset();
      setTimeout( () => {
        this.$emit( input );
      }, 100 );
    },
    show( report ) {
      this.report = report;
      this.$refs.modal.show();
    },
    reset() {
      this.report = false;
      this.$refs.modal.reset();
    },
  },
  computed: {

  },
  components: {
    Pill,
  },
  props: {
    data: {
      type: Array,
      default: () => {
        return [];
      },
      required: true,
    },
    shareType: {
      type: String,
      default: "statement",
      require: true,
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

  .Pad {
    margin-top: 32px;
    padding: 8px 16px;
  }

  .RMargin {
    margin-right: 8px;
  }

  .Parent {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .removeButton {
    margin-left: 5px;
  }
  .spacing {
    margin-top: 5px;
  }
</style>
