<template>
  <div ref="pane" class="PreviewPane" v-if="show" tabindex="-1" role="dialog">
    <div class="PreviewPane_Header">
      <h1 class="PreviewPane_Header_Title">
        <span v-if="pretitle" class="PreviewPane_Header_Title_Pre">{{ pretitle }}</span>
        <span class="PreviewPane_Header_Title_Main">{{ title }}</span>
      </h1>
      <Button class="PreviewPane_Header_Close" type="icon" :icon="['solid', 'times']" @click="close()">close</Button>
    </div>
    <div class="PreviewPane_Body">
      <slot></slot>
    </div>
  </div>
</template>

<script setup>
  import { ref, toRefs } from 'vue';

  const emit = defineEmits( [ 'close' ] );

  const props = defineProps( {
    pretitle: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    show: {
      type: Boolean,
      default: false,
    },
  } );

  const {
    show,
  } = toRefs( props );

  let activatingElement;

  const close = () => {
    activatingElement?.focus();
    emit( 'close' );
  };

  const pane = ref( null );
  const doFocus = () => {
    activatingElement = document.activeElement;
    pane.value.focus();
  };

  defineExpose( {
    doFocus,
  } );
</script>

<style lang="scss" scoped>
  @import '@/assets/styles/variables/_header.scss';
  @import '@/assets/styles/variables/_colours.scss';
  @import '@/assets/styles/variables/_sizes.scss';
  @import '@/assets/styles/variables/_mixins.scss';
  .PreviewPane {
    position: fixed;
    z-index: 9999;
    right: calc( $slidePanel-panelWidth + 48px );
    top: calc( $hugr-header-regular-height + 16px );
    width: $previewPane-width;
    border: 1px solid $hugr-colours-grey;
    background: white;
    padding: 16px;
    max-height: calc( 100vh - $hugr-header-regular-height - 64px );

    display: flex;
    flex-direction: column;

    &_Header {
      min-height: 20px;
      flex-shrink: 0;
      &_Title {
        margin: 0;
        font-size: 1em;
        font-weight: bold;
        margin-bottom: 8px;
        &_Pre {
          color: lighten( $hugr-colours-primary, 15% );
          display: block;
        }
        &_Main {
          font-size: 1.1em;
        }
      }
      &_Close {
        position: absolute;
        right: 0;
        top: 0;
        color: black;
      }
    }
    &_Body {
      @include vertical-scroll;
    }
  }
</style>
