/* eslint-disable no-console */
// import { RootState, UserObject } from '@/types';
// import { PermissionGroup, Setting } from './_types';

// @ts-ignore
export const user = state => state.user;
export const report = state => state.report;
export const firstUse = state => state.firstUse;

export const getSettingValue = state => name => {
    if( name ) {
        const { settings } = state.user;
        let value = '';
        if( settings ) {
            settings.forEach( setting => {
                if( setting.key == name ) {
                    value = setting.value;
                }
            } );

            return value;
        }

        return false;
    }
};

export const hasPermission = state => ( name, value ) => {
    if( name && value ) {
        const pGroup = state.user.permissionGroup;
        if ( pGroup ) {
            return !!( pGroup.permissions.filter( perm => perm.permissionName == name && perm.permissionValue == value ) ).length;
        }
    }

    return false;
};
export const hasPermissions = state => permissions => {
    if( permissions ) {
        let matched = 0;
        const pGroup = state.user.permissionGroup;
        if ( pGroup ) {
            permissions.forEach( permission => {
                if( permission.length == 2 ) {
                    if( ( pGroup.permissions.filter( perm => perm.permissionName == permission[0] && perm.permissionValue == permission[1] ) ).length > 0 ) {
                        matched++;
                    }
                } else {
                    return false;
                }
            } );

            if( matched == permissions.length ) {
                return true;
            }

            return false;
        }
    }

    return false;
};

// export const socket = (state: RootState) => state.socket
// export const mapID = (state: RootState) => state.mapID
