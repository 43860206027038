<template>
  <CardSet class="APITokens" title="Access Tokens"
    hlevel="3" :button="{icon:['solid', 'plus', 'after'], text: $gettext('New Token')}"
    @buttonClick="$refs.accesstokenmodal.show()">
    <Well title="Active Access Tokens">
      <dl class="DataList APITokens_List">
        <div v-for="accessToken in accessTokens" :key="accessToken._id" class="APITokens_List_Item">
          <dt class="APITokens_List_Item_Title">{{ accessToken.name }}</dt>
          <dd class="APITokens_List_Item_Data">
            <CopyBox v-if="createdAccessTokens.map( t => t._id ).indexOf( accessToken._id )>=0" class="APITokens_List_Item_Data_Copy" :text="createdAccessTokens.filter( t => t._id == accessToken._id)[0].token" />
            <div class="APITokens_List_Item_Data_Column">
              <div class="__fade __small">
                <span v-if="accessToken.lastUsed">Last used {{ moment( accessToken.lastUsed ).format( "DD/MM/YYYY @ HH:mm") }}</span>
                <span v-else v-translate>This token has not been used</span>
              </div>
              <div class="__small">
                <span v-if="!accessToken.infinite && accessToken.expiration && notExpired( accessToken )" class="__fade">Expires {{ moment( accessToken.expiration ).fromNow() }}</span>
                <span v-else-if="!accessToken.infinite && accessToken.expiration" class="__warn">Expired on {{ moment( accessToken.expiration ).format( "DD/MM/YYYY") }}</span>
                <span v-else class="__warn"><Icon type="solid" icon="warning" /> This token has no expiration date</span>
              </div>
            </div>
            <div class="APITokens_List_Item_Data_Column">
              <div :class="[accessToken.scope==0?'__warn':'__fade', '__small']">
                <Icon v-if="accessToken.scope==0" type="solid" icon="warning"/>&nbsp;
                <span>Scope:</span>&nbsp;
                <span v-if="accessToken.scope==0">Everything</span>
                <span v-if="accessToken.scope==1">{{ accessToken.team?.name }} Team</span>
                <span v-if="accessToken.scope==2">Report</span>
              </div>
            </div>

            <div class="_actions">
              <Button v-if="notExpired( accessToken )" type="border" size="small" @click="doExpireAccessToken( accessToken._id )">{{ $gettext('Expire') }}</Button>
              <Button type="serious" size="small" @click="doDeleteAccessToken( accessToken._id )">{{ $gettext('Delete') }}</Button>
            </div>
          </dd>
        </div>
          
      </dl>
    </Well>
  </CardSet>
  <AccessTokenModal ref="accesstokenmodal" @success="madeNewAccessToken" />
</template>

<script setup>
  import { ref, reactive, computed, inject } from 'vue';
  import { useQuery, useMutation } from '@vue/apollo-composable';

  import gql from 'graphql-tag';
  import moment from 'moment';

  import AccessTokenModal from '@/modals/AccessToken.vue';

  import CopyBox from '@/components/UI/CopyBox.vue';

  const confirm = inject( 'confirm' );
  const alerts = inject( 'alerts' );

  const accesstokenmodal = ref( null );
  const createdAccessTokens = reactive( [] );

  const { result: accessTokensResult, refetch: refetchAccessTokens, onResult: AccessTokensDone } = useQuery( gql`
    query {
      accessTokens: AccessTokens {
        _id,
        name,
        lastUsed,
        expiration,
        infinite,
        scope,

        team {
          _id
          name
        }

        report {
          _id
          title
        }
      }
    }`,
  );

  const accessTokens = computed( () => accessTokensResult.value?.accessTokens ?? {} );

  const notExpired = token => {
    return moment().diff( moment( token.expiration ), 'minutes' ) < 0;
  };

  const { mutate: expireAccessToken, onError: expireAccessTokenError, onDone: expireAccessTokenDone } = useMutation( gql`
    mutation expireAccessToken($id: ObjectID!, $password: String!) {
      response: expireAccessToken(id: $id, password: $password) {
        success
      }
    }
  ` );

  expireAccessTokenError( () => {

  } );

  expireAccessTokenDone( () => {
    refetchAccessTokens();
  } );

  const doExpireAccessToken = id => {
    confirm.password().then( result => {
      if( result[0] ) {
        expireAccessToken( {
          id,
          password: result[1],
        } );
      }
    } );
  };

  const { mutate: deleteAccessToken, onError: deleteAccessTokenError, onDone: deleteAccessTokenDone } = useMutation( gql`
    mutation deleteAccessToken($id: ObjectID!, $password: String!) {
      response: deleteAccessToken(id: $id, password: $password) {
        success
      }
    }
  ` );

  deleteAccessTokenError( () => {

  } );

  deleteAccessTokenDone( () => {
    refetchAccessTokens();
  } );

  const doDeleteAccessToken = id => {
    confirm.password().then( result => {
      if( result[0] ) {
        deleteAccessToken( {
          id,
          password: result[1],
        } );
      }
    } );
  };

  const madeNewAccessToken = token => {
    createdAccessTokens.push( token );
    refetchAccessTokens();
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/styles/variables/_colours.scss';
  .DataList {
    > div {
      padding: 16px;
      border-bottom: 1px solid $hugr-colours-grey;
      &:last-child {
        border-bottom: none;
      }
      dt {
        display: inline-block;
        vertical-align: top;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 20%;
      }
      dd {
        display: inline-block;
        vertical-align: top;
        margin-left: 0;
        width: 80%;
        position: relative;

        ._actions {
          display: inline-block;
          right: 0;
          position: absolute;
          top: -9px;

          button {
            margin-left: 8px;
          }
        }
      }
    }
  }

  .APITokens {
    &_List {
      &_Item {
        &_Data {
          &_Copy {
            width: calc( 100% - 150px);
          }
          &_Column {
            width: 30%;
            display: inline-block;
            vertical-align: top;
          }
        }
      }
    }
  }
  ._darkMode {
    .DataList {
      > div {
        border-bottom: 1px solid darken( $hugr-colours-grey, 40% );
        &:last-child {
          border-bottom: none;
        }
      }
    }
  }
</style>
