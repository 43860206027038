<template>
  <Fragment ref="fragment" :icon="['solid', 'puzzle-piece']" :title="!loading&&component?component.identifier:'Loading'" :btnIcon="['regular', 'edit']" btnLabel="Edit Component" :btnAction="fireEditModal" :showBtn="!component||!component.reportVersion.published" @closed="reset(false)">
    <div v-if="component" class="ComponentFragment">
      <div class="ComponentFragment_Compliance">
        <div class="ComponentFragment_Compliance_DeleteBtn">
          <Button v-if="component.canRemove" type="serious" size="micro" @click="deleteComponent">Delete component</Button>
          <template v-else>
            <Button type="serious" size="micro" disabled="true"
              @mouseenter="tooltipEl?.showTooltip()"
              @mouseleave="tooltipEl?.hideTooltip()"
              @focus="tooltipEl?.showTooltip()"
              @blur="tooltipEl?.hideTooltip()">Delete component</Button>
            <Tooltip ref="tooltipEl" text="Cannot remove component, remove all issues and this component from user journeys in order to delete it." />
          </template>
        </div>
        Component compliance:&nbsp;
        <CompliancePill :progress="component.progress" :isCompliant="component.isCompliant" :issues="component.issues.length"/>
      </div>

      <div class="ComponentFragment_Grid __grid">
        <div class="ComponentFragment_Grid_Details __grid_column_4">
          <template v-if="!guidanceShowing">
            <dl class="ComponentFragment_Grid_Details_List">
              <div>
                <dt>Description:</dt>
                <dd>
                  <template v-if="component.description">{{ component.description }}</template>
                  <template v-else>No description</template>
                </dd>
              </div>
              <div>
                <dt>Created:</dt>
                <dd>
                  <template v-if="component.created">{{ moment( component.created ).format( "DD/MM/YYYY @ HH:mm") }}</template>
                  <template v-else>Unknown</template>
                </dd>
              </div>
              <div>
                <dt>Created By:</dt>
                <dd>
                  <template v-if="component.user">{{ component.user.name }}</template>
                  <template v-else>Unknown</template>
                </dd>
              </div>
            </dl>
            <div class="ComponentFragment_Grid_Details_Screenshot">
              <Screenshot v-if="component.screenshot" :sID="component.screenshot._id" alt="Screenshot of component" :lightbox="true"/>
              <template v-else>
                <Empty v-if="component.reportVersion.published" text="No Screenshot" />
                <Empty v-else :button="{ text: $gettext('Add a screenshot') }" @buttonClick="fireEditModal"/>
              </template>
            </div>
            <div class="ComponentFragment_Grid_Details_Others _first">
              <h4 class="ComponentFragment_Grid_Details_Others_Title">User Journeys:</h4>
              <p class="ComponentFragment_Grid_Details_Others_Subtext __fade">User journeys that include this component.</p>
              <ul class="ComponentFragment_Grid_Details_Others_List">
                <li v-for="journey of component.userJourneys" :key="journey._id" class="ComponentFragment_Grid_Details_Others_List_Item">
                  <Icon type="solid" icon="route" />&nbsp;
                  <router-link :to="`/${$hugrConfig.reportRouterReplacement}s/${component.reportVersion.report.identifier}/${component.reportVersion.version}/journeys/${journey._id}`" role="button" @click="goToUserJourney( journey._id )" :title="journey.title">{{ journey.title }}</router-link>
                  <div class="ComponentFragment_Grid_Details_Others_List_Item_Right">
                    <CompliancePill :progress="journey.progress" :isCompliant="journey.isCompliant" :issues="journey.issues.length" size="small" :truncate="true" />
                    <Button v-if="!component.reportVersion.published" type="icon" :icon="['solid', 'link-slash']" @click="removeFromJourney( journey._id )">Remove component from journey</Button>
                  </div>
                </li>
                <li v-if="!component.reportVersion.published" class="ComponentFragment_Grid_Details_Others_List_LastItem"><Button size="micro" @click="addUserJourney()">Add to a user journey</Button></li>
              </ul>
            </div>
            <div class="ComponentFragment_Grid_Details_Others">
              <h4 class="ComponentFragment_Grid_Details_Others_Title">Featured on:</h4>
              <p class="ComponentFragment_Grid_Details_Others_Subtext __fade">pages that this component is used on.</p>
              <ul class="ComponentFragment_Grid_Details_Others_List">
                <li v-for="page of component.pages" :key="page._id" class="ComponentFragment_Grid_Details_Others_List_Item">
                  <Icon type="regular" icon="file" />&nbsp;
                  <router-link :to="`/${$hugrConfig.reportRouterReplacement}s/${component.reportVersion.report.identifier}/${component.reportVersion.version}/pages/${page._id}`" role="button" @click.prevent="goToPage( page._id )" :title="page.name">{{ page.name }}</router-link>
                  <div class="ComponentFragment_Grid_Details_Others_List_Item_Right">
                    <CompliancePill :progress="page.progress" :isCompliant="page.isCompliant" :issues="page.issues.length" size="small" :truncate="true"/>
                    <Button v-if="!component.reportVersion.published" type="icon" :icon="['solid', 'link-slash']" @click="removePage( page._id )">Remove page from component</Button>
                  </div>
                </li>
                <!-- <li class="ComponentFragment_Grid_Details_Others_List_LastItem"><Button size="micro" @click="addPage()">Add to a page</Button></li> -->
              </ul>
            </div>
          </template>
          <div class="ComponentFragment_Grid_Details_Guidance" v-else>
            <h2>Check guidance</h2>
            <Button class="ComponentFragment_Grid_Details_Guidance_Close" size="micro" type="icon" :icon="['solid', 'times']" @click="guidanceShowing = false">{{ $gettext('Close guidance') }}</Button>
            <iframe class="ComponentFragment_Grid_Details_Guidance_Iframe" v-if="guidanceType=='iframe'" :src="guidance" ref="guidanceIframe"></iframe>
            <vue-markdown v-if="guidanceType=='markdown'" v-highlight :html="false">{{ guidance }}</vue-markdown>
          </div>
        </div>

        <div class="ComponentFragment_Grid_Checks __grid_column_8">
          <ColumnChecks v-if="component&&component.reportVersion&&component.reportVersion._id" :editable="!component.reportVersion.published" :reportVersion="component.reportVersion._id" :column="component._id" type="component" :progress="component.progress" @changed="loadProgress() || updateProgress()" @guidance="showGuidance" @goToIssue="goToIssue"/>
          <Loader v-else />
        </div>
      </div>
    </div>
    <Loader v-else />
  </Fragment>
  <EditComponentModal ref="editcomponentmodal" v-if="component&&component.reportVersion._id" :report="component.reportVersion._id" @edited="refetch"/>
  <UserJourneyModal v-if="component&&component.reportVersion._id" :reportVersion="component.reportVersion._id" ref="userjourneymodal" @success="refetch" @updateJourney="triggerUpdateJourney"/>
  <EditUserJourneyModal ref="edituserjourneymodal" @success="refetch" />
  <!-- <ComponentModal v-if="page&&page.reportVersion._id" :report="page.reportVersion._id" ref="componentmodal" @added="refetch"/> -->
</template>

<script>
  export default {
    name: "ComponentFragment",
  };
</script>

<script setup>
  import { ref, inject, onMounted } from 'vue';
  import { useQuery, useLazyQuery, useMutation } from "@vue/apollo-composable";
  import gql from "graphql-tag";
  import moment from 'moment';

  import CompliancePill from '../components/CompliancePill.vue';

  import EditComponentModal from '@/modals/Component/Edit';
  import UserJourneyModal from '@/modals/UserJourney/Create';
  import EditUserJourneyModal from '@/modals/UserJourney/Edit';

  import ColumnChecks from '../components/ColumnChecks.vue';

  import Tooltip from '@/components/UI/Tooltip.vue';

  const emit = defineEmits( [ 'closed', 'goToPage', 'goToUserJourney' ] );
  const confirm = inject( 'confirm' );

  const editcomponentmodal = ref( null );
  const userjourneymodal = ref( null );
  const edituserjourneymodal = ref( null );
  // const componentmodal = ref( null );

  const fragment = ref( null );
  const doQuery = ref( false );
  const componentId = ref( null );
  const reportVersion = ref( null );

  const tooltipEl = ref( null );

  onMounted( () => {
    const { mutate: sendNavigation } = useMutation(
      gql`
        mutation sendNavigation ($page: String) {
          sendNavigation(page: $page)
        }
      `, {
        variables: {
          page: 'Component Fragment',
        },
    } );
    
    sendNavigation();
  } );

  const { loading, onResult, refetch } = useQuery( gql`
    query Component($id: ObjectID!) {
      component: Component(id: $id) {
        _id
        identifier
        description

        progress
        isCompliant

        canRemove

        screenshot {
          _id
        }

        user {
          _id
          name
        }

        created

        issues {
          _id
        }
        pages {
          _id,
          name
          progress
          isCompliant
          issues {
            _id
          }
        }

        userJourneys {
          _id,
          title
          progress
          isCompliant
          issues {
            _id
          }
        }

        reportVersion {
          _id
          published
          version
          report {
            _id
            identifier
          }
        }
      }
    }
  `,
  {
    id: componentId,
  },
  {
    fetchPolicy: 'no-cache',
    enabled: doQuery,
  } );

  const { load: loadProgress, refetch: updateProgress, onResult: componentProgressResult } = useLazyQuery( gql`
    query Component($id: ObjectID!) {
      component: Component(id: $id) {
        _id
        progress
        isCompliant,
      }
    }
  `,
  {
    id: componentId,
  },
  {
    fetchPolicy: 'no-cache',
    enabled: doQuery,
  } );

  // const componentList = ref( null );

  const component = ref( null );

  const fireEditModal = () => {
    editcomponentmodal.value.show( component.value._id );
  };

  const show = id => {
    componentId.value = id;
    doQuery.value = true;
    if( component.value && component.value._id == id ) refetch();
    onResult( ( { data } ) => {
      component.value = data.component;
      reportVersion.value = data.component.reportVersion._id;
    } );
    fragment.value.show();
  };

  componentProgressResult( ( { data } ) => {
    component.value.progress = data.component.progress;
    component.value.isCompliant = data.component.isCompliant;
  } );

  const reset = ( triggerParent = true ) => {
    emit( 'closed', true );
    if( triggerParent ) fragment.value.reset();
    doQuery.value = false;
  };

  const guidanceShowing = ref( false );
  const guidanceType = ref( null );
  const guidance = ref( '' );
  const guidanceIframe = ref( null );

  const showGuidance = details => {
    const regex = /\[.*\]\((.*)\)/;
    const urlCheck = regex.exec( details );
    if( urlCheck && ( urlCheck[1].indexOf( 'https://docs.hugr.app/' ) == 0 || urlCheck[1].indexOf( 'https://design.homeoffice.gov.uk/' ) == 0 ) ) {
      guidanceType.value = 'iframe';
      // eslint-disable-next-line prefer-destructuring
      guidance.value = `https://cors.diginclusion.com/${urlCheck[1]}`;
    } else {
      guidanceType.value = 'markdown';
      guidance.value = details;
    }
    guidanceShowing.value = true;
  };

  const addUserJourney = () => {
    userjourneymodal.value.show();
    userjourneymodal.value.setAddToExisting( component.value._id, 'component' );
    userjourneymodal.value.addComponent( component.value._id );
  };

  const triggerUpdateJourney = ( journey, componentToAdd ) => {
    edituserjourneymodal.value.show( journey );
    edituserjourneymodal.value.preAddComponent( componentToAdd );
  };

  const { mutate: removeFromUserJourney } = useMutation(
    gql`
    mutation removeFromUserJourney($id: ObjectID!, $item: ObjectID!, $type: String!) {
      journey: removeFromUserJourney(id: $id, item: $item, type: $type) {
        _id,
      }
    }
  ` );

  const removeFromJourney = journey => {
    confirm.simple().then( result => {
      if( result ) {
        removeFromUserJourney( {
          id: journey,
          item: component.value._id,
          type: 'component',
        } ).then( () => {
          refetch();
        } ).catch( error => {
          this.$alerts.generic( error );
        } );
      }
    } );
  };

  const { mutate: removePageFromComponent } = useMutation(
    gql`
    mutation removePageFromComponent($page: ObjectID!, $component: ObjectID!) {
      component: removePageFromComponent(page: $page, component: $component) {
        _id,
      }
    }
  ` );

  const removePage = page => {
    confirm.simple().then( result => {
      if( result ) {
        removePageFromComponent( {
          page,
          component: component.value._id,
        } ).then( () => {
          refetch();
        } ).catch( error => {
          this.$alerts.generic( error );
        } );
      }
    } );
  };

  const { mutate: removeComponent } = useMutation(
    gql`
    mutation removeComponent($id: ObjectID!) {
      removed: removeComponent(id: $id)
    }
  ` );

  const deleteComponent = () => {
    confirm.simple( 'This cannot be undone' ).then( result => {
      if( result ) {
        removeComponent( {
          id: component.value._id,
        } );
        reset();
      }
    } );
  };

  const isShowing = () => ( fragment.value ? fragment.value.isShowing() : false );

  defineExpose( {
    show,
    reset,
    isShowing,
  } );

  const goToPage = page => {
    reset();
    emit( 'goToPage', page );
  };

  const goToUserJourney = journey => {
    reset();
    emit( 'goToUserJourney', journey );
  };

  const goToIssue = identifier => {
    reset();
    emit( 'goToIssue', identifier );
  };
</script>

<style lang="scss">
  @import '@/assets/styles/variables/_colours.scss';

  .ComponentFragment {
    position: relative;
    height: 95%;

    &_Compliance {
      position: absolute;
      top: -56px;
      right: 0;
      
      &_DeleteBtn {
        display: inline-block;
        vertical-align: middle;
        margin-right: 18px;
      }
    }

    &_Grid {
      height: 100%;
      overflow: hidden;

      &_Details {
        &_List {
          > div {
            margin: 6px 0;
            dt {
              display: inline;
              font-weight: bold;
            }
            dd {
              display: inline;
              margin-left: 8px;
            }
          }
        }

        &_Screenshot {
          border: 1px solid $hugr-colours-grey;
          padding: 2px;
          max-height: 300px;
          overflow: hidden;
          text-align: center;

          img {
            width: unset !important;
            height: unset !important;
            max-height: 300px;
            max-width: 100%;
            object-fit: contain;
            overflow: hidden;
          }
        }
        
        &_Others {
          clear: both;
          border-top: 1px solid $hugr-colours-grey;
          &._first {
            border-top: none;
          }
          &_Title {
            margin-bottom: 0;
            width: 35%;
          }
          &_Subtext {
            font-size: 0.8em;
            margin: 0;
            width: 32%;
            height: 30px;
            margin-bottom: 32px;
          }
          &_List {
            float: right;
            list-style: none;
            padding: 0;
            width: 65%;
            margin-top: -80px;
            &_Item {
              position: relative;
              margin-bottom: 12px;
              a {
                display: inline-block;
                width: calc( 100% - 126px );
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              }
              &_Right {
                position: absolute;
                right: 0;
                top: -7px;
              }
            }
            &_LastItem {
              button {
                width: 100%;
                text-align: center;
              }
            }
          }
        }

        &_Guidance {
          position: relative;
          &_Close {
            position: absolute !important;
            right: 0;
            top: 0;
          }
          &_Iframe {
            height: calc( 100vh - 290px );
            width: 100%;
            border: none;
          }
        }
      }

      &_Checks {
        position: relative;
        // border: 1px solid $hugr-colours-grey;
        // background: lighten( $hugr-colours-grey, 15% );
        // padding: 16px;
        height: 95%;

      }
    }
  }
</style>
