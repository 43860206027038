<template>
  <Fragment ref="fragment" :icon="['solid', 'route']" :title="!loading&&journey?journey.title:'Loading'" :btnIcon="['regular', 'edit']" btnLabel="Edit User Journey" :btnAction="fireEditModal" :showBtn="!journey||!journey.reportVersion.published" @closed="reset(false)">
    <div v-if="journey" class="UserJourneyFragment">
      <div class="UserJourneyFragment_Compliance">
        Journey compliance:&nbsp;
        <CompliancePill :progress="journey.progress" :isCompliant="journey.isCompliant" :issues="journey.issues.length"/>
      </div>

      <div class="UserJourneyFragment_Contents">
        <div class="UserJourneyFragment_Contents_Details">
          <dl class="UserJourneyFragment_Contents_Details_List">
            <div>
              <dt>Description:</dt>
              <dd>
                <template v-if="journey.description">{{ journey.description }}</template>
                <template v-else>No description</template>
              </dd>
            </div>
            <div>
              <dt>Importance:</dt>
              <dd>
                <template v-if="journey.importance">{{ journey.importance }}</template>
                <template v-else>Unknown</template>
              </dd>
            </div>
            <div>
              <dt>Created:</dt>
              <dd>
                <template v-if="journey.created">{{ moment( journey.created ).format( "DD/MM/YYYY @ HH:mm") }}</template>
                <template v-else>Unknown</template>
              </dd>
            </div>
            <div>
              <dt>Created By:</dt>
              <dd>
                <template v-if="journey.user">{{ journey.user.name }}</template>
                <template v-else>Unknown</template>
              </dd>
            </div>
            <div>
              <dt>Progress:</dt>
              <dd><ProgressBar id="journeyprogress" class="UserJourneyFragment_Contents_Details_List_Progress" label="Journey Progress: " :hideLabel="true" :value="journey.progress"/></dd>
            </div>
          </dl>
        </div>

        <div class="UserJourneyFragment_Contents_Items">
          <Button v-if="!journey.reportVersion.published" class="UserJourneyFragment_Contents_Items_EditBtn" type="secondary" size="small" @click="fireEditModal">Edit journey</Button>
          <div class="UserJourneyFragment_Contents_Items_Item" v-for="item in journey.items" :key="item">
            <div class="UserJourneyFragment_Contents_Items_Item_Screenshot">
              <div v-if="item.item.screenshot" class="UserJourneyFragment_Contents_Items_Item_Screenshot_Cont">
                <Screenshot :sID="item.item.screenshot._id" alt="Screenshot of component"/>
              </div>
              <Empty v-else text="No screenshot"/>
              <!-- <Empty v-else :button="{ text: $gettext('Add a screenshot') }" @buttonClick="fireEditModal"/> -->
            </div>
            <div class="UserJourneyFragment_Contents_Items_Item_Details">
              <h2 class="UserJourneyFragment_Contents_Items_Item_Details_Title">
                <router-link v-if="item.type=='page'" :to="`/${$hugrConfig.reportRouterReplacement}s/${journey.reportVersion.report.identifier}/${journey.reportVersion.version}/pages/${item.item._id}`" role="button" @click.prevent="goToPage( item.item._id )" :title="item.item.name">
                  <Icon type="regular" icon="file"/>
                  &nbsp;
                  {{ item.item.name }}
                </router-link>
                <router-link v-if="item.type=='component'" :to="`/${$hugrConfig.reportRouterReplacement}s/${journey.reportVersion.report.identifier}/${journey.reportVersion.version}/components/${item.item._id}`" role="button" @click.prevent="goToComponent( item.item._id )">
                  <Icon type="solid" icon="puzzle-piece"/>
                  &nbsp;
                  {{ item.item.identifier }}
                </router-link>
              </h2>

              <dl class="UserJourneyFragment_Contents_Items_Item_Details_List">
                <div v-if="item.type=='page'">
                  <dt>Page title:</dt>
                  <dd>
                    <template v-if="item.item.title">{{ item.item.title }}</template>
                    <template v-else>Untitled</template>
                  </dd>
                </div>
                <div v-if="item.type=='page'" class="UserJourneyFragment_Contents_Items_Item_Details_List_Url">
                  <dt>URL:</dt>
                  <dd>
                    <span v-if="item.item.host||item.item.path">{{ item.item.host }}{{ item.item.path }}</span>
                    <span v-else>No URL</span>
                  </dd>
                </div>
                <div v-if="item.type=='component'">
                  <dt>Description:</dt>
                  <dd>
                    <template v-if="item.item.description">{{ item.item.description }}</template>
                    <template v-else>None</template>
                  </dd>
                </div>
                <div>
                  <dt>Progress:</dt>
                  <dd><ProgressBar class="UserJourneyFragment_Contents_Items_Item_Details_List_Progress" id="itemprogress" label="Progress: " :hideLabel="true" :value="item.item.progress"/></dd>
                </div>
                <div>
                  <dt>Page Compliance:</dt>
                  <dd><CompliancePill :progress="item.item.progress" :isCompliant="item.item.isCompliant" :issues="item.item.issues.length" size="small"/></dd>
                </div>
              </dl>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Loader v-else />
  </Fragment>
  <!-- <EditComponentModal ref="editcomponentmodal" v-if="component&&component.reportVersion._id" :report="component.reportVersion._id" @edited="refetch"/> -->
  <!-- <UserJourneyModal v-if="component&&component.reportVersion._id" :reportVersion="component.reportVersion._id" ref="userjourneymodal" @success="refetch" @updateJourney="triggerUpdateJourney"/> -->
  <EditUserJourneyModal ref="edituserjourneymodal" @success="refetch" />
  <!-- <ComponentModal v-if="page&&page.reportVersion._id" :report="page.reportVersion._id" ref="componentmodal" @added="refetch"/> -->
</template>

<script>
  export default {
    name: "UserJourneyFragment",
  };
</script>

<script setup>
  import { ref, inject } from 'vue';
  import { useQuery, useLazyQuery, useMutation } from "@vue/apollo-composable";
  import gql from "graphql-tag";
  import moment from 'moment';

  import CompliancePill from '../components/CompliancePill.vue';
  import ProgressBar from '../components/UI/ProgressBar.vue';

  import EditUserJourneyModal from '@/modals/UserJourney/Edit';

  const emit = defineEmits( [ 'closed', 'goToPage', 'goToComponent' ] );
  // const confirm = inject( 'confirm' );

  // const editcomponentmodal = ref( null );
  // const userjourneymodal = ref( null );
  const edituserjourneymodal = ref( null );
  // const componentmodal = ref( null );

  const fragment = ref( null );
  const doQuery = ref( false );
  const journeyId = ref( null );
  const reportVersion = ref( null );

  const { loading, onResult, refetch } = useQuery( gql`
    query UserJourney($id: ObjectID!) {
      journey: UserJourney(id: $id) {
        _id
        title
        description
        importance

        progress
        isCompliant,

        items {
          type
          item {
            ... on Page {
              _id
              name
              title
              host
              path

              progress
              isCompliant
              issues {
                _id
              }

              screenshot {
                _id
              }
            }
            ... on Component {
              _id
              identifier
              description

              progress
              isCompliant
              issues {
                _id
              }

              screenshot {
                _id
              }
            }
          }
        }

        user {
          _id
          name
        }

        created

        issues {
          _id
        }

        reportVersion {
          _id
          published
          version
          report {
            _id
            identifier
          }
        }
      }
    }
  `,
  {
    id: journeyId,
  },
  {
    fetchPolicy: 'no-cache',
    enabled: doQuery,
  } );

  const { load: loadProgress, refetch: updateProgress, onResult: journeyProgressResult } = useLazyQuery( gql`
    query UserJourney($id: ObjectID!) {
      journey: UserJourney(id: $id) {
        _id
        progress
        isCompliant,
      }
    }
  `,
  {
    id: journeyId,
  },
  {
    fetchPolicy: 'no-cache',
    enabled: doQuery,
  } );

  // const componentList = ref( null );

  const journey = ref( null );

  const fireEditModal = () => {
    edituserjourneymodal.value.show( journey.value._id );
  };

  const show = id => {
    journeyId.value = id;
    doQuery.value = true;
    if( journey.value && journey.value._id == id ) refetch();
    onResult( ( { data } ) => {
      journey.value = data.journey;
      reportVersion.value = data.journey.reportVersion._id;
    } );
    fragment.value.show();
  };

  journeyProgressResult( ( { data } ) => {
    journey.value.progress = data.journey.progress;
    journey.value.isCompliant = data.journey.isCompliant;
  } );

  const reset = ( triggerParent = true ) => {
    emit( 'closed', true );
    if( triggerParent ) fragment.value.reset();
    doQuery.value = false;
  };

  // const addComponent = () => {
  //   componentmodal.value.show();
  //   componentmodal.value.setAddToExisting( page.value._id );
  //   componentmodal.value.addPage( page.value._id );
  // };

  // const addUserJourney = () => {
  //   userjourneymodal.value.show();
  //   userjourneymodal.value.setAddToExisting( component.value._id, 'component' );
  //   userjourneymodal.value.addComponent( component.value._id );
  // };

  // const triggerUpdateJourney = ( journey, componentToAdd ) => {
  //   edituserjourneymodal.value.show( journey );
  //   edituserjourneymodal.value.preAddComponent( componentToAdd );
  // };

  // const { mutate: removeFromUserJourney } = useMutation(
  //   gql`
  //   mutation removeFromUserJourney($id: ObjectID!, $item: ObjectID!, $type: String!) {
  //     journey: removeFromUserJourney(id: $id, item: $item, type: $type) {
  //       _id,
  //     }
  //   }
  // ` );

  // const removeFromJourney = journey => {
  //   confirm.simple().then( result => {
  //     if( result ) {
  //       removeFromUserJourney( {
  //         id: journey,
  //         item: component.value._id,
  //         type: 'component',
  //       } ).then( () => {
  //         refetch();
  //       } ).catch( () => {
  //         this.$alerts.coded( 'TODO', 'TODO' ); //see notifications spreadsheet
  //       } );
  //     }
  //   } );
  // };

  // const { mutate: removePageFromComponent } = useMutation(
  //   gql`
  //   mutation removePageFromComponent($page: ObjectID!, $component: ObjectID!) {
  //     component: removePageFromComponent(page: $page, component: $component) {
  //       _id,
  //     }
  //   }
  // ` );

  // const removePage = page => {
  //   confirm.simple().then( result => {
  //     if( result ) {
  //       removePageFromComponent( {
  //         page,
  //         component: component.value._id,
  //       } ).then( () => {
  //         refetch();
  //       } ).catch( () => {
  //         this.$alerts.coded( 'TODO', 'TODO' ); //see notifications spreadsheet
  //       } );
  //     }
  //   } );
  // };

  const isShowing = () => ( fragment.value ? fragment.value.isShowing() : false );

  defineExpose( {
    show,
    reset,
    isShowing,
  } );

  const goToComponent = component => {
    reset();
    emit( 'goToComponent', component );
  };

  const goToPage = page => {
    reset();
    emit( 'goToPage', page );
  };
</script>

<style lang="scss">
  @import '@/assets/styles/variables/_colours.scss';
  @import '@/assets/styles/variables/_mixins.scss';

  .UserJourneyFragment {
    position: relative;
    height: 95%;

    &_Compliance {
      position: absolute;
      top: -56px;
      right: 0;
    }

    &_Contents {
      height: 100%;
      overflow: hidden;

      &_Details {
        border-bottom: 1px solid $hugr-colours-grey;
        &_List {
          > div {
            margin: 6px 0;
            dt {
              display: inline;
              font-weight: bold;
            }
            dd {
              display: inline;
              margin-left: 8px;
            }
          }
          &_Progress {
            display: inline-block;
            width: 300px !important;
          }
        }
      }

      &_Items {
        position: relative;
        height: calc( 100vh - 355px);
        @include vertical-scroll;

        &_EditBtn {
          position: absolute !important;
          top: 16px;
          right: 16px;
        }

        &_Item {
          margin: 32px 0;
          &_Screenshot {
            width: 200px;
            display: inline-block;
            vertical-align: top;

            border: 1px solid $hugr-colours-grey;

            height: 150px;
            overflow: hidden;
            position: relative;
            &_Cont {
              margin: 0;
              position: absolute;
              top: 50%;
              -ms-transform: translateY(-50%);
              transform: translateY(-50%);
              text-align: center;
              width: 100%;
              img {
                width: unset !important;
                height: unset !important;
                max-height: 150px;
                max-width: 100%;
                object-fit: contain;
                overflow: hidden;
              }
            }
          }
          &_Details {
            width: calc( 100% - 250px );
            display: inline-block;
            vertical-align: top;
            padding: 0 16px;

            &_Title {
              font-size: 14pt;
              margin-top: 0;
              font-weight: bold;
              margin-bottom: 16px;
              a {
                text-decoration: none;
                display: inline-block;
                width: 90%;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              }
            }

            &_List {
              margin: 0;
              > div {
                margin: 6px 0;
                dt {
                  display: inline;
                  font-weight: bold;
                }
                dd {
                  display: inline;
                  margin-left: 8px;
                }
              }

              &_Progress {
                display: inline-block;
                width: 300px !important;
              }
              &_Url {
                span {
                  text-decoration: none;
                  display: inline-block;
                  vertical-align: top;
                  width: 95%;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                }
              }
            }
          }
        }
      }
    }
  }
</style>
