<template>
  <div class="ButtonSet" ref="set">
    <span v-if="dropdown">
      <Button :type="type" :direction="direction" :size="size" :icon="type=='icon'?icon:['solid', 'chevron-down', 'after']" :aria-expanded="expanded" @click.prevent.stop="toggleExpanded" v-click-away="clickAway">
        <Icon v-if="icon.length&&type!='icon'" :type="icon[0]" :icon="icon[1]" />
        {{label}}
      </Button>
    </span>
    <div v-if="!dropdown||expanded" role="group" :aria-label="label" :class="['ButtonSet_Inner', dropdown?'_dropdown':'']">
      <slot :signal="signal"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UIButtonSet',
  data() {
    return {
      expanded: false,
      signal: {
        size: this.size,
        direction: this.direction,
      },
    };
  },
  methods: {
    tabAway( e ) {
      const parentEl = this.$refs.set;
      const isChild = parentEl.contains( e.srcElement );
      if( !isChild ) this.clickAway();
    },
    toggleExpanded() {
      this.expanded = !this.expanded;
      if( this.expanded ) {
        document.addEventListener( 'keyup', this.tabAway );
      }
    },
    clickAway() {
      this.expanded = false;
      document.removeEventListener( 'keyup', this.tabAway );
    },
  },
  props: {
    direction: {
      type: String,
      default: 'horizontal',
    },
    size: {
      type: String,
      default: 'normal',
    },
    label: {
      type: String, //this should be required
      default: '',
    },
    dropdown: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'primary',
    },
    icon: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  @import '@/assets/styles/variables/_colours.scss';

  .ButtonSet {
    position: relative;
    &.align-right {
      float: right;
    }
    &_Inner {
      &._dropdown {
        // min-width: 150px;
        right: 0;
        position: absolute;
        z-index: 99999;
        background: #FFF;
        border: 1px solid $hugr-colours-grey;
        border-radius: 5px;
        margin-top: 5px;
        padding: 5px;
        display: flex;
        flex-direction: column;
        button {
          display: block;
          width: 100%;
          margin-bottom: 5px;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  ._darkMode .ButtonSet {
    &_Inner {
      &._dropdown {
        background: $hugr-colours-primary;
      }
    }
  }
</style>
