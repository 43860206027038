<template>
  <p>Security settings and recommendations</p>
  <template v-if="me">
    <!-- <Well title="Recent Activity">
      <ul v-if="false">
        <li></li>
      </ul>
      <Empty v-else :text="$gettext('No recent activity')"/>
    </Well> -->

    <Well title="How you sign in">
      <dl class="DataList">
        <div>
          <dt>Two Factor Authentication</dt>
          <dd>
            <span :class="[me.mfaEnabled?'__success':'__warn']">
              <Icon type="solid" :icon="me.mfaEnabled?'circle-check':'circle-exclamation'" />&nbsp;
              <span v-if="me.mfaEnabled">Enabled</span>
              <span v-else-if="me.mfaMethods?.length">Enable a 2FA method</span>
              <span v-else>Add a 2FA Method</span>
            </span>

            <div class="_actions">
              <Button type="border" size="small" @click="authmodal.show()">{{$gettext('Add authenticator')}}</Button>
              <Button type="border" size="small" @click="securitykeymodal.show()">{{$gettext('Add hardware key')}}</Button>
              <Button v-if="hasPermission( 'Auth', 'EmailMFA' ) && !emailMFAPresent" type="border" size="small" @click="emailmfamodal.show()">{{$gettext('Add email MFA')}}</Button>
            </div>
          </dd>
        </div>
        <div>
          <dt>Password</dt>
          <dd>
            <span :class="[!me.passwordLastChanged||dateDiff>=1?'__warn':'__fade']">
              <span>Last changed:</span>&nbsp;
              <span v-if="me.passwordLastChanged">{{ moment( me.passwordLastChanged ).format( "DD/MM/yyyy" ) }}</span>
              <span v-else>unknown</span>
            </span>
          
            <div class="_actions">
              <Button type="border" size="small" @click="passwordresetmodal.show()">{{$gettext('Change password')}}</Button>
            </div>
          </dd>
        </div>
        <div>
          <dt>Security Keys</dt>
          <dd>
            <span class="__fade">{{ keys }} keys</span>
            
            <div class="_actions">
              <Button type="border" size="small" @click="securitykeymodal.show()">{{$gettext('Add hardware key')}}</Button>
            </div>
          </dd>
        </div>
      </dl>
    </Well>

    <div class="__grid">
      <Well title="Trusted Devices" class="TrustedDevices __grid_column_6">
        <template v-if="me.mfaEnabled">
          <p v-translate>Devices can be trusted so that you do not need a second step in order to login, such as your own computer.</p>
          <p v-translate>To do this select "trust this device" when you next login during the second step.</p>
          <div class="TrustedDevices_Actions">
            <Button type="border" size="small" @click="clearTrustedDevices()">{{$gettext('Revoke all trusted devices')}}</Button>
          </div>
        </template>
        <template v-else>
          <p v-translate>Trusted devices allow easier login when multi factor Authentication is enabled.</p>
          <div class="TrustedDevices_Actions">
            <Button type="border" size="small" @click="authmodal.show()">{{$gettext('Add authenticator')}}</Button>
            <Button type="border" size="small" @click="securitykeymodal.show()">{{$gettext('Add hardware key')}}</Button>
            <Button v-if="hasPermission( 'Auth', 'EmailMFA' ) && !emailMFAPresent" type="border" size="small" @click="emailmfamodal.show()">{{$gettext('Add email MFA')}}</Button>
          </div>
        </template>
      </Well>
      <Well title="2FA Methods" class="__grid_column_6">
        <ul class="MFA_Methods">
          <template v-for="method in me.mfaMethods">
            <li :key="method._id" v-if="method.confirmed" class="MFA_Methods_Method">
              <span class="MFA_Methods_Method_Icon">
                <Icon v-if="method.type=='key'" type="solid" icon="key"/>
                <Icon v-if="method.type=='authenticator'" type="solid" icon="unlock"/>
                <Icon v-if="method.type=='email'" type="solid" icon="envelope"/>
              </span>
              <span class="MFA_Methods_Method_Body">
                <h4 class="MFA_Methods_Method_Body_Name">{{ method.name }}</h4>
                <span class="MFA_Methods_Method_Body_Type __fade">{{ method.type }} ({{ method.active?$gettext('enabled'):$gettext('disabled') }}{{ method.primary?$gettext(', primary'):'' }})</span>
              </span>
              <span class="MFA_Methods_Method_Actions">
                <Button size="micro" type="border" v-if="!method.primary&&method.active" @click="setPrimaryMFAMethod( { method: method._id } )">{{$gettext('Make primary')}}</Button>

                <Button size="micro" type="border" v-if="method.active" @click="disableMFAMethod( { method: method._id } )">{{$gettext('Disable')}}</Button>
                <Button size="micro" type="border" v-else @click="enableMFAMethod( { method: method._id } )">{{$gettext('Enable')}}</Button>

                <Button size="micro" type="serious" @click="deleteMFAMethod(method._id)">{{$gettext('Delete')}}</Button>
              </span>
            </li>
          </template>
        </ul>
      </Well>
    </div>
  </template>
  <Loader v-else />

  <AuthenticatorModal ref="authmodal" @success="refetchMe();"/>
  <EmailMFAModal ref="emailmfamodal" @success="refetchMe();"/>
  <SecurityKeyModal ref="securitykeymodal" @success="refetchMe()"/>
  <PasswordResetModal ref="passwordresetmodal" @success="refetchMe()"/>
</template>

<script setup>
  import { computed, ref, inject } from 'vue';
  import { useStore } from 'vuex';
  import { useQuery, useMutation } from '@vue/apollo-composable';
  import gql from 'graphql-tag';
  import moment from 'moment';

  import AuthenticatorModal from '@/modals/Authenticator';
  import EmailMFAModal from '@/modals/EmailMFA';
  import SecurityKeyModal from '@/modals/securityKey';
  import PasswordResetModal from '@/modals/PasswordReset';

  const store = useStore();

  const hasPermission = computed( () => store.getters.hasPermission );

  const confirm = inject( 'confirm' );
  const alerts = inject( 'alerts' );

  const authmodal = ref( null );
  const securitykeymodal = ref ( null );
  const emailmfamodal = ref ( null );
  const passwordresetmodal = ref( null );

  const { result: meResult, refetch: refetchMe } = useQuery( gql`
    query {
      me: WhoAmI {
        _id,
        name,
        passwordLastChanged,
        mfaEnabled,
        mfaMethods {
          _id
          name
          type
          confirmed,
          primary,
          active
        }
      }
    }`,
  );

  const me = computed( () => meResult.value?.me ?? {} );

  const keys = computed( () => me.value?.mfaMethods?.filter( m => m.type == 'key' ).length ?? 0 );
  const emailMFAPresent = computed( () => me.value?.mfaMethods?.filter( m => m.type == 'email' ).length > 0 );

  const { mutate: clearTrustedDevices, onError: clearTrustedDevicesError } = useMutation( gql`
    mutation clearTrustedDevices {
      response: clearTrustedDevices {
        success
      }
    }
  ` );

  clearTrustedDevicesError( error => {
    alerts.generic( error );
  } );

  const { mutate: setPrimaryMFAMethod, onError: setPrimaryMFAMethodError, onDone: setPrimaryMFAMethodDone } = useMutation( gql`
    mutation setPrimaryMFAMethod($method: ObjectID) {
      response: setPrimaryMFAMethod(method: $method) {
        success
      }
    }
  ` );

  setPrimaryMFAMethodDone( () => {
    alerts.success( 'Primary 2FA method updated' );
    refetchMe();
  } );

  setPrimaryMFAMethodError( error => {
    //alerts.coded( 'E031', 'FTODO' );
    alerts.generic( error );
  } );

  const { mutate: disableMFAMethod, onError: disableMFAMethodError, onDone: disableMFAMethodDone } = useMutation( gql`
    mutation disableMFAMethod($method: ObjectID) {
      response: disableMFAMethod(method: $method) {
        success
      }
    }
  ` );

  disableMFAMethodDone( () => {
    alerts.success( '2FA Method Disabled' );
    refetchMe();
  } );

  disableMFAMethodError( error => {
    //alerts.coded( 'E034', 'FTODO' );
    alerts.generic( error );
  } );

  const { mutate: enableMFAMethod, onError: enableMFAMethodError, onDone: enableMFAMethodDone } = useMutation( gql`
    mutation enableMFAMethod($method: ObjectID) {
      response: enableMFAMethod(method: $method) {
        success
      }
    }
  ` );

  enableMFAMethodDone( () => {
    alerts.success( '2FA Method Enabled' );
    refetchMe();
  } );

  enableMFAMethodError( error => {
    //alerts.coded( 'E033', 'FTODO' );
    alerts.generic( error );
  } );

  const { mutate: doDeleteMFAMethod, onError: deleteMFAMethodError, onDone: deleteMFAMethodDone } = useMutation( gql`
    mutation deleteMFAMethod($method: ObjectID) {
      response: deleteMFAMethod(method: $method) {
        success
      }
    }
  ` );

  deleteMFAMethodDone( () => {
    alerts.success( '2FA Method deleted' );
    refetchMe();
  } );

  deleteMFAMethodError( error => {
    //alerts.coded( 'E032', 'FTODO' );
    alerts.generic( error );
  } );

  const deleteMFAMethod = method => {
    confirm.simple( 'This cannot be undone' ).then( result => {
      if( result ) {
        doDeleteMFAMethod( { method } );
      }
    } );
  };

  const dateDiff = computed( () => moment().diff( me.value.passwordLastChanged, 'years' ) );

</script>

<style lang="scss" scoped>
  @import '@/assets/styles/variables/_colours.scss';
  .DataList {
    > div {
      padding: 16px;
      border-bottom: 1px solid $hugr-colours-grey;
      &:last-child {
        border-bottom: none;
      }
      dt {
        display: inline-block;
        vertical-align: top;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 25%;
      }
      dd {
        display: inline-block;
        vertical-align: top;
        margin-left: 0;
        width: 75%;
        position: relative;

        ._actions {
          display: inline-block;
          right: 0;
          position: absolute;
          top: -9px;

          button {
            margin-left: 8px;
          }
        }
      }
    }
  }

  .TrustedDevices {
    position: relative;
    &_Actions {
      float: right;
      button {
        margin-left: 8px;
      }
    }
  }

  .MFA {
    &_Methods {
      // border: 1px solid $hugr-colours-grey;
      // border-radius: 3px;
      list-style: none;
      padding: 0;
      margin: 0;
      &_Method {
        border-bottom: 1px solid $hugr-colours-grey;
        padding: 5px 10px;
        position: relative;
        &:last-child {
          border-bottom: none;
        }
        &_Icon {
          display: inline-block;
          vertical-align: middle;
          margin-right: 10px;
        }
        &_Body {
          display: inline-block;
          vertical-align: middle;
          max-width: 48%;
          &_Name {
            font-family: "Quicksand", serif;
            font-weight: 400;
            margin: 0;
            width: 100%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          &_Type {
            font-size: 0.8em;
            // color: lighten($hugr-colours-primary, 25%);
          }
        }
        &_Actions {
          position: absolute;
          right: 0;
          top: 0;
          display: inline-block;
          vertical-align: middle;
          button {
            display: inline-block;
            vertical-align: top;
            width: auto;
            margin-right: 10px;
            margin-top: 10px;
          }
        }
      }
    }
    &_Actions {
      margin-top: 20px;
      button {
        margin-right: 20px;
      }
    }
  }

  ._darkMode {
    .DataList {
      > div {
        border-bottom: 1px solid darken( $hugr-colours-grey, 40% );
        &:last-child {
          border-bottom: none;
        }
      }
    }

    .MFA {
      &_Methods {
        &_Method {
          border-bottom: 1px solid darken( $hugr-colours-grey, 40% );
          &:last-child {
            border-bottom: none;
          }
        }
      }
    }
  }
</style>
