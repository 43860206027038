<template>
  <Modal
    ref="modal"
    :title="$gettext('Add Email MFA')"
    class="EmailMFAModal"
    
    :showActions="true"
    submitText="Verify"

    @submit="verify()"
    @close="reset()">
    <div class="EmailMFAModal_Top">
      <div class="EmailMFAModal_Top_Text">
        <div>
          <p v-show="failed.length < 1" v-translate>You should have received a login email with a code in it. Please enter the code. </p>
          <p v-show="failed.length > 0"> Something went wrong: {{ failed }} </p>
        </div>
      </div>
    </div>
    <FormFieldSet legend="Verify email">
      <FormInput idRoot="verify_" label="Code" v-model="magicCode" />
    </FormFieldSet>
  </Modal>
</template>

<script>
import gql from 'graphql-tag';
import { mapState, mapActions } from 'vuex';

import FormInput from '@/components/Form/Input';
import FormFieldSet from '@/components/Form/FieldSet';

export default {
  name: 'EmailMFAModal',
  created() {
  },
  data() {
    return {
      magicCode: '',
      failed: '',
      verified: false,
    };
  },
  methods: {
    show() {
      this.failed = '';
      this.$apollo.mutate( {
        mutation: gql`mutation ($id: ObjectID!) {
          result: requestMFAEmail(id: $id) {
            success,
            reason
          }
        }`,
        variables: {
          id: this.user.id,
        },
      } ).then( res => {
        const { result } = res.data;
        if( result.success ) {
          this.$refs.modal.show();
        } else {
          this.failed = result.reason;
          this.$alerts.error( 'Try again in a minute', this.failed );
        }
      } ).catch( error => {
        this.failed = `Email request failed, We think this is a problem on our end.`;
        this.$alerts.generic( error );
      } );
    },
    verify() {
      this.failed = '';
      this.$apollo.mutate( {
        mutation: gql`
          mutation verifyEmailMFA($magicCode: String!) {
            verified: verifyEmailMFA(magicCode: $magicCode)
          }
        `,
        variables: {
          magicCode: this.magicCode,
        },
      } ).then( res => {
        this.verified = res.data.verified;
        if( this.verified ) {
          this.$alerts.success( 'Email MFA added', `Email MFA has been set up successfully` );
          this.$emit( 'success' );
          this.reset();
        } else {
          this.failed = 'Incorrect or invalid code. Please check and try again.';
          this.$alerts.error( 'Invalid code', this.failed );
        }
      } ).catch( error => {
        this.failed = 'Failed to verify with server. Try again later.';
        this.$alerts.generic( error );
      } );
    },
    reset() {
      this.magicCode = '';
      this.failed = '';
      this.$refs.modal.reset();
    },
  },
  computed: {
    ...mapState( [ 'user' ] ),
  },
  props: {

  },
  components: {
    FormInput,
    FormFieldSet,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .EmailMFAModal {
    &_Top {
      & > * {
        display: inline-block;
        vertical-align: top;
      }
      &_QR {
        width: 40%;
      }
      &_Text {
        width: 60%;
        > div {
          padding-left: 20px;
        }
      }
    }
  }
</style>
