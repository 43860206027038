<template>
  <!-- TODO: Needs updated actions -->
  <Modal ref="modal" :title="$gettext('Export to Jira')" @close="reset()">
    <form @submit.prevent="submitForm">
      <div v-if="jirasites.length > 0">
        <div v-if="!exporting">
          <FormSelect idRoot="jira_" ref="jiraSite" :label="$gettext('Jira Site')" v-model="jiraSite" :options="{
            ...jiraSitesByName
          }"/>
          <FormInput idRoot="jira_" ref="reportIdentifier" :label="$gettext('Report Identifier')" v-model="identifier" disabled/>
          <FormInput idRoot="jira_" ref="pName" :label="$gettext('Project Name')" v-model="projectName" :validation="['not-empty']" />
          <FormInput idRoot="jira_" ref="pKey" :label="$gettext('Project Key')" v-model="projectKey" :hintText='$gettext(`At least 2 letters and less than 10, and must be uppercase letters`)' :validation="['not-empty']"/>
          <FormInput idRoot="jira_" ref="pDescription" :label="$gettext('Project Description')" v-model="projectDescription" :validation="['not-empty']" />

          <div class="actions">
            <Button type="primary" @click.prevent="reset">{{$gettext('Cancel')}}</Button>
            <Button type="secondary" htype="submit">{{$gettext('Export to JIRA')}}</Button>
          </div>
        </div>
        <div v-else>
          {{$gettext("Export has been initiated, please wait a moment.")}}
        </div>
      </div>

      <div v-else>
        {{$gettext("Trying to fetch jira sites associated with the account. If this lasts longer than a minute, please make sure you have a Jira account linked and Jira sites present on the account.")}}
        <div class="actions">
          <Button type="primary" @click.prevent="reset">{{$gettext('Cancel')}}</Button>
        </div>
      </div>
    </form>
  </Modal>
</template>

<script>
import gql from 'graphql-tag';
import { mapState, mapActions } from 'vuex';

import AriaSearchSelect from '@/components/Aria/SearchSelect2';

import FormFieldSet from '@/components/Form/FieldSet';
import FormInput from '@/components/Form/Input';
import FormSelect from '@/components/Form/Select';
import FormImage from '@/components/Form/Image';
import FormTextArea from '@/components/Form/TextArea';

export default {
  name: 'JiraExportModal',
  data() {
    return {
      exporting: false,
      jiraSite: false,
      jirasites: [],
      identifier: this.$attrs.report.identifier,
      projectDescription: "",
      projectName: "",
      projectKey: "",
    };
  },
  watch: {
    report() {
      if( this.$attrs.report.team ) {
        this.fetchSites();
      }
    },
  },
  methods: {
    fetchSites() {
      this.$apollo.query( {
        query: gql`
          query JiraSitesFromTeam($id: ObjectID!) {
            sites: JiraSitesFromTeam(id: $id)
          }
      `,
      variables: {
          id: this.$attrs.report.team?._id,
      },
      } ).then( res => {
        console.log( "FETCHED SITES" );
        this.jirasites = JSON.parse( res.data.sites );
      } ).catch( err => {
        JSON.stringify( "COULDN'T FETCH SITES" );
        console.log( err );
      } );
    },
    submitForm() {
      this.$hugrvalidate( [ 'pName', 'pKey', 'pDescription' ], this ).then( ( { success, errors } ) => {
        if( success ) {
          if( this.jiraSite != false && ( this.projectKey.length > 1 && this.projectKey.length <= 10 ) ) {
            this.exporting = true;
            this.$alerts.info( 'Initiated', `Request to export initiated. Please wait a moment.` );
            this.$apollo.mutate( {
            mutation: gql`
              mutation exportReportToJira($siteId: String!, $description: String!, $projectKey: String!, $projectName: String!, $reportIdentifier: String!) {
                result: exportReportToJira(siteId: $siteId, description: $description, projectKey: $projectKey, projectName: $projectName, reportIdentifier: $reportIdentifier)
              }
            `,
            variables: {
              siteId: this.jiraSite,
              description: this.projectDescription,
              projectKey: this.projectKey,
              projectName: this.projectName,
              reportIdentifier: this.identifier,
            },
          } ).then( res => {
            this.exporting = false;
            if( res.data.result.indexOf( "Failure" ) >= 0 ) {
              this.$alerts.error( 'Failure during export', res.data.result );
            } else if ( res.data.result.indexOf( "Success With Errors" ) >= 0 ) {
              this.$alerts.info( 'Errors during export', 'Some issues arised during exporting, project might exist but there might be errors with issues exported.' );
              this.reset();
            } else {
              this.$alerts.success( 'Success', 'Project should exist in Jira. Please check.' );
              this.reset();
            }
          } ).catch( err => {
            this.exporting = false;
            this.$alerts.error( `Ouch, you've found a bug.`, `We know this is an important feature and we need fix it pronto. ` );
          } );
          } else {
            this.$alerts.error( 'Please choose a jira site to export to or choose appropriate key length.' );
          }
        }
      } );
    },
    show() {
      this.fetchSites();
      this.identifier = this.$attrs.report.identifier;
      this.$refs.modal.show();
    },
    reset() {
      this.exporting = false;
      this.jirasites = [];
      this.jiraSite = false;
      this.projectDescription = "";
      this.projectName = "";
      this.projectKey = "";

      this.$refs.modal.reset();
    },
  },
  computed: {
    ...mapState( [ 'user' ] ),
    jiraSitesByName() {
      const ret = {};
      if ( this.jirasites ) {
        for( const site of this.jirasites ) {
          ret[site.name] = site.url;
        }
      }

      return ret;
    },
  },
  props: [ 'team' ],
  components: {
    FormInput,
    FormSelect,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

@import '@/assets/styles/variables/_colours.scss';

.PageCompTabList {
  padding-bottom: 10px !important;
}

.Components {
  & > * { border-bottom: 1px solid $hugr-colours-grey; }
  &_Tabs {
    &_Pane {
      background: $hugr-colours-grey;
      padding: 0px;
      background: lighten($hugr-colours-primary, 75%);
    }
  }
}
</style>
