<template>
  <Modal
    ref="modal"
    :title="$gettext(`Edit Accreditation Details`)"
    
    :showActions="true"

    :loading="submitting"
    loadingText="Updating..."

    :submitText="$gettext('Save')"
    @submit="updateDetails"
    
    @close="reset()"
    :ready="details">
    <Notice size="micro" type="alert" v-if="!isPublished">This report version is not published!</Notice>
    <FormSelect idRoot="accreditation_" :label="$gettext('Accreditation active?')" v-model="details.active" :options="{
      'true': $gettext('Yes'),
      'false': $gettext('No'),
    }"/>
    <FormInput ref="standard" idRoot="accreditation_" :label="$gettext('Standard to be shown')" v-model="details.shownStandard" :hintText="`e.g: WCAG 2.1 AA. This will use the report template if blank`" />
    <FormInput ref="date" idRoot="accreditation_" :label="$gettext('Date to be shown')" v-model="details.shownDate" :hintText="'e.g: March 2023. This will use the report publish date if blank'" />
    <FormSelect idRoot="accreditation_" :label="$gettext('Accreditation type')" v-model="details.pass" :options="{
      'true': $gettext('Pass'),
      'false': $gettext('Tested'),
    }"/>

    <Notice size="micro" type="alert" v-if="!isCompliant&&details.pass=='true'">Report is not compliant but is being published as pass</Notice>
    <Notice size="micro" type="alert" v-if="isCompliant&&details.pass=='false'">Report is compliant but is being published as in testing</Notice>

    <img v-if="details.logo && details.logo != ''" :src="details.logo" alt="Logo" />
    <FormImage ref="ssImage" idRoot="page_" :label="$gettext('Logo')" v-model="details.logo"/>
  </Modal>
</template>

<script>
import gql from 'graphql-tag';

import FormInput from '@/components/Form/Input';
import FormSelect from '@/components/Form/Select';
import FormImage from '@/components/Form/Image';

export default {
  name: 'EditAccreditationDetailsModal',
  data() {
    return {
      details: false,
      reportID: false,
      template: false,
      isCompliant: false,
      isPublished: false,
      submitting: false,
    };
  },
  methods: {
    updateDetails() {
      const validations = [ 'date', 'standard' ];

      this.$hugrvalidate( validations, this ).then( ( { success, errors } ) => {
        if( success ) {
          this.submitting = true;
          if ( this.details.active == 'true' ) {
            this.details.active = true;
          } else {
            this.details.active = false;
          }

          if ( this.details.pass == 'true' ) {
            this.details.pass = true;
          } else {
            this.details.pass = false;
          }
          this.$apollo.mutate( {
            mutation: gql`
              mutation updateReportAccreditationDetails($id: ObjectID!, $details: AccreditationDetailsInput!) {
                success: updateReportAccreditationDetails(id: $id, details: $details)
              }
            `,
            variables: {
              id: this.reportID,
              details: this.details,
            },
          } ).then( () => {
            this.submitting = false;
            this.reset();
            this.$alerts.success( 'Success', `Successfully changed the accreditation details for this report.` );
          } ).catch( () => {
            this.submitting = false;
            this.$alerts.coded( 'E054', 'F3101' ); //see notifications spreadsheet
          } );
        }
      } );
    },

    show( reportId ) {
      this.reportID = reportId;

      this.$apollo.query( {
        query: gql`
          query Report($reportId: ObjectID) {
            report: Report(id: $reportId) {
              accreditation {
                logo
                active
                pass
                shownDate
                shownStandard
              },
              template
              currentVersion {
                isCompliant
                published
              }
            }
          }
        `,
        variables: {
          reportId,
        },
        fetchPolicy: 'no-cache',
      } ).then( res => {
        this.details = { ...res.data.report.accreditation };

        if ( this.details.active ) {
          this.details.active = 'true';
        } else {
          this.details.active = 'false';
        }

        if ( this.details.pass ) {
          this.details.pass = 'true';
        } else {
          this.details.pass = 'false';
        }

        this.template = res.data.report.template;

        this.isCompliant = res.data.report.currentVersion.isCompliant;
        this.isPublished = res.data.report.currentVersion.published;

        delete this.details.__typename;
        this.$refs.modal.show();
      } ).catch( () => {
        this.$alerts.coded( 'E055', 'F3102' ); //see notifications spreadsheet
      } );
    },
    reset() {
      this.details = false;
      this.reportID = false;
      this.$refs.modal.reset();
    },
  },
  props: {

  },
  components: {
    FormInput,
    FormSelect,
    FormImage,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
