<template>
  <Modal
    ref="modal"
    :title="$gettext('New Portfolio')"

    :showActions="true"

    :loading="submitting"
    loadingText="Adding Portfolio..."

    :submitText="$gettext('Add portfolio')"
    @submit="submitPortfolio"

    @close="reset()" >
    <FormInput ref="identifier" idRoot="portfolio_" :label="$gettext('Identifier')" v-model="identifier" :validation="[{
      regex: /^[a-zA-Z0-9\-\_]*$/,
      message: $gettext('May only contain letters, numbers, underscore and hyphen characters')
    },
    'not-empty']"/>
    <FormInput ref="name" idRoot="portfolio_" :label="$gettext('Name')" v-model="name" :validation="['not-empty']"/>

    <AriaSearchSelect ref="customerselect" :label="$gettext('Customer')" :dropdown="true" :gqlOptions="['CUSTOMERS']" @selected="setCustomer" :validation="['not-empty']"/>

    <AriaSearchSelect ref="userselect" :label="$gettext('Principal')" :dropdown="true" :gqlOptions="['USERS']" @selected="setPrincipal" :validation="['not-empty']" hintText="This user will have access to all reports associated with this portfolio unless they are marked as sensitive."/>
  </Modal>
</template>

<script>
import gql from 'graphql-tag';

import FormInput from '@/components/Form/Input';

import AriaSearchSelect from '@/components/Aria/SearchSelect2';

export default {
  name: 'PortfolioModal',
  data() {
    return {
      identifier: '',
      name: '',
      customer: '',
      principal: '',
      submitting: false,
    };
  },
  methods: {
    submitPortfolio() {
      this.$hugrvalidate( [ 'identifier', 'name', 'customerselect', 'userselect' ], this ).then( ( { success, errors } ) => {
        if( success ) {
          this.submitting = true;
          this.$apollo.mutate( {
            mutation: gql`
              mutation addPortfolio($portfolio: PortfolioInput!) {
                portfolio: addPortfolio(portfolio: $portfolio) {
                  _id
                }
              }
            `,
            variables: {
              portfolio: {
                identifier: this.identifier,
                name: this.name,
                customer: this.customer,
                principal: this.principal,
              },
            },
          } ).then( res => {
            this.submitting = false;
            this.reset();
            this.$alerts.success( 'Porfolio added!', `Portfolio ${this.name} has been added successfully` );
            this.$emit( 'success', res.data.portfolio._id );
            this.$refs.modal.close();
          } ).catch( () => {
            this.submitting = false;
            this.$alerts.coded( 'E077', 'F4601' ); //see notifications spreadsheet
          } );
        }
      } );
    },

    setCustomer( customer, display ) {
      this.$refs.customerselect.select( customer, display );
      this.customer = customer;
    },

    setPrincipal( principal, display ) {
      this.$refs.userselect.select( principal, display );
      this.principal = principal;
    },

    show() {
      this.$refs.modal.show();
    },
    reset() {
      this.identifier = '';
      this.name = '';
      this.customer = '';
      this.$refs.modal.close();
    },
  },
  props: {
  },
  components: {
    FormInput,
    AriaSearchSelect,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
