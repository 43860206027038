<template>
  <Body>
    <div class="first-use" v-if="!dismissDashboardVideo||dismissDashboardVideo!='true'">
      <Notice class="Dashboard_Welcome" size="small" @dismissed="doDismiss">
        <div class="Dashboard_Welcome_Content" v-if="$hugrConfig.appId.indexOf('ho') == 0">
          <p>Here you can log, discuss, resolve and report on accessibility issues with services that are provided or used by Home Office and its staff.</p>
          <p>This will help to give people with access needs equality of experience and opportunity. It will also aid our levels of quality, satisfaction, effectiveness, efficiency, reputation and compliance.</p>
          <p>If you have any questions, ideas or feedback contact the accessibility team.</p>
        </div>
        <div class="Dashboard_Welcome_Content" v-else>
          <h3 v-translate>Let's make accessibility accessible!</h3>
          <p>{{$gettext(`To get started we've some instructions to guide you through creating your first report.`)}} <a href="https://docs.hugr.app/#/intro">{{$gettext('HUGR instructions')}}</a></p>
          <p>{{$gettext(`Any questions please do get in touch, we're here to help`)}} <a href="mailto:hello@diginclusion.com">hello@diginclusion.com</a>.</p>
        </div>
      </Notice>
    </div>
    <div class="Dashboard">
      <div class="Dashboard_Header">
        <div>
          <h2>
          {{$gettext('Welcome to Hugr,') + ' '}}<span class="Dashboard_Header_Bold">{{ (user.displayname.split(' ')[0] || user.name.split(' ')[0]) }}</span>
          </h2>
        </div>
        <div>
          <template v-if="teamLoaded">
            <div class="Report_Team" v-if="people.length>1" @mouseenter="hoverPeople()" @mouseleave="blurPeople()">
              <h3 class="Report_Team_Title">Team</h3>
              <Avatar v-for="(person, index) in people" :class="['Report_Team_Avatar', `_person-${index}`, index<4&&!showAllPeople?`_startCollapsed`:'', index>4&&!showAllPeople?`_startHidden`:'']" :key="person[1]" :uid="person[1]" :details="true" size="small" type="round" @focus="hoverPeople()" @blur="blurPeople(e)"/>
              <Pill :class="['Report_Team_Pill', showAllPeople?`_hide`:``]" v-if="people.length>5" :text="`${people.length-5} more`"/>
            </div>
          </template>
        </div>
      </div>
      <div class="Dashboard_Columns">
      <div class="Dashboard_ColumnA">
        <div v-if="announcements?.length > 0" class="Dashboard_ColumnA_Header">
          <div class="Dashboard_ColumnA_Header_Announcements">
            <div class="Dashboard_ColumnA_Header_Announcements_Head">
              <img :src="require('@/assets/images/orange-rocket.svg')" alt="">
              <h3 class="Dashboard_ColumnA_Header_Title" v-translate>Announcements</h3>
            </div>
            <div class="Dashboard_ColumnA_Header_Announcements_Head">
              <span v-show="!announcementsExpanded" class="PillLocal">{{ announcements?.length }}</span>
              <button v-if="announcements?.length > 0" class="Dashboard_ColumnA_Header_Announcements_Button" type="button" aria-controls="id_announcements" :aria-expanded="announcementsExpanded" @click.prevent="announcementsExpanded = !announcementsExpanded">
                <img v-if="announcementsExpanded" :src="require('@/assets/images/cross.svg')" alt=""/>
                <img v-else :src="require('@/assets/images/open-chevron.svg')" alt=""/>
                <span v-if="announcementsExpanded" class="a11yhide">
                  Hide announcements
                </span>
                <span class="a11yhide" v-else>
                  Show announcements
                </span>
              </button>
            </div>
          </div>
          <ul id="id_announcements" v-show="announcementsExpanded">
            <li v-for="announcement in announcements" :key="announcement.announcement">{{ announcement.announcement }}</li>
          </ul>
        </div>
        <div v-if="this.hasPermission( 'Admin', 'OverviewDashboard' )" class="Dashboard_ColumnA_Overview">
          <div class="Dashboard_ColumnA_Overview_Head">
            <div class="Dashboard_ColumnA_Overview_Head_Section">
              <Icon type="solid" icon="chart-pie" />
              <h3>{{ $gettext( 'Managerial overview' ) }}</h3>
              <div class="Dashboard_ColumnA_Overview_Head_Section _rightHigher">
                <div v-if="teamsLoaded && Object.keys(teams).length > 4" class="_teamsListbox">
                  <AriaListBox idRoot="teamchoice_" :inline="true" :label="$gettext('Team:')" v-model="currentTeam"
                    :options="{
                      ...teams
                    }"
                  />
                </div>
                <div v-else-if="teamsLoaded && Object.keys(teams).length > 0" class="_teamsTabs">
                  <TabList
                    v-if="tabs.length"
                    :tabs="tabs"
                    :lastIndex="lastKnownTab" @tabChanged="lastKnownTab = $event;"  />
                </div>
                <Empty v-else-if="teamsLoaded " text="No teams"/>
              </div>
            </div>
          </div>
          <div v-if="!loadingIssues && Object.keys(issues).length > 0" class="Dashboard_ColumnA_Overview_Body">
            <WidgetFails :issues="issues" :reports="reports" />
            <WidgetCommonIssues :issues="issues" :reports="reports" />
            <WidgetMostIssuesReports :issues="issues" :reports="reports" />
          </div>
          <Empty v-else-if="!loadingIssues" text="No data to show from this team"/>
          <Loader v-else />
        </div>
        <div class="Dashboard_ColumnA_Assessments">
          <div class="Dashboard_ColumnA_Assessments_Head">
            <div class="Dashboard_ColumnA_Assessments_Head_Section">
              <Icon type="solid" icon="chart-pie" />
              <h3>{{ $gettext( 'Recent assessments' ) }}</h3>
            </div>
            <div class="Dashboard_ColumnA_Assessments_Head_Section _right">
              <router-link :to="`/${$hugrConfig.reportRouterReplacement}s/find/all/alphabetical`" v-translate>View all</router-link>
            </div>
          </div>
          <OnDeck class="Dashboard_ColumnA_Assessments_Deck" />
        </div>
      </div>
      <div class="Dashboard_ColumnB">
        <div class="Dashboard_ColumnB_Activity">
          <h3 class="Dashboard_ColumnB_Title" v-translate>Recent Activity</h3>
          <Stream />
        </div>
      </div>
    </div>
    </div>
  </Body>
</template>

<script>
import gql from 'graphql-tag';

import { mapGetters, mapState } from 'vuex';

import UIBody from '@/components/UI/Body';
import Avatar from '@/components/Helpers/Avatar';
import Pill from '../components/UI/Pill.vue';
import AriaListBox from '@/components/Aria/ListBox';

import WidgetFails from '../components/Widgets/WidgetFails.vue';
import WidgetCommonIssues from '../components/Widgets/WidgetCommonIssues.vue';
import WidgetMostIssuesReports from '../components/Widgets/WidgetMostIssuesReports.vue';

import Stream from '@/components/Stream';
import OnDeck from '@/components/OnDeck';

export default {
  name: 'DashboardView',
  data() {
    return {
      teamLoaded: false,
      team: false,

      teamsLoaded: false,
      teams: false,
      currentTeam: false,
      reports: false,
      loadingReports: false,
      issues: false,
      loadingIssues: false,
      issueFetchCounter: 0,

      tabs: [],
      lastKnownTab: 0,

      peopleTimeout: false,
      showAllPeople: false,
      announcementsExpanded: false,
      dismissDashboardVideo: 'true',
    };
  },
  mounted() {
    if ( this.user.primaryTeam?._id ) {
      this.currentTeam = this.user.primaryTeam?._id;
      this.fetchTeam();
    }

    if ( this.user.teams.length > 0 ) {
      this.$apollo.query( {
        query: gql`
          query Teams {
            teams: Teams {
              identifier,
              name,
              _id,
              customer {
                _id
                name
              }
            }
          }`,
      } ).then( ( { data: { teams } } ) => {
        this.teamsLoaded = true;

        const opts = {};

        this.tabs = [];

        let primaryTeam = {};
        const otherTeams = [];

        for( const team of teams ) {
          if( team.customer ) opts[team._id] = { label: team.name, group: team.customer.name };
          else opts[team._id] = team.name;

          const teamTabObj = {
            text: team.name,
            controls: team._id,
            action: () => {
              this.currentTeam = team._id;
            },
          };

          if( otherTeams.map( t => t.text ).indexOf( team.name ) >= 0 || primaryTeam.text == team.name ) teamTabObj.text += ` (1)`;

          if ( team._id == this.user.primaryTeam?._id ) primaryTeam = teamTabObj;
          else otherTeams.push( teamTabObj );
        }

        this.tabs = [ primaryTeam, ...otherTeams.filter( t => t.controls != primaryTeam.controls ) ];

        this.teams = opts;
      } ).catch( error => {
        this.$alerts.generic( error );
      } );
    }
    this.$apollo.mutate( {
        mutation: gql`
          mutation ($page: String) {
            sendNavigation(page: $page)
          }
        `,
        variables: {
          page: 'Dashboard',
        },
      } );
  },
  watch: {
    currentTeam() {
      if ( this.currentTeam == false || this.currentTeam == '' ) return;

      this.fetchTeam();
      this.fetchWidgetAssessments();
    },

    reports() {
      this.issues = {};
      if ( this.reports == false || this.reports.length == 0 ) return;
      this.fetchAssessmentIssues();
    },

    issueFetchCounter() {
      if ( this.issueFetchCounter == this.reports.length ) {
        this.loadingIssues = false;
      }
    },
  },
  apollo: {
    dismissDashboardVideo: {
      query: gql`
        query GetUserSetting($id: ObjectID!, $key: String!) {
          dismissDashboardVideo: GetUserSetting(id: $id, key: $key)
        }
      `,
      variables() {
        return {
          id: this.user.id,
          key: 'dismissDashboardVideo',
        };
      },
    },
    announcements: {
      query: gql`
        query {
          announcements: ActiveAnnouncements {
            announcement,
          }
        }
      `,
    },
  },
  methods: {
    hoverPeople() {
      clearTimeout( this.peopleTimeout );
      this.showAllPeople = true;
    },

    blurPeople() {
      if( document.activeElement.classList.contains( 'Avatar_Actual' ) ) return;

      this.peopleTimeout = setTimeout( () => {
      this.showAllPeople = false;
      }, 500 );
    },

    fetchTeam() {
      this.teamLoaded = false;

      this.$apollo.query( {
        query: gql`
          query Team($id: ObjectID) {
            team: Team(id: $id) {
              users {
                name,
                permissionGroup {
                  name
                },
                _id
              },
              managers {
                name,
                permissionGroup {
                  name
                },
                _id
              },
              contacts {
                name,
                permissionGroup {
                  name
                },
                _id
              }
            }
          }`,
        variables: {
          id: this.currentTeam,
        },
      } ).then( ( { data: { team } } ) => {
        this.teamLoaded = true;
        this.team = team;
      } ).catch( () => {
        //this.$alerts.coded( '', '' );
      } );
    },

    fetchWidgetAssessments() {
      this.loadingReports = true;

      this.$apollo.query( {
        query: gql`
          query ReportsFromTeam($team: ObjectID) {
            reports: ReportsFromTeam(team: $team) {
              _id,
              identifier,
              title,
              currentVersion {
                _id,
                version,
                isCompliant,
                progress,
              }
            }
          }`,
        variables: {
          team: this.currentTeam,
        },
      } ).then( ( { data: { reports } } ) => {
        this.reports = reports;
        this.loadingReports = false;
      } ).catch( error => {
        this.$alerts.generic( error );
      } );
    },

    fetchAssessmentIssues() {
      this.loadingIssues = true;
      this.issues = {};
      this.issueFetchCounter = 0;
      this.reports.forEach( report => {
        this.$apollo.query( {
        query: gql`
          query IssuesFromReport($id: ObjectID) {
            issues: IssuesFromReport(id: $id) {
              _id,
              identifier,
              status,
              flag,
              template {
                identifier,
                severity,
                title,
                criteria {
                  criterion,
                  level
                }
              }
              }
            }`,
          variables: {
            id: report._id,
          },
        } ).then( ( { data: { issues } } ) => {
          this.issues[report._id] = issues;
          this.issueFetchCounter++;
        } ).catch( error => {
          this.$alerts.generic( error );
        } );
      } );
    },

    doDismiss() {
      this.$apollo.mutate( {
        mutation: gql`
          mutation setUserSetting($id: ObjectID!, $key: String!, $value: String!) {
            res: setUserSetting(id: $id, key: $key, value: $value)
          }
        `,
        variables: {
          id: this.user.id,
          key: 'dismissDashboardVideo',
          value: 'true',
        },
      } ).then( () => {
        this.$apollo.queries.dismissDashboardVideo.refetch();
      } );
    },
  },
  computed: {
    ...mapState( [ 'user' ] ),
    ...mapGetters( [ 'hasPermission' ] ),
    people() {
      const ret = [];

      if( !this.teamLoaded ) return ret;

      // ret.push( [ this.user.name, this.user._id , this.user.permissionGroup?.name ] );

      if( this.team ) {
        for( const person of this.team.managers ) {
          if( ret.map( i => i[1] ).indexOf( person._id ) == -1 ) {
            ret.push( [ person.name, person._id, person.permissionGroup?.name ] );
          }
        }

        for( const person of this.team.users ) {
          if( ret.map( i => i[1] ).indexOf( person._id ) == -1 ) {
            ret.push( [ person.name, person._id, person.permissionGroup?.name ] );
          }
        }

        for( const person of this.team.contacts ) {
          if( ret.map( i => i[1] ).indexOf( person._id ) == -1 ) {
            ret.push( [ `${person.name} (Contact)`, person._id, person.permissionGroup?.name ] );
          }
        }

      }

      return ret;
    },
    // ...mapState(['firstUse'])
  },
  components: {
    Body: UIBody,
    OnDeck,
    Stream,
    Avatar,
    AriaListBox,
    Pill,
    WidgetFails,
    WidgetCommonIssues,
    WidgetMostIssuesReports,
  },
};
</script>

<style lang="scss" scoped>

@import '@/assets/styles/variables/_colours.scss';

.a11yhide {
  position: absolute !important;
  height: 1px; width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
}

.PillLocal {
    background-color: $hugr-colours-secondary;
    border-radius: 16px;
    padding-right: 6px;
    padding-left: 6px;
    padding-top: 2px;
    padding-bottom: 2px;
    font-weight: bold;
    font-size: 16px;
    font-weight: 600;
    margin-right: 5px;
    color:black;
  }

  .Dashboard {
    &_Welcome {
      &_Video {
        display: inline-block;
        vertical-align: top;
        // width: 50%;
      }
      &_Content {
        display: inline-block;
        vertical-align: top;
        width: 50%;
        padding: 0 20px;
        padding-left: 50px;
        box-sizing: border-box;
        h3 {
          margin-top: 0;
        }
        a {
          color: #FFF;
        }
      }
    }

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    column-gap: 45px;

    &_Header {
      width: 100%;

      h2 {
        margin-top: 40px;
        margin-bottom: 40px;
        font-size: 32px;
        line-height: 40px;
      }

      &_Bold {
        font-weight: 700;
        font-size: 32px;
        line-height: 40px;
      }
    }

    &_Columns {
      display: flex;
      flex-direction: row;
      justify-content: center;
      column-gap: 45px;
      width: 100%;
    }

    &_ColumnA {
      flex: 70%;
      width: 100%;

      &_Overview {
        width: 100%;

        &_Body {
          width: 100%;
          min-height: 200px;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          gap: 16px;
          margin: 16px 0;
        }

        &_Head {
          border-bottom: 1px solid #CCD2D9;
          padding: 16px 0;
          position: relative;

          &_Section {
            display: flex;
            vertical-align: middle;
            align-items: center;

            &._pureRight {
              position: absolute;
              right: 0;
            }

            &._right {
              position: absolute;
              right: 0;
              top: 19px;
            }

            &._rightHigher {
              position: absolute;
              right: 0;
              top: 18px;
            }

            ._teamsListbox {
              min-width: 250px;
            }

            ._teamsTabs {
              position: relative;
              top: -9px;
            }

            svg {
              display: inline-block;
              vertical-align: middle;
              margin-right: 8px;
            }
            h3 {
              font-size: 1.4em;
              font-weight: 600;
              margin: 0px;
              display: inline-block;
              vertical-align: middle;
            }
            a {
              // color: $hugr-colours-new-link;
              display: inline-block;
              vertical-align: middle;
            }
          }
        }
      }

      &_Assessments {
        width: 100%;

        &_Deck {
          margin-top: 24px;
        }

        &_Head {
          // display: flex;
          // flex-direction: row;
          // justify-content: space-between;
          // align-items: center;
          border-bottom: 1px solid #CCD2D9;
          padding: 16px 0;
          position: relative;

          &_Section {
            // display: flex;
            // flex-direction: row;
            // align-items: center;
            // column-gap: 4px;
            // min-height: 60px;
            display: inline-block;
            vertical-align: middle;

            &._pureRight {
              position: absolute;
              right: 0;
            }

            &._right {
              position: absolute;
              right: 0;
              top: 19px;
            }

            &._rightHigher {
              position: absolute;
              right: 0;
              top: 10px;
            }

            svg {
              display: inline-block;
              vertical-align: middle;
              margin-right: 8px;
            }
            h3 {
              font-size: 1.4em;
              font-weight: 600;
              margin: 0px;
              display: inline-block;
              vertical-align: middle;
            }
            a {
              // color: $hugr-colours-new-link;
              display: inline-block;
              vertical-align: middle;
            }
          }
        }
      }

      &_Header {
        max-width: 100%;
        padding: 16px;
        background-color: rgb(50,90,127);
        color: white;

        &_Announcements {
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          &_Button {
            cursor: pointer;
            border: none;
            background: transparent;
          }

          &_Head {
            display: flex;
            flex-direction: row;
            align-content: center;
            column-gap: 4px;

            h3 {
              margin-bottom: 0px;
            }
          }
        }

        h3 {
          margin-top: 0px;
        }

        &_Title {
          font-size: 18px;
          font-weight: 600;
        }

        ul {
          li {
            font-size: 22px;
            line-height: 26px;
          }
        }
      }
    }
    &_ColumnB {
      flex: 30%;
      max-width: 500px;
      width: 100%;

      &_Teams {
        display: flex;
        flex-direction: column;
        border-left: 1px solid #CCD2D9;
        padding-left: 24px;
        margin-bottom: 40px;

        h3 {
          font-weight: 600;
          font-size: 18px;
          margin-top: 0px;
          margin-bottom: 24px;
        }

        &_Team {
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;
          column-gap: 12px;
          &_Icon {
            flex: 10%;
          }
          &_Name {
            flex: 50%;
            font-weight: 600;
            text-wrap: nowrap;
          }
          &_Members {
            flex: 20%;
            display: flex;
            justify-content: right;
            text-wrap: nowrap;
          }
          &_Actions {
            display: flex;
            justify-content: center;
          }
        }
      }

      &_Activity {
        padding-left: 24px;
        border-left: 1px solid #CCD2D9;

        h3 {
          font-weight: 600;
          font-size: 18px;
          margin-bottom: 24px;
          margin-top: 0px;
        }

        &_Title {
          margin-bottom: 24px;
        }
      }
    }
  }

  ._darkMode .Dashboard {

  }

  @media screen and (max-width: 800px) {
    .Dashboard {
      &_Welcome {
        &_Video {

        }
        &_Content {
          width: 100%;
          padding: 0 5px;
        }
      }
      &_Item {
        display: block;
        width: 100%;
      }
    }
  }
</style>
