<template>
  <div class="UserProfile">
    <!-- <Button size="small" type="secondary" @click="$router.push(`/team/${instance.team.identifier}`)">Back to team's page</Button>
    <Button size="small" type="secondary" @click="$refs.ticketlistmodal.show()">Show Team Tickets</Button> -->
    <Body v-if="instance" :title="`User profile`" :aside="true" class="UserProfile_Body">
      <div class="UserProfile_Summary_Name">
        <Avatar :uid="instance._id" />
        <div v-if="!instance.displayName" class="UserProfile_Summary_Name_Text">{{instance.name}} ({{instance.email}})</div>
        <div v-else class="UserProfile_Summary_Name_Text">{{instance.displayName}} ({{instance.email}})</div>
      </div>

      <TabList class="TabList" :tabs="tabs" :key="`tablist`"/>

      <div v-if="chosenTab == 'User Details'">
      <div class="UserProfile_Summary">
        <div class="edit-head">
          <h3 v-translate>User Summary</h3>
        </div>
        <div v-if="isActiveMember && !instance.disabled" :key="'summary-1-' + instance.disabled" v-translate>
          This user is currently an active HUGR user and has signed up successfully.
        </div>
        <div v-if="isActiveMember && instance.disabled" :key="'summary-2-' + instance.disabled" v-translate>
          This user is currently a HUGR user and has signed up successfully, but has been disabled.
        </div>
        <div v-if="!isActiveMember" :key="'summary-3-' + instance.disabled" v-translate>
          This user is currently not a HUGR user that has signed up, but is referenced somewhere.
        </div>
        <div v-if="!isActiveMember && instance.contactOnly" :key="'summary-4-' + instance.disabled" v-translate>
          This user is a contact.
        </div>
        <div v-if="!isActiveMember && instance.staged && !instance.contactOnly" :key="'summary-5-' + instance.disabled" v-translate>
          This user has been invited to HUGR but has not signed up yet.
        </div>
      </div>

      <div class="UserProfile_Summary">
        <div class="edit-head">
          <h3 v-translate>User's Public Notes</h3>
        </div>
        <div v-if="instance.notes && instance.notes.length != 0" :key="'notes-1-' + instance.notes">
          {{instance.notes}}
        </div>
        <div v-else :key="'notes-2-' + instance.notes" v-translate>
          This user does not have any notes about them.
        </div>
      </div>

      <div v-if="(hasPermission('Users', 'ResetPassword') || user.aclevel == 0) || ( (user.id!=instance._id) && ( ( !instance.disabled && (hasPermission('Users','Delete') || user.aclevel == 0 ) ) ||  instance.disabled && (hasPermission('Users','Update') || user.aclevel == 0) ) ) || ( hasPermission('Users','Update') || user.aclevel == 0 )" class="UserProfile_Actions">
        <div class="edit-head">
          <h3 v-translate>User Actions</h3>
        </div>
        <Button class="UserProfile_Actions_Button" v-if="hasPermission('Users', 'ResetPassword') || hasPermission('Users', 'Delete') || user.aclevel == 0" type="serious" size="large" @click="resetPassword(instance._id)">{{$gettext('Reset password')}}</Button>
        <Button class="UserProfile_Actions_Button" v-if="( hasPermission('Users', 'ResetMFA') || hasPermission('Users', 'Delete') || user.aclevel == 0 ) && instance.mfaEnabled" type="serious" size="large" @click="resetMFA(instance._id)">{{ $gettext('Reset 2FA') }}</Button>
        <template v-if="user.id!=instance._id">
          <Button class="UserProfile_Actions_Button" v-if="!instance.disabled && (hasPermission('Users','Delete') || user.aclevel == 0)" type="serious" size="large" @click="disableUser(instance._id)">{{$gettext('Disable user')}}</Button>
          <Button class="UserProfile_Actions_Button" v-if="instance.disabled && (hasPermission('Users','Update') || user.aclevel == 0)" type="secondary" size="large" @click="enableUser(instance._id)">{{$gettext('Enable user')}}</Button>
        </template>
        <Button class="UserProfile_Actions_Button" v-if="hasPermission('Users','Update') || user.aclevel == 0" size="large" @click="$refs.editusermodal.show(instance._id)">{{$gettext('Edit user')}}</Button>
        <LinkButton v-if="user.aclevel == 0" :to="`/${$hugrConfig.reportRouterReplacement}s/find/adminall/alphabetical/owner/${instance._id}`">View all assessments</LinkButton>
      </div>

      <div class="edit-head">
        <h3 v-translate>Comments</h3>
      </div>
      <Comments v-if="instance" v-bind:reference="instance._id"/>
      </div>

      <div v-if="chosenTab == 'Associated Teams'">
        <template v-if="instance.teams.length > 0">
          <DataTable  class="Teams_Teams_Table"
                      :headers="[
              $gettext('Identifier'),
              $gettext('Name'),
            ]">
            <tr v-for="team in instance.teams" v-bind:key="'team-' + team.identifier">
              <td>{{ team.identifier }}</td>
              <td><router-link :to="`/team/${team.identifier}`">{{ team.name }}</router-link></td>
            </tr>
          </DataTable>
        </template>

        <span v-else v-translate>There are currently no associated teams with this account.</span>
      </div>

      <div v-if="chosenTab == 'Associated Portfolios'">
        <template v-if="instance.portfolios.length > 0">
          <DataTable  class="Teams_Teams_Table"
                      :headers="[
              $gettext('Identifier'),
              $gettext('Name'),
            ]">
            <tr v-for="portfolio in instance.portfolios" v-bind:key="'portfolio-' + portfolio.identifier">
              <td>{{ portfolio.identifier }}</td>
              <td><router-link :to="`/portfolio/${portfolio.identifier}`">{{ portfolio.name }}</router-link></td>
            </tr>
          </DataTable>
        </template>

        <span v-else v-translate>There are currently no associated teams with this account.</span>
      </div>

      <Aside>
        <dl>
          <div>
            <dt v-translate>Status</dt>
            <dd>
              <span v-if="!instance.disabled" :key="'disabled-' + instance.disabled" v-translate>Active</span>
              <span v-else v-translate>Disabled</span>
            </dd>
          </div>

          <div>
            <dt v-translate>Phone number</dt>
            <dd>
              <span v-if="instance.phoneNumber && instance.phoneNumber != ''" :key="'phone-' + instance.phoneNumber">{{ instance.phoneNumber }}</span>
              <span v-else v-translate>No phone number available.</span>
            </dd>
          </div>
          <div>
            <dt v-translate>MFA Enabled?</dt>
            <dd>
              <span v-if="instance.mfaEnabled" v-translate>Yes</span>
              <span v-else v-translate>No</span>
            </dd>
          </div>
          <div v-if="instance.displayName && instance.displayName != instance.name">
            <dt v-translate>Original name</dt>
            <dd>
              <span >{{ instance.name }}</span>
            </dd>
          </div>
          <div>
            <dt v-translate>Primary Team</dt>
            <dd>
              <span v-if="instance.primaryTeam"><router-link :to="`/team/${instance.primaryTeam.identifier}`">{{ instance.primaryTeam.name }}</router-link></span>
              <span v-else v-translate>No primary team.</span>
            </dd>
          </div>
          <div>
            <dt v-translate>Permission Group</dt>
            <dd>
              <span v-if="instance.permissionGroup">{{ instance.permissionGroup.name }}</span>
              <span v-else v-translate>Not assigned to any permission groups.</span>
            </dd>
          </div>
          <div>
            <dt v-translate>Legacy ACL</dt>
            <dd>
              <span v-if="instance.aclevel >= 0 && instance.aclevel <= 3">{{ instance.aclevel }}</span>
              <span v-else v-translate>No legacy ACL</span>
            </dd>
          </div>
        </dl>
      </Aside>
    </Body>
    <EditUserModal ref="editusermodal" @success="$apollo.queries.instance.refetch()" />
    <!-- <TicketListModal ref="ticketlistmodal" v-if="instance.team" :team="instance.team._id"/> -->
  </div>
</template>

<script>
import gql from 'graphql-tag';
import { mapState, mapGetters } from 'vuex';
import UIBody from '@/components/UI/Body';
import UIAside from '@/components/UI/Aside';
// import TicketListModal from '@/modals/TicketList';

import Avatar from '@/components/Helpers/Avatar';

import Comments from '@/components/Comments/Index';

import EditUserModal from '@/modals/User/Edit';

export default {
  name: 'UserProfileView',
  created() {
    this.$apollo.query( {
      query: gql`
        query Settings {
          settings: Settings {
            id
            portfolios
          }
        }
      `,
    } ).then( res => {
      this.hasPortfolios = res.data.settings.portfolios;
      if( this.hasPortfolios ) {
        this.tabs.push( {
          text: 'Associated Portfolios',
          action: () => {
            this.chosenTab = 'Associated Portfolios';
          },
        } );
      }
    } );
  },
  mounted() {
    this.$apollo.mutate( {
      mutation: gql`
        mutation ($page: String) {
          sendNavigation(page: $page)
        }
      `,
      variables: {
        page: 'User Profile',
      },
    } );
  },
  data() {
    return {
      identifier: this.$route.params.identifier,
      chosenTab: 'User Details',

      tabs: [
        {
          text: 'User Details',
          action: () => {
            this.chosenTab = 'User Details';
          },
        },
        {
          text: 'Associated Teams',
          action: () => {
            this.chosenTab = 'Associated Teams';
          },
        },
      ],

      report: false,
      instance: false,

      group: false,
      groupInstances: false,

      solutionsChanged: false,

      hasPortfolios: false,
    };
  },
  watch: {
    $route() {
      if( this.$route.name == 'UserProfile' ) {
        this.identifier = this.$route.params.identifier;
        this.$apollo.queries.instance.refetch();
      }
    },
  },
  apollo: {
    instance() {
      return {
      query() {
 return gql`
        query User($ident: ObjectID!) {
          instance: User(id: $ident) {
            _id,
            name,
            email,
            phoneNumber,
            displayname,
            aclevel,
            teams {
              _id,
              identifier,
              name,
            },
            primaryTeam {
              _id,
              identifier,
              name
            }
            portfolios {
              _id
              identifier
              name
            }
            staged,
            contactOnly,
            mfaEnabled,
            disabled,
            notes,
            permissionGroup {
              name
            },
            archived
          }
        }
      `;
},
      variables() {
        return {
          ident: this.identifier,
        };
      },
      fetchPolicy: 'no-cache',
    };
    },
  },
  methods: {
    resetMFA( id ) {
      this.$confirm.simple( "Ensure you confirm the identity of the user before disabling 2FA methods" ).then( result => {
        if( result ) {
          this.$apollo.mutate( {
            mutation: gql`
              mutation resetUserMFA($id: ObjectID!) {
                resetUserMFA(id: $id) {
                  success,
                  extraInfo
                }
              }
            `,
            variables: {
              id,
            },
          } ).then( res => {
            if( res.data.resetUserMFA.success ) {
              this.$apollo.queries.instance.refetch();
              this.$alerts.success( '2FA reset', `The users 2FA methods have been disabled` );
            } else {
              this.$alerts.error( '2FA not reset', `Something went wrong, please try again later. If the problem persists, contact us.` );
            }
          } ).catch( () => {
            this.$alerts.error( '2FA not reset', `Something went wrong, please try again later. If the problem persists, contact us.` );
          } );
        }
      } );
    },
    resetPassword( id ) {
      this.$confirm.simple().then( result => {
        if( result ) {
          this.$apollo.mutate( {
            mutation: gql`
              mutation resetUserPassword($id: ObjectID!) {
                resetUserPassword(id: $id) {
                  success,
                  extraInfo
                }
              }
            `,
            variables: {
              id,
            },
          } ).then( res => {
            if( res.data.resetUserPassword.success ) {
              this.$apollo.queries.instance.refetch();
              this.$alerts.success( 'Password reset', `The user has been sent a reset email, the code is: ${res.data.resetUserPassword.extraInfo}` );
            } else {
              this.$alerts.error( 'Password not reset', `Something went wrong, please try again later. If the problem persists, contact us.` );
            }
          } ).catch( () => {
            this.$alerts.error( 'Password not reset', `Something went wrong, please try again later. If the problem persists, contact us.` );
          } );
        }
      } );
    },
    disableUser( id ) {
      this.$confirm.simple().then( result => {
        if( result ) {
          this.$apollo.mutate( {
            mutation: gql`
              mutation disableUser($id: ObjectID!) {
                disableUser(id: $id) {
                  _id
                }
              }
            `,
            variables: {
              id,
            },
          } ).then( () => {
            this.$apollo.queries.instance.refetch();
          } );
        }
      } );
    },
    enableUser( id ) {
      this.$confirm.simple().then( result => {
        if( result ) {
          this.$apollo.mutate( {
            mutation: gql`
              mutation enableUser($id: ObjectID!) {
                enableUser(id: $id) {
                  _id
                }
              }
            `,
            variables: {
              id,
            },
          } ).then( () => {
            this.$apollo.queries.instance.refetch();
          } );
        }
      } );
    },
  },
  computed: {
    isActiveMember() {
      if ( this.instance.staged || this.instance.contactOnly ) {
        return false;
      }

      return true;
    },
    ...mapGetters( [ 'hasPermission' ] ),
    ...mapState( [ 'user' ] ),
  },
  components: {
    Body: UIBody,
    Aside: UIAside,
    Comments,
    Avatar,
    EditUserModal,
  },
};
</script>

<style lang="scss" scoped>

@import '@/assets/styles/variables/_colours.scss';

.TabList {
  margin-top: 5px;
  padding-bottom: 10px !important;
}

.UserProfile {
  &_Actions {
    &_Button {
      margin-right: 10px;
    }
  }
  &_Summary {
    &_Name {
      margin: auto;
      padding: 10px;
      &_Text {
        display: inline-block;
        font-weight: bold;
        margin-left: 10px;
        margin-top: 8px;
      }
    }
  }
  &_Body {
    position: relative;

    &_Screenshot {
      height: 240px;
      position: absolute;
      right: 50px;
      top: -90px;

      border: 2px solid $hugr-colours-grey;
      &_Img {
        width: 100%;
        height: auto;
      }
    }
  }
}
  div.edit-head {
    border-bottom: 1px solid #dce4ee;
    margin-bottom: 15px;
    margin-top: 30px;
    padding-bottom: 1px;
    h3 {
      margin: 0;
    }
    button {
      float: right;
      position: relative;
      top: -26px;
    }
  }

  figure {
    border: 1px solid #b2b3b2;
    background: #f6f8fa;
    padding: 10px;
    margin: 0;
    img {
      max-width: 100%;
    }
  }
</style>
