<template>
  <div :class="['Widget', size == 'long' ? '_long':'']">
    <div class="Widget_Container">
      <h4>{{ title }}</h4>
      <div class="Widget_Container_Inner">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'UIWidgetBase',
  props: {
    title: {
      type: String,
      default: "Untitled widget",
    },
    size: {
      type: String,
      default: "normal",
    },
  },
  components: {

  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  @import '@/assets/styles/variables/_colours.scss';

  .Widget {
    min-height: 50px;
    min-width: 400px;
    width: 30%;
    background: $hugr-colours-card-background;
    flex: 1 1 auto;

    &._long {
      width: 100%;
    }
    &_Container {
      padding: 8px;
      h4 {
        margin: 8px 0;
      }
      &_Inner {
        height: 100%;
        position: relative;
      }
    }
  }

  ._darkMode .Widget{
    background: lighten( $hugr-colours-primary, 10% );
    color: #FFF;
  }
</style>
