<template>
  <span :class="['ListBox', inline?'_inline':'']" v-click-away="close">
    <span :class="['ListBox_Label', `_${labelStyle}`, isVisible, inline?'_inline':'', bold?'_bold':'']" :id="`${id}_label`">{{ label }}</span>

    <span :class="['ListBox_Box',  inline?'_inline':'']">
      <button class="ListBox_Box_Button"
              ref="button"
              type="button"
              :id="`${id}_button`"
              @click="toggle"
              :aria-haspopup="`${id}_listbox`"
              :aria-labelledby="`${id}_label ${id}_button`"
              :aria-expanded="isOpen?'true':'false'"
              @keydown.up.prevent="goUpFromBox" @keydown.down.prevent="goDownFromBox">

        <span class="ListBox_Box_Button_InnerText" :title="options[cValue]?.label?options[cValue].label:options[cValue]" >{{ options[cValue]?.label?options[cValue].label:options[cValue] }}</span>

      </button>
      <Icon class="ListBox_Box_DropArrow" type="solid" icon="chevron-down" @click="toggle"/>

      <div class="ListBox_Box_OptionsContainer" v-if="isOpen">
        <ul class="ListBox_Box_OptionsContainer_Options" v-if="!isGrouped" ref="options" role="listbox" :id="`${id}_listbox`">
          <li v-for="(display, v) in options" v-bind:key="v" role="option" :class="['ListBox_Box_OptionsContainer_Options_Item', v==cValue?'_active':'']" :aria-selected="v==cValue?'true':'false'" :tabindex="v==cValue?'0':'-1'" :ref="`option-${v}`" @keydown.up.prevent="goUp" @keydown.down.prevent="goDown" @click="(e) => choose(e, v)" @keyup.enter="(e) => choose(e, v)" @keyup.space.prevent="(e) => choose(e, v)" @keyup.esc="forceClose" @keydown.tab="close">
            <span class="ListBox_Box_OptionsContainer_Options_Item_Display" :title="display">{{ display }}</span>
            <span v-if="extra.hasOwnProperty(v)" class="ListBox_Box_OptionsContainer_Options_Item_Extra">{{ extra[v] }}</span>
          </li>
        </ul>
        <ul class="ListBox_Box_OptionsContainer_Options" v-else>
          <template v-for="group of groups" :key="group.title">
            <li v-if="Object.keys(group.group).length">
              <h5 v-if="group.title!='none'">{{ group.title }}</h5>
              <ul class="ListBox_Box_OptionsContainer_Options" ref="options" role="listbox" :id="`${id}_listbox`">
                <li v-for="(display, v) in group.group" v-bind:key="v" role="option" :class="['ListBox_Box_OptionsContainer_Options_Item', v==cValue?'_active':'']" :aria-selected="v==cValue?'true':'false'" :tabindex="v==cValue?'0':'-1'" :ref="`option-${v}`" @keydown.up.prevent="goUp" @keydown.down.prevent="goDown" @click="(e) => choose(e, v)" @keyup.enter="(e) => choose(e, v)" @keyup.space.prevent="(e) => choose(e, v)" @keyup.esc="forceClose" @keydown.tab="close">
                  <span class="ListBox_Box_OptionsContainer_Options_Item_Display" :title="display">{{ display }}</span>
                  <span v-if="extra.hasOwnProperty(v)" class="ListBox_Box_OptionsContainer_Options_Item_Extra">{{ extra[v] }}</span>
                </li>
              </ul>
            </li>
          </template>
        </ul>
      </div>
    </span>

  </span>
</template>

<script>
export default {
  name: 'AriaListBox',
  data() {
    return {
      uid: this.$uuid.generate(),
      cValue: this.modelValue,
      isOpen: false,
    };
  },
  watch: {
    cValue() {
      this.$emit( 'update:modelValue', this.cValue );
    },
    modelValue() {
      this.cValue = this.modelValue;
    },
  },
  methods: {
    toggle() {
      if( this.isOpen ) {
        this.close();
      } else {
        this.open();
      }
    },
    open() {
      this.isOpen = true;

      setTimeout( () => {
        const ref = `option-${this.cValue}`;
        if( this.$refs[ref]?.length ) {
          this.$refs[ref][0].focus();
        }
      }, 100 );
    },
    close() {
      this.isOpen = false;
    },
    forceClose() {
      this.close();
      this.$refs.button.focus();
    },
    goUp( e ) {
      if( e.srcElement.previousElementSibling ) {
        e.srcElement.previousElementSibling.focus();
      } else if( this.isGrouped && e.srcElement.parentElement.parentElement.previousElementSibling ) {
        const items = e.srcElement.parentElement.parentElement.previousElementSibling.getElementsByTagName( 'li' );
        items[items.length - 1].focus();
      }
    },
    goDown( e ) {
      if( e.srcElement.nextElementSibling ) {
        e.srcElement.nextElementSibling.focus();
      } else if( this.isGrouped && e.srcElement.parentElement.parentElement.nextElementSibling ) {
        e.srcElement.parentElement.parentElement.nextElementSibling.getElementsByTagName( 'li' )[0].focus();
      }
    },
    goUpFromBox( e ) {
      this.isOpen = true;

      setTimeout( () => {
        let els;
        if( !this.isGrouped ) {
          els = e.srcElement.parentElement.getElementsByTagName( 'li' );
        } else {
          els = e.srcElement.parentElement.getElementsByTagName( 'li' )[0].getElementsByTagName( 'li' );
        }

        els[els.length - 1].focus();
      }, 100 );
    },
    goDownFromBox( e ) {
      this.isOpen = true;

      setTimeout( () => {
        let els;
        if( !this.isGrouped ) {
          els = e.srcElement.parentElement.getElementsByTagName( 'li' );
        } else {
          els = e.srcElement.parentElement.getElementsByTagName( 'li' )[0].getElementsByTagName( 'li' );
        }
        els[0].focus();
      }, 100 );
    },
    choose( e, v ) {
      this.cValue = v;
      this.$emit( 'input', v );
      setTimeout( () => { //delay change because of watch?
        this.$emit( 'change', e, v );
      }, 100 );
      this.forceClose();
    },
  },
  computed: {
    id() {
      return `${this.idRoot}${this.uid}-${this.label.replace( ' ', '' ).toLowerCase().trim()}`;
    },
    isVisible() {
      let ret = '';
      if( !this.labelVisible ) {
        ret = 'a11yhide';
      }

      return ret;
    },
    isGrouped() {
      for( const index of Object.keys( this.options ) ) {
        if( typeof this.options[index] === 'object' ) return true;
      }

      return false;
    },
    groups() {
      const groups = {
        none: [],
      };

      for( const index of Object.keys( this.options ) ) {
        if( typeof this.options[index] != 'object' ) {
          groups.none.push( {
            index,
            label: this.options[index],
          } );
        } else {
          if( typeof groups[this.options[index].group ] == 'undefined' ) {
            groups[this.options[index].group] = [];
          }
          groups[this.options[index].group].push( {
            index,
            label: this.options[index].label,
          } );
        }
      }

      const priority = [];
      const array = [];
      for( const title of Object.keys( groups ) ) {
        const group = {};
        const groupArray = groups[title];

        groupArray.sort( ( a, b ) => {
          if( a.label < b.label ) return -1;
          if( a.label > b.label ) return 1;

          return 0;
        } );

        for( const item of groupArray ) {
          group[item.index] = item.label;
        }

        if( title.toLowerCase() == 'none' || title.toLowerCase() == 'dig inclusion' || title.toLowerCase() == 'home office' ) {
          priority.push( {
            title,
            group,
          } );
        } else {
          array.push( {
            title,
            group,
          } );
        }
      }

      array.sort( ( a, b ) => {
        if( a.title < b.title ) return -1;
        if( a.title > b.title ) return 1;

        return 0;
      } );

      return [ ...priority, ...array ];
    },
  },
  props: {
    idRoot: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    labelStyle: {
      type: String,
      default: "regular",
    },
    modelValue: {
      required: false,
    },
    labelVisible: {
      type: Boolean,
      required: false,
      default: true,
    },
    options: {
      required: true,
    },
    extra: {
      required: false,
      default: () => {
        return {};
      },
    },
    inline: {
      type: Boolean,
      default: false,
    },
    bold: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  @import '@/assets/styles/variables/_header.scss';
  @import '@/assets/styles/variables/_colours.scss';
  @import '@/assets/styles/variables/_buttons.scss';
  @import '@/assets/styles/variables/_mixins.scss';

  .ListBox {
    position: relative;
    &._inline {
      display: flex;
    }
    
    &_Label {
      margin: 10px 0;
      display: block;
      color: #262e37;

      &._small {
        font-size: 0.8em;
        margin: 0;
      }
      &._inline {
        display: inline-block;
        vertical-align: middle;
        margin: 0;
        padding: 4px 0;
      }
      &._bold {
        font-weight: 600;
      }
    }
    &_Box {
      position: relative;
      &_Button {
        background: #FFF;
        border: 1px solid $hugr-button-border-border;
        border-radius: 2px;
        width: 100%;
        text-align: left;
        padding: 5px 5px 1px;

        transition: background-color .5s ease-in-out 0s, color .5s ease-in-out 0s;

        &_InnerText  {
          width: 94%;
          display: inline-block;
          height: 16px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
      &_DropArrow {
        position: absolute;
        right: 6px;
        top: -3px;
      }

      &_OptionsContainer {
        width: 100%;

        padding: 5px 0;

        background: $hugr-colours-white;
        border: 1px solid $hugr-colours-grey;
        border-radius: 5px;

        box-shadow: $hugr-header-regular-shadow;

        position: absolute;
        z-index: 9999;

        top: 25px;

        max-height: 250px;
        
        @include vertical-scroll;

        &_Options {

          list-style: none;
          padding: 0;
          margin: 0;

          h5 {
            margin: 6px;
            border-bottom: 1px solid $hugr-colours-grey;
          }

          &_Item {
            padding: 6px 8px;

            &._active {
              background: lighten($hugr-colours-grey, 10%);
            }

            &:focus, &:hover {
              background: $hugr-colours-grey;
              cursor: pointer;
              outline: none;
            }

            &_Display {
              display: block;
              overflow: hidden;
            }
            &_Extra {
              font-size: 0.8em;
              color: lighten($hugr-colours-primary, 10%);
            }
          }
        }
      }

      &._inline {
        display: inline-block;
        margin-left: 8px;
        // min-width: 170px;
        width: 100%;
        vertical-align: middle;
        svg {
          margin-top: 8px;
        }
      }
    }

    select {
      padding: 5px;
      border: 1px solid #b2b3b2;
      background: #FFF;
      color: #262e37;
      border-radius: 2px;
      width: 100%;
      margin-bottom: 10px;
    }
  }

  ._darkMode .ListBox {
    &_Label {
      color: $hugr-colours-grey;
    }
    &_Box {
      &_Button {
        background: $hugr-colours-primary;
        color: #FFF;
      }

      &_OptionsContainer {
        background: $hugr-colours-primary;

        &_Options {
          &_Item {
            &_Extra {
              color: $hugr-colours-grey;
            }

            &._active {
              background: darken($hugr-colours-primary, 10%);
            }

            &:focus, &:hover {
              background: darken($hugr-colours-primary, 5%);
            }
          }
        }
      }
    }
  }
</style>
