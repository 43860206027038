<template>
  <WidgetBase size="long" :title="$gettext('Most issues on assessments')">
    <div class="Products">
      <div v-for=" ( report, i ) in processedArray " :key="`report-${i}`">
        <div class="Products_Product" v-if="Math.max(report.advisory, report.low, report.medium, report.high, report.critical) > 0">
          <h5 class="a11yhide">{{ report.title }}</h5>
          <div class="Products_Product_Graph">
            <div class="Products_Product_Graph_Graphic">
              <SeverityLine
              :type="'line'"
              :direction="'vertical'"
              :advisory="report.advisory"
              :low="report.low"
              :medium="report.medium"
              :high="report.high"
              :critical="report.critical"
            />
            </div>
            <div v-if="i < processedArray.length" class="Products_Product_Graph_Separator">

            </div>
          </div>
          <div class="Products_Product_Info">
            <router-link :to="`/assessments/view/${report.identifier}`">{{ report.title }}</router-link>
          </div>
        </div>
      </div>
      <Empty v-if="processedArray.filter( r => Math.max( r.advisory, r.low, r.medium, r.high, r.critical ) > 0 ).length == 0" :text="$gettext( 'No issues on any assessments' )" />
    </div>
  </WidgetBase>
</template>

<script>
import WidgetBase from './WidgetBase.vue';
import SeverityLine from '../UI/SeverityLine.vue';

export default {
  name: 'UIWidgetMostIssuesProducts',
  props: {
    issues: {
      type: Object,
    },
    reports: {
      type: Array,
    },
  },
  computed: {
    processedArray() {
      return Object.values( this.processedReports );
    },
    processedReports() {
      const reports = {};

      for ( const [ report, issueArray ] of Object.entries( this.issues ) ) {
        reports[report] = { };
        reports[report].advisory = 0;
        reports[report].low = 0;
        reports[report].medium = 0;
        reports[report].high = 0;
        reports[report].critical = 0;

        issueArray.forEach( issue => {
          switch( issue.template.severity ) {
            case 0:
              reports[report].advisory++;
              
              return;
            case 1:
              reports[report].low++;

              return;
            case 2:
              reports[report].medium++;

              return;
            case 3:
              reports[report].high++;

              return;
            case 4:
              reports[report].critical++;
          }
        } );
      }

      this.reports.forEach( report => {
        reports[report._id].title = report.title;
        reports[report._id].identifier = report.identifier;
      } );

      return reports;
    },
  },
  components: {
    WidgetBase,
    SeverityLine,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  .Products {
    display: flex;
    column-gap: 0px;

    height: 100%;
    position: relative;
    &_Product {
      display: flex;
      flex-direction: column;
      width: 129px;
      
      &_Graph {
        display: flex;
        flex-direction: row;

        &_Graphic {
          padding-bottom: 8px;
          padding-left: 8px;
          padding-right: 8px;
          border-bottom: black 1px solid;
        }
        &_Separator {
          height: 24px;
          width: 1px;
          background: black;
          position: relative;
          top: 96px;
        }
      }

      &_Info {
        margin-top: 8px;
        max-width: 130px;
        text-wrap: wrap;
        text-align: center;
      }
    }
  }

  // .widget {
  //   .container {
  //     padding: 20px;
  //     .inner {
  //       display: flex;
  //       column-gap: 0px;

  //       height: 100%;
  //       position: relative;
  //     }
  //   }
  // }
</style>
