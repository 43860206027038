<template>
  <Modal
    ref="modal"
    :title="$gettext('New Version')"

    :showActions="true"

    :loading="creating"
    loadingText="Adding new version..."

    :submitText="$gettext('Add new version')"
    @submit="submit"

    @close="reset()">
    <FormSelect idRoot="team_" :label="$gettext('Retest type')" v-model="type" aria-describedby="retestInfo" :options="{
      0: $gettext('Quick retest'),
      1: $gettext('Full retest'),
      }"/>
    <p id="retestInfo">
      <translate v-if="type == 0" :translate-params="{ver: (parseFloat(trueVersion)+0.1).toFixed(1)}">This will create version %{ver}, copy all pages and component and issues.</translate>
      <translate v-if="type == 1" :translate-params="{ver: (parseFloat(trueVersion)+1).toFixed(0)+'.0'}">This will create version %{ver}, copy all pages and component but not issues.</translate>
    </p>
    <template v-if="type == 0">
      <input type="checkbox" v-model="markToRetest" id="markToRetest" />&nbsp;<label for="markToRetest">Mark all issues as "to retest"</label>
    </template>
  </Modal>
</template>

<script>
  export default {
    name: "VersionModal",
  };
</script>

<script setup>
  import { ref, inject, toRefs } from 'vue';
  import { useRouter } from 'vue-router';
  import gql from "graphql-tag";
  import { useMutation } from "@vue/apollo-composable";
  import FormSelect from '@/components/Form/Select';
  import config from '../../../config';

  const router = useRouter();

  const confirm = inject( 'confirm' );
  const alerts = inject( 'alerts' );

  const QUICK_RETEST = 0;
  const FULL_RETEST = 1;

  const props = defineProps( {
    id: String,
    trueVersion: String,
  } );

  const modal = ref( null );
  const type = ref( QUICK_RETEST );
  const creating = ref( false );
  const markToRetest = ref( false );
  const { id } = toRefs( props );

  const { mutate: doFullRetest } = useMutation(
    gql`
    mutation newReportVersionFull($id: ObjectID!) {
      report: newReportVersionFull(id: $id) {
        _id,
        identifier
        currentVersion {
          _id
          version
        }
      }
    }
  ` );

  const { mutate: doQuickRetest } = useMutation(
    gql`
    mutation newReportVersionQuick($id: ObjectID!, $retest: Boolean) {
      report: newReportVersionQuick(id: $id, retest: $retest) {
        _id,
        identifier
        currentVersion {
          _id
          version
        }
      }
    }
  ` );

  const submit = () => {
    if( type.value == QUICK_RETEST ) {
      confirm.simple( 'Cannot be reversed' ).then( result => {
        if( result ) {
          creating.value = true;
          doQuickRetest( {
            id: id.value,
            retest: markToRetest.value,
          } ).then( res => {
            creating.value = false;
            alerts.success( 'New version', `A new report version has been created` );
            router.push( `/${config.reportRouterReplacement}s/view/${res.data.report.identifier}/${res.data.report.currentVersion.version}` );
          } ).catch( () => {
            creating.value = false;
            alerts.error( `Couldn't create a new version`, `Please try again or contact an administrator.` );
          } );
        }
      } );
    } else if( type.value == FULL_RETEST ) {
      confirm.simple( 'Cannot be reversed' ).then( result => {
        if( result ) {
          creating.value = true;
          doFullRetest( {
            id: id.value,
          } ).then( res => {
            creating.value = false;
            alerts.success( 'New version', `A new report version has been created` );
            router.push( `/${config.reportRouterReplacement}s/view/${res.data.report.identifier}/${res.data.report.currentVersion.version}` );
          } ).catch( () => {
            creating.value = false;
            alerts.error( `Couldn't create a new version`, `Please try again or contact an administrator.` );
          } );
        }
      } );
    }
  };

  const show = () => {
    modal.value.show();
  };

  const reset = () => {
    type.value = QUICK_RETEST;
    markToRetest.value = false;
    modal.value.reset();
  };

  defineExpose( {
    show,
    reset,
  } );
</script>

<style lang="scss" scoped>

</style>
