<template>
  <div>
    <div v-if="me" class="MFA">
      <FormFieldSet :legend="$gettext(`Two factor authentication`) + ` (${me.mfaEnabled?$gettext('enabled'):$gettext('not enabled')})`">
        <div v-if="me.mfaEnabled||me.mfaMethods.length" >
          <h3 v-translate>2FA Methods</h3>
          <ul class="MFA_Methods">
            <template v-for="method in me.mfaMethods">
              <li :key="method._id" v-if="method.confirmed" class="MFA_Methods_Method">
                <span class="MFA_Methods_Method_Icon">
                  <Icon v-if="method.type=='key'" type="solid" icon="key"/>
                  <Icon v-if="method.type=='authenticator'" type="solid" icon="unlock"/>
                </span>
                <span class="MFA_Methods_Method_Body">
                  <h4 class="MFA_Methods_Method_Body_Name">{{ method.name }}</h4>
                  <span class="MFA_Methods_Method_Body_Type">{{ method.type }} ({{ method.active?$gettext('enabled'):$gettext('disabled') }}{{ method.primary?$gettext(', primary'):'' }})</span>
                </span>
                <span class="MFA_Methods_Method_Actions">
                  <Button size="micro" type="border" v-if="!method.primary&&method.active" @click="makePrimaryMethod(method._id)">{{$gettext('Make primary')}}</Button>

                  <Button size="micro" type="border" v-if="method.active" @click="disableMethod(method._id)">{{$gettext('Disable')}}</Button>
                  <Button size="micro" type="border" v-else @click="enableMethod(method._id)">{{$gettext('Enable')}}</Button>

                  <Button size="micro" type="serious" @click="deleteMethod(method._id)">{{$gettext('Delete')}}</Button>
                </span>
              </li>
            </template>
          </ul>
        </div>
        <div class="MFA_Actions">
          <Button type="border" @click="$refs.authmodal.show()">{{$gettext('Add authenticator')}}</Button>
          <Button type="border" @click="$refs.securitykeymodal.show()">{{$gettext('Add hardware key')}}</Button>
        </div>
      </FormFieldSet>
    </div>
    <AuthenticatorModal ref="authmodal" @success="$apollo.queries.me.refetch(); $emit('success');"/>
    <SecurityKeyModal ref="securitykeymodal" @success="$apollo.queries.me.refetch(); $emit('success');"/>
  </div>
</template>

<script>
import gql from 'graphql-tag';

import { mapState, mapActions } from 'vuex';
import md5 from 'md5';

import FormFieldSet from '@/components/Form/FieldSet';
// import FormInput from '@/components/Form/Input';

import AuthenticatorModal from '@/modals/Authenticator';
import SecurityKeyModal from '@/modals/securityKey';

export default {
  name: 'TFAForm',
  data() {
    return {
      me: false,
    };
  },
  apollo: {
    me: {
      query: gql`
        query {
          me: WhoAmI {
            _id,
            name,
            mfaEnabled,
            mfaMethods {
              _id
              name
              type
              confirmed,
              primary,
              active
            }
          }
        }
      `,
    },
  },
  methods: {
    makePrimaryMethod( method ) {
      this.$apollo.mutate( {
        mutation: gql`
          mutation setPrimaryMFAMethod($method: ObjectID) {
            response: setPrimaryMFAMethod(method: $method) {
              success
            }
          }
        `,
        variables: {
          method,
        },
      } ).then( res => {
        if( res.data.response.success ) {
          this.$apollo.queries.me.refetch();
        }
      } ).catch( () => {
        this.$alerts.coded( 'E031', 'F1601' ); //see notifications spreadsheet
      } );
    },
    deleteMethod( method ) {
      this.$confirm.simple( 'This cannot be undone' ).then( result => {
        if( result ) {
          this.doDeleteMethod( method );
        }
      } );
    },

    doDeleteMethod( method ) {
      this.$apollo.mutate( {
        mutation: gql`
          mutation deleteMFAMethod($method: ObjectID) {
            response: deleteMFAMethod(method: $method) {
              success
            }
          }
        `,
        variables: {
          method,
        },
      } ).then( res => {
        if( res.data.response.success ) {
          this.$apollo.queries.me.refetch();
        }
      } ).catch( () => {
        this.$alerts.coded( 'E032', 'F1602' ); //see notifications spreadsheet
      } );
    },

    enableMethod( method ) {
      this.$apollo.mutate( {
        mutation: gql`
          mutation enableMFAMethod($method: ObjectID) {
            response: enableMFAMethod(method: $method) {
              success
            }
          }
        `,
        variables: {
          method,
        },
      } ).then( res => {
        if( res.data.response.success ) {
          this.$apollo.queries.me.refetch();
        }
      } ).catch( () => {
        this.$alerts.coded( 'E033', 'F1603' ); //see notifications spreadsheet
      } );
    },
    disableMethod( method ) {
      this.$apollo.mutate( {
        mutation: gql`
          mutation disableMFAMethod($method: ObjectID) {
            response: disableMFAMethod(method: $method) {
              success
            }
          }
        `,
        variables: {
          method,
        },
      } ).then( res => {
        if( res.data.response.success ) {
          this.$apollo.queries.me.refetch();
        }
      } ).catch( () => {
        this.$alerts.coded( 'E034', 'F1604' ); //see notifications spreadsheet
      } );
    },
  },
  computed: {
    ...mapState( [ 'user' ] ),
  },
  components: {
    // FormInput,
    FormFieldSet,

    AuthenticatorModal,
    SecurityKeyModal,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  @import '@/assets/styles/variables/_colours.scss';

  .MFA {
    &_Methods {
      border: 1px solid $hugr-colours-grey;
      border-radius: 3px;
      list-style: none;
      padding: 0;
      margin: 0;
      &_Method {
        border-bottom: 1px solid $hugr-colours-grey;
        padding: 5px 10px;
        position: relative;
        &:last-child {
          border-bottom: none;
        }
        &_Icon {
          display: inline-block;
          vertical-align: middle;
          margin-right: 10px;
        }
        &_Body {
          display: inline-block;
          vertical-align: middle;
          &_Name {
            font-family: "Quicksand", serif;
            font-weight: 400;
            margin: 0;
          }
          &_Type {
            font-size: 0.8em;
            color: lighten($hugr-colours-primary, 25%);
          }
        }
        &_Actions {
          position: absolute;
          right: 0;
          top: 0;
          display: inline-block;
          vertical-align: middle;
          button {
            display: inline-block;
            vertical-align: top;
            width: auto;
            margin-right: 10px;
            margin-top: 10px;
          }
        }
      }
    }
    &_Actions {
      margin-top: 20px;
      button {
        margin-right: 20px;
      }
    }
  }

  @media (max-width: 700px) {
    .MFA {
      &_Methods {
        &_Method {
          &_Body {

          }
          &_Actions {
            display: block;
            position: relative;
          }
        }
      }
    }
  }
</style>
