<template>
  <Body :title="$gettext(`Report Type:`) + ` ${template.identifier}`">
    <form @submit.prevent="updateTemplate" class="Settings_Body_Form">
      <FormTextArea idRoot="sstemplate_" label="Executive Summary" v-model="template.execSummary"/>

      <br />
      <table>
        <caption v-translate>Key</caption>
        <thead>
          <th v-translate>variable</th>
          <th v-translate>output</th>
          <th v-translate>description</th>
        </thead>
        <tbody>
          <tr>
            <td>issues.all</td>
            <td v-translate>number</td>
            <td v-translate>Total number of issues in report</td>
          </tr>
          <tr>
            <td>issues.advisory</td>
            <td v-translate>number</td>
            <td v-translate>number of advisory issues in report</td>
          </tr>
          <tr>
            <td>issues.low</td>
            <td v-translate>number</td>
            <td v-translate>number of low severity issues in report</td>
          </tr>
          <tr>
            <td>issues.medium</td>
            <td v-translate>number</td>
            <td v-translate>number of medium severity issues in report</td>
          </tr>
          <tr>
            <td>issues.high</td>
            <td v-translate>number</td>
            <td v-translate>number of high severity issues in report</td>
          </tr>
          <tr>
            <td>issues.critical</td>
            <td v-translate>number</td>
            <td v-translate>number of critical issues in report</td>
          </tr>
          <tr>
            <td>issues.biggestGroup</td>
            <td v-translate>string</td>
            <td v-translate>Severity group with the largest amount of issues</td>
          </tr>

          <tr>
            <td>instances.all</td>
            <td v-translate>number</td>
            <td v-translate>Total number of instances in report</td>
          </tr>
          <tr>
            <td>instances.advisory</td>
            <td v-translate>number</td>
            <td v-translate>number of advisory instances in report</td>
          </tr>
          <tr>
            <td>instances.low</td>
            <td v-translate>number</td>
            <td v-translate>number of low severity instances in report</td>
          </tr>
          <tr>
            <td>instances.medium</td>
            <td v-translate>number</td>
            <td v-translate>number of medium severity instances in report</td>
          </tr>
          <tr>
            <td>instances.high</td>
            <td v-translate>number</td>
            <td v-translate>number of high severity instances in report</td>
          </tr>
          <tr>
            <td>instances.critical</td>
            <td v-translate>number</td>
            <td v-translate>number of critical instances in report</td>
          </tr>
          <tr>
            <td>instances.biggestGroup</td>
            <td v-translate>string</td>
            <td v-translate>Severity group with the largest amount of instances</td>
          </tr>
          <tr>
            <td>compliance.status</td>
            <td>string</td>
            <td>whether the report is compliant or not</td>
          </tr>
          <tr>
            <td>progress</td>
            <td>number</td>
            <td>progress of the report, percent</td>
          </tr>
          <tr>
            <td>started</td>
            <td>boolean</td>
            <td>whether the report is started</td>
          </tr>
          <tr>
            <td>completed</td>
            <td>boolean</td>
            <td>whether the report is completed</td>
          </tr>
        </tbody>
      </table>
      <p v-translate>If you want any other variables just let me know</p>

      <!-- <input type="checkbox" id="published" v-model="template.published"><label for="published">Published</label>
      <input type="checkbox" id="promoted" v-model="template.promoted"><label for="promoted">Promoted</label> -->

      <ButtonSet class="">
        <Button type="primary" htype="submit">{{$gettext('Save')}}</Button>
      </ButtonSet>
    </form>
  </Body>
</template>

<script>
import gql from 'graphql-tag';

// import FormInput from '@/components/Form/Input';
import FormTextArea from '@/components/Form/TextArea';

export default {
  name: 'TemplatesReportTypeView',
  data() {
    return {
      template: false,
      tid: this.$route.params.identifier,
    };
  },
  apollo: {
    atemplate: {
      query: gql`
        query ReportType($identifier: String!) {
          atemplate: ReportType(identifier: $identifier) {
            _id,
            identifier,
            execSummary
          }
        }
      `,
      variables() {
        return {
          identifier: this.tid,
        };
      },
    },
  },
  watch: {
    $route( to ) {
      if( this.$route.name == 'ReportType' ) {
        this.tid = to.params.identifier;
        this.$apollo.queries.atemplate.refetch();
      }
    },
    atemplate() {
      this.template = { ...this.atemplate };
    },
  },
  methods: {
    updateTemplate() {
      this.$apollo.mutate( {
        mutation: gql`
          mutation updateReportType($identifier: String!, $execSummary: String) {
            ss: updateReportType(identifier: $identifier, execSummary: $execSummary) {
              _id
            }
          }
        `,
        variables: {
          identifier: this.tid,
          execSummary: this.template.execSummary,
        },
      } ).then( () => {
        this.$alerts.success( 'Report Type updates', `The report type has been updated.` );
      } ).catch( () => {
        this.$alerts.error( `Sorry, something went wrong`, `We couldn't update the spreadsheet template.` );
      } );
    },
  },
  components: {
    // FormInput,
    FormTextArea,
  },
};
</script>
