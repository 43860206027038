<template>
  <p>Your notification preferences</p>
  <Well title="Email Notifications">
    <dl class="DataList">
      <div>
        <dt>
          <span>Digests</span>
          <span class="_sub">Receive digest of your notifications on a regular basis</span>
        </dt>
        <dd>
          <span class="_radiobutton">
            <input type="radio" id="digest_off" name="digest" value="off" :checked="digest=='off'" @click="setDigest('off')"/>
            <label for="digest_off">Off</label>
          </span>
          <span class="_radiobutton">
            <input type="radio" id="digest_daily" name="digest" value="daily" :checked="digest=='daily'" @click="setDigest('daily')"/>
            <label for="digest_daily">Daily</label>
          </span>
          <span class="_radiobutton">
            <input type="radio" id="digest_weekly" name="digest" value="weekly" :checked="digest=='weekly'" @click="setDigest('weekly')"/>
            <label for="digest_weekly">Weekly</label>
          </span>
        </dd>
      </div>
      
      <div>
        <dt>
          <span>Tags</span>
          <span class="_sub">Receive an email when you are tagged in a comment or update</span>
        </dt>
        <dd>
          <span class="_radiobutton">
            <input type="radio" id="tags_never" name="tags" value="never" :checked="tags=='never'" @click="setTags('never')"/>
            <label for="tags_never">Never</label>
          </span>
          <span class="_radiobutton">
            <input type="radio" id="tags_team" name="tags" value="team" :checked="tags=='team'" @click="setTags('team')"/>
            <label for="tags_team">Only from people on my team</label>
          </span>
          <span class="_radiobutton">
            <input type="radio" id="tags_everyone" name="tags" value="everyone" :checked="tags=='everyone'" @click="setTags('everyone')"/>
            <label for="tags_everyone">Everyone</label>
          </span>
        </dd>
      </div>

      <div>
        <dt>
          <span>Report Updates</span>
          <span class="_sub">Receive an email when a report you are involved with is updated</span>
        </dt>
        <dd>
          <span class="_radiobutton">
            <input type="radio" id="reports_never" name="reports" value="never" :checked="reports=='never'" @click="setReports('never')"/>
            <label for="reports_never">Never</label>
          </span>
          <span class="_radiobutton">
            <input type="radio" id="reports_own" name="reports" value="own" :checked="reports=='own'" @click="setReports('own')"/>
            <label for="reports_own">Only on reports I own</label>
          </span>
          <span class="_radiobutton">
            <input type="radio" id="reports_all" name="reports" value="all" :checked="reports=='all'" @click="setReports('all')"/>
            <label for="reports_all">All Reports</label>
          </span>
        </dd>
      </div>
    </dl>
  </Well>
</template>

<script setup>
  import { computed, ref, inject, watch } from 'vue';
  import { useQuery, useMutation } from '@vue/apollo-composable';
  import { useStore } from 'vuex';
  import { useGettext } from 'vue3-gettext';
  import gql from 'graphql-tag';

  const gettext = useGettext();
  const alerts = inject( 'alerts' );
  // const confirm = inject( 'confirm' );

  const store = useStore();
  const user = computed( () => store.getters.user );

  const digest = ref( 'weekly' );
  const tags = ref( 'team' );
  const reports = ref( 'never' );

  const { mutate: setSetting, onError: setSettingError, onDone: setSettingDone } = useMutation( gql`
    mutation SetUserSetting($setUserSettingId: ObjectID!, $key: String!, $value: String!) {
      result: setUserSetting(id: $setUserSettingId, key: $key, value: $value)
    }
  ` );

  setSettingError( () => {
    alerts.coded( 'ETODO', 'FTODO' ); //XXX
  } );
  
  const { result: digestResult, refetch: refetchDigest, onResult: digestDone } = useQuery( gql`
    query GetUserSetting($id: ObjectID!, $key: String!) {
      res: GetUserSetting(id: $id, key: $key)
    }`, {
      id: user.value.id,
      key: 'email-digest',
    }, {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only', //needed to trigger onResult first time
    },
  );

  digestDone( ( { data } ) => {
    if( data?.res != 'undefined' ) digest.value = data?.res;
  } );

  const setDigest = value => {
    digest.value = value;
    setSetting( {
      setUserSettingId: user.value.id,
      key: "email-digest",
      value,
    } );
  };
  
  const { result: tagsResult, refetch: refetchTags, onResult: tagsDone } = useQuery( gql`
    query GetUserSetting($id: ObjectID!, $key: String!) {
      res: GetUserSetting(id: $id, key: $key)
    }`, {
      id: user.value.id,
      key: 'email-tags',
    }, {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only', //needed to trigger onResult first time
    },
  );

  tagsDone( ( { data } ) => {
    if( data?.res != 'undefined' ) tags.value = data?.res;
  } );

  const setTags = value => {
    tags.value = value;
    setSetting( {
      setUserSettingId: user.value.id,
      key: "email-tags",
      value,
    } );
  };

  const { result: reportsResult, refetch: refetchReports, onResult: reportsDone } = useQuery( gql`
    query GetUserSetting($id: ObjectID!, $key: String!) {
      res: GetUserSetting(id: $id, key: $key)
    }`, {
      id: user.value.id,
      key: 'email-reports',
    }, {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only', //needed to trigger onResult first time
    },
  );

  reportsDone( ( { data } ) => {
    if( data?.res != 'undefined' ) reports.value = data?.res;
  } );

  const setReports = value => {
    reports.value = value;
    setSetting( {
      setUserSettingId: user.value.id,
      key: "email-reports",
      value,
    } );
  };

  setSettingDone( () => {
    alerts.success( 'Preferenced updated' );
    refetchDigest();
    refetchTags();
    refetchReports();
  } );

</script>

<style lang="scss" scoped>
  @import '@/assets/styles/variables/_colours.scss';
  .DataList {
    > div {
      padding: 16px;
      border-bottom: 1px solid $hugr-colours-grey;
      &:last-child {
        border-bottom: none;
      }
      dt {
        display: inline-block;
        vertical-align: top;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 30%;
        span {
          display: block;
          &._sub {
            font-size: 0.8em;
            width: 100%;
            white-space: break-spaces;
            margin-top: 4px;
          }
        }
      }
      dd {
        display: inline-block;
        vertical-align: top;
        margin-left: 0;
        width: 60%;
        position: relative;
        margin-left: 10%;

        ._actions {
          display: inline-block;
          right: 0;
          position: absolute;
          top: -9px;

          button {
            margin-left: 8px;
          }
        }

        ._radiobutton {
          display: block;
          margin-bottom: 10px;
        }
      }
    }
  }

  ._darkMode {
    .DataList {
      > div {
        border-bottom: 1px solid darken( $hugr-colours-grey, 40% );
        &:last-child {
          border-bottom: none;
        }
      }
    }
  }
</style>
