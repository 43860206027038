<template>
  <div class="FilterSort">
    <div class="FilterSort_IntroText" :id="`${uid}_label`">Sort by:</div>
    <div class="FilterSort_Box">
      <button :class="['FilterSort_Box_Button', showOpts?'__open':'']" @click="toggleOpts"
        ref="button"
        :id="`${uid}_button`"
        :aria-expanded="showOpts?'true':'false'"
        :aria-haspopup="`${uid}_listbox`"
        :aria-labelledby="`${uid}_label ${uid}_button`"
      >
        {{ options[value] }}
        <Icon class="FilterOpt_Button_Icon" type="solid" :icon="showOpts?'chevron-up':'chevron-down'" />
      </button>
      <div class="FilterSort_Box_Opts" v-if="showOpts">
        <ul class="FilterSort_Box_Opts_List" :id="`${uid}_listbox`" role="listbox">
          <li class="FilterSort_Box_Opts_List_Item" v-for="(value, key) in options" :key="`${key}-li`" role="option" :aria-selected="key==value?'true':'false'" :tabindex="key==value?'0':'-1'" @keydown.up.prevent="goUp" @keydown.down.prevent="goDown" @click="(e) => choose(e, key)" @keyup.enter="(e) => choose(e, key)" @keyup.esc="forceClose" @keydown.tab="doUnshowOpts">
            <span>{{ value }}</span>
            <span class="FilterSort_Box_Opts_List_Item_Text_Extra" v-if="extra&&extra.hasOwnProperty(key)">{{ extra[key] }}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UIFilterSort',
  inject: [ 'signal' ],
  data() {
    let value = Object.keys( this.options )[0];
    if( this.default && this.options.hasOwnProperty( this.default ) ) {
      value = this.default;
    }

    return {
      uid: this.$uuid.generate(),

      showOpts: false,
      value,
    };
  },
  watch: {
    signal( from, to ) {
      if( to != this.uid ) {
        this.doUnshowOpts();
      }
    },
  },
  methods: {
    toggleOpts() {
      if( !this.showOpts ) {
        this.doShowOpts();
      } else {
        this.doUnshowOpts();
      }
    },
    doShowOpts() {
      this.$emit( 'open', this.uid );
      setTimeout( () => {
        this.showOpts = true;
      }, 10 );
      // setTimeout( () => {
      //   const ref = `${this.value}-li`;
      //   this.$refs[ref][0].focus();
      // }, 100 );
    },
    doUnshowOpts() {
      this.showOpts = false;
    },
    forceClose() {
      this.doUnshowOpts();
      this.$refs.button.focus();
    },
    choose( e, v ) {
      this.value = v;
      this.$emit( 'input', v );
      this.$emit( 'change', e );
      this.forceClose();
    },
    // save: function() {
    //   this.$emit('change', this.currValue);
    //   this.ghostValue = this.currValue;
    //   this.doUnshowOpts();
    // },
    // clear: function() {
    //   this.currValue = [];
    //   //should this save and close or not save and stay open?
    //   // this.$emit('change', this.value);
    //   // this.doUnshowOpts();
    // },
    // clearAndSave: function() {
    //   this.clear();
    //   this.save();
    // }
  },
  props: {
    options: {
      type: Object,
      required: true,
    },
    extra: {
      required: false,
      default: () => {
        return {};
      },
    },
    default: {
      default: false,
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  @import '@/assets/styles/variables/_colours.scss';
  @import '@/assets/styles/variables/_header.scss';

  .FilterSort {
    display: inline-block;
    vertical-align: top;

    &_IntroText {
      display: inline-block;
      vertical-align: top;

      padding: 8px 0;
      color: $hugr-colours-primary;
      transition: background-color .5s ease-in-out 0s, color .5s ease-in-out 0s;
      font-weight: bold;
      font-size: 0.9em;
      margin-right: 8px;
    }

    &_Box {
      display: inline-block;
      vertical-align: top;

      &_Button {
        padding: 8px;
        background: white;
        border: 1px solid $hugr-colours-grey;
        border-radius: 4px;

        transition: background-color .5s ease-in-out 0s, color .5s ease-in-out 0s;

        &:hover, &:focus, &.__open {
          background: lighten($hugr-colours-grey, 10%);
          cursor: pointer;
        }
      }
      &_Opts {
        position: absolute;
        top: 35px;
        margin-left: -1px;
        background: white;
        border: 1px solid $hugr-colours-grey;
        width: 250px;
        z-index: 9999;
        border-bottom-right-radius: 3px;
        border-bottom-left-radius: 3px;
        box-shadow: $hugr-header-regular-shadow;

        &_List {
          list-style: none;
          margin: 0;
          padding: 10px 0;

          &_Item {
            padding: 10px;

            &_Input {
              vertical-align: middle;
            }
            &_Text {
              display: inline-block;
              vertical-align: middle;
              padding-left: 10px;
              max-width: 200px;

              > * {
                display: block;
              }
              &_Extra {
                font-size: 9pt;
                color: $hugr-colours-primary;
              }
            }
          }
        }
      }
    }
  }
</style>
